import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import "./index.css";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import App from "./App";
import { keytoken } from "./Configs/token_keys";

import Orders from "./components/Orders/orders";
import StockChecker from "./components/Orders/stock_checker";
import ShopDetails from "./components/ShopDetails";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./components/Login/login";
import home from "./components/Welcome/home";
import DriverDetails from "./components/DriverDetails/vehicle_details_new";
import Navbar from "./components/Navigation bar/Navbar";
import OrderReport from "./components/Orders/OrderReports/OrderReportPage";
import OrderDetails from "./components/OrdersListing/OrdersDetails";
import ProductSummary from "./components/ProductSummary/productsummary";
import VehicleRouting from "./components/VehicleRouting/VehicleRouting";
import DeliverySummary from "./components/DeliverySummary/DeliverySummary";
import UnDeliveredOrders from "./components/DeliverySummary/UnDeliveredOrders";
import OrderList from "./components/OrdersListing/orderslist";
// import OrderPrintDetails from './components/Orders/OrdersReportPrint'
import OrderPrintDetails from "./components/Orders/OrdersReportPrintFirebase";
import BulkOrdersPrint from "./components/OrdersListing/bulkOrdersPrint";
import ReturnOrders from "./components/ReturnOrders/ReturnOrders";
import Inventory from "./components/Inventory";
import Picking from "./components/Picking";
// import ItemInventoryDetails from "./components/Inventory/ItemInventory/ItemInventoryDetails";
import InventoryMovementsDetails from "./components/InventoryMovements/InventoryMovementsDetails";
import ItemInventoryMovementDetails from "./components/InventoryMovements/ItemInventoryMovements/ItemInventoryDetails";
import InventoryReportsDetails from "./components/InventoryMovements/InventoryReportsDetails";
import OrderBulkPrint from "./components/Orders/BulkOrders";
import NewBulkOrders from "./components/Orders/NewBulkOrders";
import KhetikaBill from "./components/Orders/KhetikaBill";
import YoGroceryPrint from "./components/OrdersListing/YoGroceryInvoice";
import KhetikaInvoice from "./components/OrdersListing/Khetika";
import LoosePacking from "./components/LoosePacking/loosepacking";
import SearchLoosePack from "./components/SearchLoosePack/searchloosepack";
import HighValuePack from "./components/HighValuePack/home";
import AddReturnOrders from "./components/Orders/addreturnorders";
import AddReturnOrAdjustmentOrder from "./components/Orders/addreturnadjustmentorders";
import ViewReturnOrders from "./components/ViewReturnOrders/viewreturnorders";
import DeliverySchedules from "./components/DeliverySchedules/deliveryschedules";
import SuperKreditOrders from "./components/SuperKreditOrders/superkreditorders";
import SuperKreditReports from "./components/SuperKreditOrders/superkreditreports";
import DriverPerformance from "./components/DriverPerformance/Driverperformance";
import TransitStockTransfer from "./components/TransitStockTransfer/transit_stock_transfer";
import StockMovementPrint from "./components/TransitStockTransfer/StockMovementReportPrintFirebase";
import TransitStockReturn from "./components/TransitStockReturn";
import InventoryStock from "./components/InventoryStock/Inventorystock";
import StockCorrection from "./components/StockCorrection";
import StockDiscrepancy from "./components/StockDiscrepancy";
import StockClearance from "./components/StockClearance/Stockclearance";
import ChequeManagement from "./components/ChequeManagement/ChequeManagement";
import SuperKredit from "./components/ChequeManagement/SuperKredit";
import PaymentPending from "./components/PaymentPending/PaymentPending";
import CashManagement from "./components/CashManagement/CashManagement";
import PaymentManagement from "./components/PaymentManagement/PaymentManagement";
import PendingEscalations from "./components/PendingEscalations/PendingEscalations";
import ScratchCardTransactions from "./components/ScratchCardTransactions/ScratchCardTransactions";
import Rupifi from "./components/Rupifi/Rupifi";
import RupifiApprove from "./components/Rupifi/RupifiApprove";
import Fresh from "./components/Fresh/Fresh";
import StockDiscrepancyReport from "./components/StockDiscrepancy/Report/StockDiscrepancyReport";
import StockCorrectionReport from "./components/StockCorrection/Report/StockCorrectionReport";
import TransporterPayments from "./components/TransporterPayments/TransporterPayments";
// import Finaleap from "./components/Finaleap/Finaleap";
import Finaleap from "./components/FinaleapTransaction/Finaleap";

import WeeklyVariableCost from "./components/WeeklyVariableCost/WeeklyVariableCost";
import BagCheckReport from "./components/BagCheckReport";
// stock check report
import StockCheckingReport from "./components/StockCheckReport/Main";
// import Invoice from "./components/TransporterPayments/TransporterPaymentsPrint";
// import Epay from "./components/Epay/home";
import PalletSticker from "./components/PalletStickerPrint/PalletStickerPrinting";

axios.defaults.headers.common["x-access-token"] = keytoken;
sessionStorage.setItem("x-access-token", keytoken);

const routing = (
  <Router>
    <div>
      <Route exact path="/home" component={home} />
      <Route exact path="/" component={Login} />

      <ProtectedRoute exact path="/orders" component={Orders} />
      <ProtectedRoute
        exact
        path="/transit-stock-checker"
        component={StockChecker}
      />
      <ProtectedRoute exact path="/shop" component={ShopDetails} />
      <Route exact path="/login" component={Login} />
      <ProtectedRoute exact path="/orderReport" component={OrderReport} />
      <ProtectedRoute
        exact
        path="/orderPrintDetails"
        component={OrderPrintDetails}
      />
      <ProtectedRoute exact path="/routing" component={VehicleRouting} />
      <ProtectedRoute
        exact
        path="/delivery-summary"
        component={DeliverySummary}
      />
      <ProtectedRoute
        exact
        path="/un-delivered-orders/:dateVal"
        component={UnDeliveredOrders}
      />
      <ProtectedRoute
        exact
        path="/un-delivered-orders"
        component={UnDeliveredOrders}
      />

      <ProtectedRoute exact path="/orderdetails" component={OrderDetails} />
      <ProtectedRoute exact path="/returnedorders" component={ReturnOrders} />
      <ProtectedRoute exact path="/vehicle-details" component={DriverDetails} />
      <ProtectedRoute
        exact
        path="/bulkorderdetails"
        component={BulkOrdersPrint}
      />

      <ProtectedRoute exact path="/orderlist" component={OrderList} />
      <ProtectedRoute exact path="/inventory" component={Inventory} />
      <ProtectedRoute
        exact
        path="/inventory-movements"
        component={InventoryMovementsDetails}
      />

      <ProtectedRoute
        exact
        path="/inventory-reports"
        component={InventoryReportsDetails}
      />

      <Route
        exact
        path="/item-inventory-movements"
        component={ItemInventoryMovementDetails}
      />

      <ProtectedRoute
        exact
        path="/bag-check-report"
        component={BagCheckReport}
      />

      <ProtectedRoute exact path="/orderBulkPrint" component={NewBulkOrders} />
      <ProtectedRoute exact path="/khetikaBill" component={KhetikaBill} />
      <ProtectedRoute exact path="/khetikaInvoice" component={KhetikaInvoice} />
      <ProtectedRoute exact path="/loosepackorders" component={LoosePacking} />
      <ProtectedRoute exact path="/highValuePacks" component={HighValuePack} />
      <ProtectedRoute
        exact
        path="/searchloosepackorders"
        component={SearchLoosePack}
      />
      <ProtectedRoute
        exact
        path="/addreturnorders"
        component={AddReturnOrders}
      />
      <ProtectedRoute
        exact
        path="/addreturnadjustmentorders"
        component={AddReturnOrAdjustmentOrder}
      />
      <ProtectedRoute
        exact
        path="/viewreturnorders"
        component={ViewReturnOrders}
      />
      <ProtectedRoute
        exact
        path="/deliveryschedules"
        component={DeliverySchedules}
      />
      <ProtectedRoute
        exact
        path="/superkredit-orders"
        component={SuperKreditOrders}
      />
      <ProtectedRoute
        exact
        path="/superkredit-reports"
        component={SuperKreditReports}
      />
      <ProtectedRoute
        exact
        path="/transit-stock-transfer"
        component={TransitStockTransfer}
      />
      <ProtectedRoute
        exact
        path="/stock-movement-print"
        component={StockMovementPrint}
      />
      <ProtectedRoute
        exact
        path="/transit-stock-return"
        component={TransitStockReturn}
      />
      <ProtectedRoute
        exact
        path="/stock-correction"
        component={StockCorrection}
      />
      <ProtectedRoute
        exact
        path="/stock-correction-report"
        component={StockCorrectionReport}
      />
      <ProtectedRoute
        exact
        path="/stock-discrepancy"
        component={StockDiscrepancy}
      />
      <ProtectedRoute
        exact
        path="/stock-discrepancy-report"
        component={StockDiscrepancyReport}
      />
      <ProtectedRoute
        exact
        path="/driver-performance"
        component={DriverPerformance}
      />
      <ProtectedRoute
        exact
        path="/inventory-stock"
        component={InventoryStock}
      />
      <ProtectedRoute
        exact
        path="/stock-clearance"
        component={StockClearance}
      />

      <ProtectedRoute
        exact
        path="/cash-management"
        component={CashManagement}
      />
      <ProtectedRoute
        exact
        path="/cheque-management"
        component={ChequeManagement}
      />
      <ProtectedRoute exact path="/Super-Kredit" component={SuperKredit} />
      <ProtectedRoute
        exact
        path="/payment-pending"
        component={PaymentPending}
      />
      <ProtectedRoute
        exact
        path="/payment-management"
        component={PaymentManagement}
      />
      <ProtectedRoute
        exact
        path="/pending-escalations"
        component={PendingEscalations}
      />
      <ProtectedRoute
        exact
        path="/scratch-card-transactions"
        component={ScratchCardTransactions}
      />
      <ProtectedRoute
        exact
        path="/stockCheckingReport"
        component={StockCheckingReport}
      />
      <ProtectedRoute exact path="/Rupifi" component={Rupifi} />
      <ProtectedRoute exact path="/Finaleap" component={Finaleap} />
      <ProtectedRoute
        exact
        path="/WeeklyVariableCost"
        component={WeeklyVariableCost}
      />

      <ProtectedRoute exact path="/RupifiApprove" component={RupifiApprove} />

      <ProtectedRoute exact path="/productsummary" component={ProductSummary} />
      <ProtectedRoute exact path="/picking" component={Picking} />
      <ProtectedRoute exact path="/palletSticker" component={PalletSticker} />
      <ProtectedRoute exact path="/Fresh" component={Fresh} />

      <ProtectedRoute
        exact
        path="/transporter-payments"
        component={TransporterPayments}
      />

      {/* <Route exact path="/" component={} /> */}
    </div>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));
