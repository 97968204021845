import React, { useState, useEffect, useRef } from 'react'
import { Table, Badge,Navbar,Form,Button,Dropdown,DropdownButton,InputGroup,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import szlogo from '../../assests/images/sz_logo.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import AddItemModal from '../OrdersListing/AddNewItemModal';
import { IconButton } from '@material-ui/core';
import NavBar from '../Navigation bar/Navbar'
import Select from 'react-select';
import { toast } from 'react-toastify';
import Switch from "react-switch";
import PickingModal from './PickingModal.js';
import AllErrorsModal from './AllErrorsModal.js';
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'
import { service_url } from "../../Configs/mysqlconfig";
import fire from '../../Configs/firebase';
const excel = require('exceljs');

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }

    return [year,month,day].join('-');
}

const Picking = () => {
    const today = new Date()
    let tomorrow =  new Date()
    tomorrow.setDate(today.getDate() + 1);


    const [backDrop,setBackDrop] = useState(false);
    const [dateType, setDateType] = useState("Delivery");
    const [pickingDate,setPickingDate] = useState("");
    const [deliveryDate,setDeliveryDate] = useState(formatDate(tomorrow));

    const [summaryType,setSummaryType] = useState("SELECT");
    const [shipModifyOrdersData, setShipModifyOrdersData] = useState({});
    const [pickingData, setPickingData] = useState({});
    const [loadingData, setLoadingData] = useState({});
    const [pickingRouteTypeStatusMap, setPickingRouteTypeStatusMap] = useState({});
    const [pickingRouteTypeWarehouseMap, setPickingRouteTypeWarehouseMap] = useState({});
    const [pickingRouteTypeDiscrepancyMap, setPickingRouteTypeDiscrepancyMap] = useState({});
    const [loadingRouteTypeStatusMap, setLoadingRouteTypeStatusMap] = useState({});
    const [loadingRouteTypeWarehouseMap, setLoadingRouteTypeWarehouseMap] = useState({});
    const [loadingRouteTypeDiscrepancyMap, setLoadingRouteTypeDiscrepancyMap] = useState({});

    const [warehouseList, setWarehouseList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [varietyList, setVarietyList] = useState([]);

    const [selectedWarehouse, setSelectedWarehouse] = useState("SELECT");
    const [selectedRoute, setSelectedRoute] = useState("SELECT");
    const [selectedStatus, setSelectedStatus] = useState("SELECT");
    const [selectedVariety, setSelectedVariety] = useState("SELECT");
    const [isError, setIsError] = useState(false);

    const [pickingFlag, setPickingFlag] = useState(false);
    const [allErrorsFlag, setAllErrorsFlag] = useState(false);
    const [dohData, setDohData] = useState({});

    const [unpickingList, setUnpickingList] = useState([]);

    const [pickingStatus, setPickingStatus] = useState("");
    const [loadingStatus, setLoadingStatus] = useState("");
    const [shipModifyStatus, setShipModifyStatus] = useState("");
    const [allErrorStatus, setAllErrorStatus] = useState([]);

    const dataToPrint = useRef()

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
      console.log(pickingDate);
    },[pickingDate])

    useEffect(() => {
      if(deliveryDate || pickingDate) {
        resetFilters();
        if(summaryType=="Picking") {
          getPickingSummary();
        } else if(summaryType=="Loading") {
          getLoadingSummary();
        } else if(summaryType=="Shipped Modification") {
          getShippedModificationSummary();
        }
      }
    }, [deliveryDate, pickingDate, summaryType])

    useEffect(() => {
      setBackDrop(true);
      let selectDate = new Date(deliveryDate);
      let year = selectDate.getFullYear();
      let month = selectDate.getMonth() + 1;
      let date = selectDate.getDate();

      Promise.all([
        fire.database().ref(`Driver_Orders_Short_Orders/${year}/${month}/${date}-${month}-${year}`).once("value")
      ]).then(([ snapshot ]) => {
        
        let shipModifyOrdersObj = snapshot.val() || {};
        let finalShipModifyList = [];
        Object.keys(shipModifyOrdersObj).map((rkey,rindex) => {
          let routeObj = shipModifyOrdersObj[rkey];
          Object.keys(routeObj).map((key,index) => {
            let itemObj = routeObj[key];

            if(itemObj.hasOwnProperty("item_deleted_qty")) {
              itemObj.route = rkey;
              finalShipModifyList.push(itemObj);
            } else if(itemObj.hasOwnProperty("freeproductlist")) {
              let freeproductlist = itemObj.freeproductlist || {};
              
              Object.keys(freeproductlist).map((key,index) => {
                let freeProdObj = freeproductlist[key];
                if(freeProdObj.hasOwnProperty("item_deleted_qty")) {
                  itemObj.route = rkey;
                  itemObj.item_deleted_qty = freeProdObj.item_deleted_qty;
                  finalShipModifyList.push(itemObj);
                }
              })
            }

          })
        })

        setUnpickingList(finalShipModifyList);
        setBackDrop(false);
      })
    }, [deliveryDate])

    const getPickingSummary = () => {
      setBackDrop(true);
      let selectDate = new Date(pickingDate);
      let year = selectDate.getFullYear();
      let month = selectDate.getMonth() + 1;
      let date = selectDate.getDate();

      let selectDeliveryDate = new Date(deliveryDate);
      let delYear = selectDeliveryDate.getFullYear();
      let delMonth = selectDeliveryDate.getMonth() + 1;
      let delDate = selectDeliveryDate.getDate();

      Promise.all([
        fire.database().ref(`Driver_Orders_History/${delYear}/${delMonth}/${delDate}-${delMonth}-${delYear}`).once("value"),
        fire.database().ref(`Picking_Orders_History/${year}/${month}/${date}-${month}-${year}`).once("value"),
        fire.database().ref(`Picking_Allocation_History/${year}/${month}/${date}-${month}-${year}`).once("value")
      ]).then(([dohSnap, orderSnap, allocationSnap]) => {
        let pickingObj = orderSnap.val() || {};
        let allocationObj = allocationSnap.val() || {};
        let driverOrdersObj = dohSnap.val() || {};

        let pickingCreatedMap = {};
        let dohRoutes = Object.keys(driverOrdersObj) || [];
        for(let dohRoute of dohRoutes) {
          if(Number(dohRoute)!=9998 && Number(dohRoute)!=9999) {
            pickingCreatedMap[dohRoute] = "N";
          }
        }

        let pickingRoutes = Object.keys(pickingObj) || [];
        let pickingAllocatedMap = {};
        for(let pickRoute of pickingRoutes) {
          pickingAllocatedMap[pickRoute] = "N";
        }


        let pickingRouteStatusMap = {};
        let statusSet = new Set();
        statusSet.add("UnAssigned");
        statusSet.add("Assigned");

        let pickingNotCompletedRoutes = "";
        Object.keys(allocationObj).map((pkey,pindex) => {
          let routeObj = allocationObj[pkey] || {};

          Object.keys(routeObj).map((rkey,rindex) => {
            let status = routeObj[rkey].status || "";
            let bag_id_update = routeObj[rkey].bag_id_update || "";

            if(!statusSet.has(status) && status) {
              statusSet.add(status);
            }

            pickingAllocatedMap[rkey] = "Y";
            pickingRouteStatusMap[rkey] = status;
          })
        })

        let finalLoadingObj = {};
        let vehicleSet = new Set();
        let varietySet = new Set();
        let warehouseSet = new Set();
        let pickingRouteWarehouseMap = {};
        let pickingRouteDiscrepancyMap = {};
        Object.keys(pickingObj).map((rkey,rindex) => {
          let routeObj = pickingObj[rkey] || {};

          if(pickingRouteStatusMap[rkey]!="Completed" && pickingRouteStatusMap[rkey]!="ShortPicked") {
            pickingNotCompletedRoutes += `${rkey},`
          }

          let pickingRoute = rkey.split("-")[0];
          pickingCreatedMap[pickingRoute] = "Y";

          Object.keys(routeObj).map((key,index) => {
            let itemObj = routeObj[key] || {};
            let vehicle_no = routeObj[key].vehicle_no || "";
            let variety = routeObj[key].type || "";
            variety = variety.replace(" ","_");
            let warehouse = routeObj[key].city || "";

            if(!warehouseSet.has(warehouse)) {
              warehouseSet.add(warehouse);
            }

            pickingRouteWarehouseMap[rkey] = warehouse;

            if(itemObj.hasOwnProperty("picked_qty") && itemObj.picked_qty!="" && (Number(itemObj.picked_qty)!=Number(itemObj.order_qty))) {
              pickingRouteDiscrepancyMap[rkey] = "Y";
            }

            if(!varietySet.has(variety)) {
              varietySet.add(variety);
            }

            if(!vehicleSet.has(vehicle_no)) {
              vehicleSet.add(vehicle_no);
            }
          })
        })

        setBackDrop(false);
        setPickingData(pickingObj);
        setStatusList([...statusSet]);
        setRouteList([...vehicleSet]);
        setVarietyList([...varietySet]);
        setWarehouseList([...warehouseSet]);
        setPickingRouteTypeStatusMap(pickingRouteStatusMap);
        setPickingRouteTypeWarehouseMap(pickingRouteWarehouseMap);
        setPickingRouteTypeDiscrepancyMap(pickingRouteDiscrepancyMap);

        let pickingNotCreatedRoutes = "";
        Object.keys(pickingCreatedMap).map((key,index) => {
          if(pickingCreatedMap[key]=="N") {
            pickingNotCreatedRoutes += `${key},`;
          }
        })

        let pickingNotAllocatedRoutes = "";
        Object.keys(pickingAllocatedMap).map((key,index) => {
          if(pickingAllocatedMap[key]=="N") {
            pickingNotAllocatedRoutes += `${key},`;
          }
        })

        let allErrors=[];
        if(pickingNotCreatedRoutes) {
          pickingNotCreatedRoutes = pickingNotCreatedRoutes.slice(0,-1);
          allErrors.push(`Picking not created for routes: ${pickingNotCreatedRoutes}`);
        }

        if(pickingNotAllocatedRoutes) {
          pickingNotAllocatedRoutes = pickingNotAllocatedRoutes.slice(0,-1);
          allErrors.push(`Routes not allocated for picking: ${pickingNotAllocatedRoutes}`);
        }

        if(pickingNotCompletedRoutes) {
          pickingNotCompletedRoutes = pickingNotCompletedRoutes.slice(0,-1);
          allErrors.push(`Routes not completed for picking: ${pickingNotCompletedRoutes}`);
        }

        if(pickingNotCreatedRoutes) {
          setPickingStatus(`Picking not created for routes: ${pickingNotCreatedRoutes}`);
        }else if(pickingNotAllocatedRoutes) {
          setPickingStatus(`Routes not allocated for picking: ${pickingNotAllocatedRoutes}`);
        }else if(pickingNotCompletedRoutes) {
          setPickingStatus(`Routes not completed for picking: ${pickingNotCompletedRoutes}`);
        }else{
          setPickingStatus(`All routes picking completed.`);
        }

        setAllErrorStatus(allErrors);
      })
    }

    const PickingRouteItemsTableHeader = ({routekey}) => {
        return (
            <thead className="thead-light">
                <tr>
                    <th>Item ID</th>
                    <th>Item Description</th>
                    <th>Image</th>
                    <th>Type</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    {routekey.includes("LOOSE") && <th>Order Number</th>}
                    {routekey.includes("LOOSE") && <th>Bag ID</th>}
                    <th>Order Qty</th>
                    <th>Picked Qty</th>
                </tr>
            </thead>
        );
    }

    const PickingRouteItemsTableBody = ({routeData,routekey}) => {
      let finalRouteData=routeData;

      let rows = Object.keys(finalRouteData).map((rkey, index) => {
        let row = finalRouteData[rkey] || {};

        let bgColor = "";
        if((row.hasOwnProperty("picked_qty") && row['picked_qty']!="" && Number(row['picked_qty'])!=Number(row['order_qty'])) || row.hasOwnProperty("modified_qty")) {
          bgColor = "coral";
        }

        let str = ``;

        if(!isError || (isError && bgColor == "coral")) {
          return (
              <tr key={index} style={{ backgroundColor: bgColor }}>
                  <td>{row['item_id']}</td>
                  <td>{row['long_description']}</td>
                  {row['product_image_url']?(<td><a href={row['product_image_url']} target="_blank"><img
                                                src={row['product_image_url']}
                                                alt="Product Image"
                                                height={70}
                                                width={70}
                                              /></a></td>):(<td></td>)}
                  <td>{row['type']}</td>
                  <td>{row['category']}</td>
                  <td>{row['sub_category']}</td>
                  {routekey.includes("LOOSE") && <td>{row['order_number']}</td>}
                  {routekey.includes("LOOSE") && <td>{row['bag_id']}</td>}
                  {row.hasOwnProperty("modified_qty")?(<><td>{row['modified_qty']} <s>{row['order_qty']}</s></td></>):(<td>{row['order_qty']}</td>)}
                  <td>{row['picked_qty']}</td>
              </tr>
          );
        }
      });

      return (<><tbody>{rows}</tbody></>);
    }

    const PickingRouteItemsTable = ({routeData, routekey}) => {
      return (
          <>
            <table className="table table-striped">
                  <PickingRouteItemsTableHeader routekey={routekey} />
                  <PickingRouteItemsTableBody routeData={routeData} routekey={routekey} />
            </table>
          </>
      );
    }

    const PickingItemsTable = () => {
      let rows = Object.keys(pickingData).map((rkey, index) => {
        let routeData = pickingData[rkey] || {};

        if(Object.keys(routeData).length>0){
          let status = pickingRouteTypeStatusMap[rkey]?pickingRouteTypeStatusMap[rkey]:(pickingRouteTypeStatusMap[rkey]==""?"Assigned":"UnAssigned");

          if((!isError || (isError && pickingRouteTypeDiscrepancyMap[rkey]=="Y")) && (selectedWarehouse=="SELECT" || selectedWarehouse==pickingRouteTypeWarehouseMap[rkey]) && (selectedStatus=="SELECT" || selectedStatus==status) && (selectedRoute=="SELECT" || rkey.includes(selectedRoute)) && (selectedVariety=="SELECT" || rkey.includes(selectedVariety))) {
            return (
              <div key={rkey}>
                <center><h4>Route - {rkey} ( {status} )</h4></center>
                <PickingRouteItemsTable routeData={routeData} routekey={rkey} />
              </div>
            );
          }
        }
      });

      return (<><div ref={dataToPrint} style={{ marginTop:70}}>{rows}</div></>);
    }

    const getLoadingSummary = () => {
      setBackDrop(true);
      let selectDate = new Date(pickingDate);
      let year = selectDate.getFullYear();
      let month = selectDate.getMonth() + 1;
      let date = selectDate.getDate();

      Promise.all([
        fire.database().ref(`Picking_Orders_History/${year}/${month}/${date}-${month}-${year}`).once("value"),
        fire.database().ref(`Loading_Allocation_History/${year}/${month}/${date}-${month}-${year}`).once("value"),
        fire.database().ref(`Picking_Allocation_History/${year}/${month}/${date}-${month}-${year}`).once("value")
      ]).then(([orderSnap, allocationSnap, pickingSnap]) => {
        let loadingObj = orderSnap.val() || {};
        let allocationObj = allocationSnap.val() || {};
        let pickingObj = pickingSnap.val() || {};

        let loadingRouteStatusMap = {};
        let statusSet = new Set();
        statusSet.add("UnAssigned");
        statusSet.add("Assigned");

        let loadingNotCompletedRoutes = "";
        Object.keys(allocationObj).map((pkey,pindex) => {
          let routeObj = allocationObj[pkey] || {};

          Object.keys(routeObj).map((rkey,rindex) => {
            let loose_pack_status = routeObj[rkey].loose_pack_status || "";
            let high_value_status = routeObj[rkey].high_value_status || "";
            let bag_status = routeObj[rkey].bag_status || "";
            let fmcg_status = routeObj[rkey].fmcg_status || "";

            if(!statusSet.has(loose_pack_status) && loose_pack_status) {
              statusSet.add(loose_pack_status);
            }

            if(!statusSet.has(high_value_status) && high_value_status) {
              statusSet.add(high_value_status);
            }

            if(!statusSet.has(fmcg_status) && fmcg_status) {
              statusSet.add(fmcg_status);
            }

            if(!statusSet.has(bag_status) && bag_status) {
              statusSet.add(bag_status);
            }

            loadingRouteStatusMap[`${rkey}-BAG`] = bag_status;
            loadingRouteStatusMap[`${rkey}-FMCG`] = fmcg_status;
            loadingRouteStatusMap[`${rkey}-HIGH_VALUE`] = high_value_status;
            loadingRouteStatusMap[`${rkey}-LOOSE_PACK`] = loose_pack_status;

            if(bag_status!="Completed" && bag_status!="ShortLoaded" && routeObj[rkey].hasOwnProperty("bag_status")) {
              loadingNotCompletedRoutes += `${rkey}-BAG,`;
            }

            if(fmcg_status!="Completed" && fmcg_status!="ShortLoaded" && routeObj[rkey].hasOwnProperty("fmcg_status")) {
              loadingNotCompletedRoutes += `${rkey}-FMCG,`;
            }

            if(high_value_status!="Completed" && high_value_status!="ShortLoaded" && routeObj[rkey].hasOwnProperty("high_value_status")) {
              loadingNotCompletedRoutes += `${rkey}-HIGH_VALUE,`;
            }

            if(loose_pack_status!="Completed" && loose_pack_status!="ShortLoaded" && routeObj[rkey].hasOwnProperty("loose_pack_status")) {
              loadingNotCompletedRoutes += `${rkey}-LOOSE_PACK,`;
            }
          })
        })

        let pickingCompletedRouteSet = new Set();
        let pickingNotCompletedRoutes = "";
        let pickingRouteStatusMap = {};

        let shipModifyNotDoneRoutes = "";
        let loadingAllocatedMap = {};
        Object.keys(pickingObj).map((pkey,pindex) => {
          let routeObj = pickingObj[pkey] || {};

          Object.keys(routeObj).map((rkey,rindex) => {
            let bag_id_update = routeObj[rkey].bag_id_update || "";
            let status = routeObj[rkey].status || "";
            let picker_route = routeObj[rkey].picker_route || "";

            if((status=="ShortPicked" || status=="Completed") && bag_id_update=="true") {
              loadingAllocatedMap[picker_route] = "N";
            }

            if(bag_id_update=="true" && !pickingCompletedRouteSet.has(rkey)) {
              pickingCompletedRouteSet.add(rkey);
            }

            if(bag_id_update!="true") {
              shipModifyNotDoneRoutes += `${rkey},`;
            }

            pickingRouteStatusMap[rkey] = status;
          })
        })

        Object.keys(allocationObj).map((pkey,pindex) => {
          let routeObj = allocationObj[pkey] || {};

          let loadingRoutes = Object.keys(routeObj) || [];
          for(let loadingRoute of loadingRoutes) {
            loadingAllocatedMap[loadingRoute] = "Y";
          }
        })

        let pickingCompletedRouteList = Array.from(pickingCompletedRouteSet);

        let unassignedRoute = new Set();
        Object.keys(pickingRouteStatusMap).map((key,index) => {
          if(pickingRouteStatusMap[key]!="Completed") {
            if(!loadingRouteStatusMap.hasOwnProperty(key)) {
              if(!unassignedRoute.has(key)) {
                unassignedRoute.add(key);
              }
            }
          }
        })

        let finalLoadingObj = {};
        let vehicleSet = new Set();
        let warehouseSet = new Set();
        let loadingRouteWarehouseMap = {};
        let loadingRouteDiscrepancyMap = {};
        Object.keys(loadingObj).map((rkey,rindex) => {
          let routeObj = loadingObj[rkey] || {};

          if(pickingRouteStatusMap[rkey]!="Completed" && pickingRouteStatusMap[rkey]!="ShortPicked") {
            pickingNotCompletedRoutes += `${rkey},`
          }

          Object.keys(routeObj).map((key,index) => {
            let itemObj = routeObj[key] || {};
            if(itemObj.hasOwnProperty("loaded_qty") || unassignedRoute.has(rkey) || loadingRouteStatusMap[rkey]) {
              if(!finalLoadingObj[rkey]) {
                finalLoadingObj[rkey] = {};
              }

              if(!finalLoadingObj[rkey][key]) {
                finalLoadingObj[rkey][key] = itemObj;
              }

              let vehicle_no = routeObj[key].vehicle_no || "";
              let warehouse = routeObj[key].city || "";
              loadingRouteWarehouseMap[rkey] = warehouse;

              if(itemObj.hasOwnProperty("loaded_qty") && itemObj.loaded_qty!="" && (Number(itemObj.loaded_qty)!=Number(itemObj.picked_qty))) {
                loadingRouteDiscrepancyMap[rkey] = "Y";
              }

              if(!warehouseSet.has(warehouse)) {
                warehouseSet.add(warehouse);
              }

              if(!vehicleSet.has(vehicle_no)) {
                vehicleSet.add(vehicle_no);
              }
            }
          })
        })

        console.log(finalLoadingObj);

        setBackDrop(false);
        setLoadingData(finalLoadingObj);
        setRouteList([...vehicleSet]);
        setStatusList([...statusSet]);
        setWarehouseList([...warehouseSet]);
        setLoadingRouteTypeStatusMap(loadingRouteStatusMap);
        setLoadingRouteTypeWarehouseMap(loadingRouteWarehouseMap);
        setLoadingRouteTypeDiscrepancyMap(loadingRouteDiscrepancyMap);

        let loadingNotAllocatedStr = "";
        Object.keys(loadingAllocatedMap).map((key,index) => {
          if(loadingAllocatedMap[key]=="N") {
            loadingNotAllocatedStr += `${key},`
          }
        })

        let allErrors = [];
        if(Object.keys(pickingObj).length==0) {
          allErrors.push(`Picking allocation data not present`)
        }

        if(Object.keys(allocationObj).length==0) {
          allErrors.push(`Loading allocation data not present`)
        }

        if(loadingNotAllocatedStr) {
          loadingNotAllocatedStr = loadingNotAllocatedStr.slice(0,-1);
          allErrors.push(`Routes picking completed, shipped modification done but not allocated to loading : ${loadingNotAllocatedStr}`);
        }

        if(loadingNotCompletedRoutes) {
          loadingNotCompletedRoutes = loadingNotCompletedRoutes.slice(0,-1);
          allErrors.push(`Loading allocated but not completed: ${loadingNotCompletedRoutes}`);
        }

        if(Object.keys(pickingObj).length==0) {
          setLoadingStatus(`Picking allocation data not present`)
        } else if(Object.keys(allocationObj).length==0) {
          setLoadingStatus(`Loading allocation data not present`)
        } else if(loadingNotAllocatedStr) {
          setLoadingStatus(`Routes picking completed, shipped modification done but not allocated to loading : ${loadingNotAllocatedStr}`);
        }else if(loadingNotCompletedRoutes) {
          setLoadingStatus(`Loading allocated but not completed: ${loadingNotCompletedRoutes}`);
        }else{
          setLoadingStatus(`All the Routes are Loaded.`);
        }

        setAllErrorStatus(allErrors);
      })
    }

    const LoadingRouteItemsTableHeader = ({routekey}) => {
        return (
            <thead className="thead-light">
                <tr>
                    <th>Item ID</th>
                    <th>Item Description</th>
                    <th>Image</th>
                    <th>Type</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    {routekey.includes("LOOSE") && <th>Order Number</th>}
                    {routekey.includes("LOOSE") && <th>Bag ID</th>}
                    <th>Order Qty</th>
                    <th>Picked Qty</th>
                    <th>Loading Qty</th>
                </tr>
            </thead>
        );
    }

    const LoadingRouteItemsTableBody = ({ routeData, routekey }) => {
      let finalRouteData=routeData;

      let rows = Object.keys(finalRouteData).map((rkey, index) => {
        let row = finalRouteData[rkey] || {};

        let bgColor = "";
        if((row.hasOwnProperty("loaded_qty") && row['loaded_qty']!="" && Number(row['picked_qty'])!=Number(row['loaded_qty'])) || row.hasOwnProperty("modified_qty")) {
          bgColor = "coral";
        }

        if(!isError || (isError && bgColor == "coral")) {
          return (
              <tr key={index} style={{ backgroundColor: bgColor }}>
                  <td>{row['item_id']}</td>
                  <td>{row['long_description']}</td>
                  {row['product_image_url']?(<td><a href={row['product_image_url']} target="_blank"><img
                                                src={row['product_image_url']}
                                                alt="Product Image"
                                                height={70}
                                                width={70}
                                              /></a></td>):(<td></td>)}
                  <td>{row['type']}</td>
                  <td>{row['category']}</td>
                  <td>{row['sub_category']}</td>
                  {routekey.includes("LOOSE") && <td>{row['order_number']}</td>}
                  {routekey.includes("LOOSE") && <td>{row['bag_id']}</td>}
                  {row.hasOwnProperty("modified_qty")?(<><td>{row['modified_qty']} <s>{row['order_qty']}</s></td></>):(<td>{row['order_qty']}</td>)}
                  <td>{row['picked_qty']}</td>
                  <td>{row['loaded_qty']}</td>
              </tr>
          );
        }
      });

      return (<><tbody>{rows}</tbody></>);
    }

    const LoadingRouteItemsTable = ({routeData,routekey}) => {
      return (
          <>
            <table className="table table-striped">
                  <LoadingRouteItemsTableHeader routekey={routekey} />
                  <LoadingRouteItemsTableBody routeData={routeData} routekey={routekey} />
            </table>
          </>
      );
    }

    const LoadingItemsTable = () => {
      let rows = Object.keys(loadingData).map((rkey, index) => {
        let routeData = loadingData[rkey] || {};

        if(Object.keys(routeData).length>0){
          let status = loadingRouteTypeStatusMap[rkey]?loadingRouteTypeStatusMap[rkey]:(loadingRouteTypeStatusMap[rkey]==""?"Assigned":"UnAssigned");

          if((!isError || (isError && loadingRouteTypeDiscrepancyMap[rkey]=="Y")) && (selectedWarehouse=="SELECT" || selectedWarehouse==loadingRouteTypeWarehouseMap[rkey]) && (selectedStatus=="SELECT" || selectedStatus==status) && (selectedRoute=="SELECT" || rkey.includes(selectedRoute)) && (selectedVariety=="SELECT" || rkey.includes(selectedVariety))) {
            return (
              <div key={rkey}>
                <center><h4>Route - {rkey} ( {status} )</h4></center>
                <LoadingRouteItemsTable routeData={routeData} routekey={rkey} />
              </div>
            );
          }
        }
      });

      return (<><div ref={dataToPrint} style={{ marginTop:70}}>{rows}</div></>);
    }

    const getShippedModificationSummary = () => {
      setBackDrop(true);
      let selectDate = new Date(deliveryDate);
      let year = selectDate.getFullYear();
      let month = selectDate.getMonth() + 1;
      let date = selectDate.getDate();


      let pickedDate = new Date(pickingDate);
      let picked_year = pickedDate.getFullYear();
      let picked_month = pickedDate.getMonth() + 1;
      let picked_date = pickedDate.getDate();

      Promise.all([
        fire.database().ref(`Driver_Orders_Short_Orders/${year}/${month}/${date}-${month}-${year}`).once("value"),
        fire.database().ref(`Picking_Allocation_History/${picked_year}/${picked_month}/${picked_date}-${picked_month}-${picked_year}`).once("value"),
        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}-${month}-${year}`).once("value")
      ]).then(([snapshot, pickingSnap, ordersSnap]) => {
        let pickingObj = pickingSnap.val() || {};
        let getOrderHistory = ordersSnap.val() || {};

        let shipModifyCompletedMap = {};
        Object.keys(pickingObj).map((pkey,pindex) => {
          let routeObj = pickingObj[pkey] || {};

          Object.keys(routeObj).map((rkey,rindex) => {
            let status = routeObj[rkey].status || "";
            let picker_route = routeObj[rkey].picker_route || "";
            let bag_id_update = routeObj[rkey].bag_id_update || "";

            if(status=="Completed" || status=="ShortPicked") {
              shipModifyCompletedMap[picker_route] = "N";

              if(bag_id_update=="true") {
                shipModifyCompletedMap[picker_route] = "Y";
              }
            }
          })
        })

        let shipModifyOrdersObj = snapshot.val() || {};
        let finalShipModifyObj = {};
        Object.keys(shipModifyOrdersObj).map((rkey,rindex) => {
          let routeObj = shipModifyOrdersObj[rkey];
          let driverRouteObj = getOrderHistory[rkey] || {};

          shipModifyCompletedMap[rkey] = "Y";

          Object.keys(routeObj).map((key,index) => {
            let itemObj = routeObj[key];
            let revisedOrderAmount = Number(itemObj['revised_order_amt']) || 0;
            let orderDisc = Number(itemObj['order_disc']) || 0;
            let revisedOrderDisc = Number(itemObj['revised_order_disc']) || 0;
            let finalOrderValue = revisedOrderAmount + orderDisc - revisedOrderDisc;

            itemObj.final_order_value = finalOrderValue;

            if(!finalShipModifyObj[rkey]) {
              finalShipModifyObj[rkey] = {};
            }

            if(!finalShipModifyObj[rkey][key]) {
              // doh check
              if(!driverRouteObj[key]) {
                itemObj.error_msg = `Order was deleted`
              } else {
                let orderStatus = driverRouteObj[key].status || "";
                if((orderStatus!="Shipped-Modified" && orderStatus!="Partially-Delivered") || !driverRouteObj[key].hasOwnProperty("revised_order_amt")
                 || !driverRouteObj[key].hasOwnProperty("item_modified_qty")) {
                  itemObj.order_key = key;
                  itemObj.route = rkey;
                  itemObj.is_update = "Y";
                }
              }

              finalShipModifyObj[rkey][key] = itemObj;
            }
          })
        })

        setShipModifyOrdersData(finalShipModifyObj);
        setBackDrop(false);

        let shipModifyNotDoneRoutes = "";
        Object.keys(shipModifyCompletedMap).map((key,index) => {
          if(shipModifyCompletedMap[key]=="N") {
            shipModifyNotDoneRoutes += `${key},`;
          }
        })

        let allErrors = [];
        if(Object.keys(pickingObj).length==0) {
          allErrors.push(`Picking allocation data not present.`)
        }

        if(shipModifyNotDoneRoutes) {
          shipModifyNotDoneRoutes = shipModifyNotDoneRoutes.slice(0,-1);
          allErrors.push([`Routes picking completed, shipped modification not done. : ${shipModifyNotDoneRoutes}`])
        }


        if(Object.keys(pickingObj).length==0) {
          setShipModifyStatus(`Picking allocation data not present.`)
        } else if(shipModifyNotDoneRoutes) {
          setShipModifyStatus(`Routes picking completed, shipped modification not done. : ${shipModifyNotDoneRoutes}`);
        }else{
          setShipModifyStatus(`Shipped Modification is done for all routes.`);
        }

        setAllErrorStatus(allErrors);

      })
    }

    const updateDohShipModifyItem = (item) => {
      console.log(item);

      if(!item) {
        toast(`No item data`, { type: toast.TYPE.ERROR });
        return;
      }

      let selectDate = new Date(deliveryDate);
      let year = selectDate.getFullYear();
      let month = selectDate.getMonth() + 1;
      let date = selectDate.getDate();

      let { route, order_key, revised_order_amt, revised_order_disc, item_modified_qty, order_number } = item;

      fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}-${month}-${year}/${route}`).once("value", (snapshot) => {
        let routeOrders = snapshot.val() || {};

        let fbBulkObj = {};
        Object.keys(routeOrders).map((okey,oindex) => {
          if(routeOrders[okey].order_number==order_number) {
            fbBulkObj[`/${okey}/status`] = "Shipped-Modified";
            fbBulkObj[`/${okey}/revised_order_amt`] = revised_order_amt;

            if(item.hasOwnProperty("revised_order_disc")) {
              fbBulkObj[`/${okey}/revised_order_disc`] = revised_order_disc;
            }

            if(okey==order_key) {
              fbBulkObj[`/${okey}/item_primary_reason`] = "Bill Modified";
              fbBulkObj[`/${okey}/item_reason`] = "Short Supply";
              fbBulkObj[`/${okey}/item_modified_qty`] = "" + item_modified_qty;
            }
          }
        })
        console.log(fbBulkObj);

        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}-${month}-${year}/${route}`).update(fbBulkObj)
        .then(() => {
          toast(`Successfully updated`, { type: toast.TYPE.SUCCESS });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        }).catch((err) => {
          toast(`${err}`, { type: toast.TYPE.ERROR });
        })
      })
    }

    const ShipModifyRouteOrdersTableHeader = () => {
        return (
            <thead className="thead-light">
                <tr>
                    <th>Order Number</th>
                    <th>Total Order Amt</th>
                    <th>Item ID</th>
                    <th>Image</th>
                    <th>Order Qty</th>
                    <th>Item Modified Qty</th>
                    <th>Unpicked Qty</th>
                    <th>Coupon Code</th>
                    <th>Revised Order Amt</th>
                    <th>Revised Disc</th>
                    <th>Freeproduct</th>
                    <th>Update</th>
                </tr>
            </thead>
        );
    }

    const ShipModifyRouteOrdersTableBody = (routeData) => {
      let finalRouteData=routeData['routeData'];
      console.log("finalRouteData");
      console.log(finalRouteData);

      let rows = Object.keys(finalRouteData).map((rkey, index) => {
        let row = finalRouteData[rkey] || {};
          let freeproductlist = row['freeproductlist'] || [];
          let bgColor = "";

          let finalOrderValue = Number(row['final_order_value']) || 0;
          if(finalOrderValue<1000) {
            bgColor = "coral";
          }

              return (
                  <tr key={index} style={{ backgroundColor: bgColor }}>
                      <td>{row['order_number']}</td>
                      <td>{row['total_amt']}</td>
                      <td>{row['item_id']}</td>
                      {row['image_url']?(<td><a href={row['image_url']} target="_blank"><img
                                                    src={row['image_url']}
                                                    alt="Product Image"
                                                    height={70}
                                                    width={70}
                                                  /></a></td>):(<td></td>)}
                      <td>{row['order_qty']}</td>
                      <td>{row['item_modified_qty']}</td>
                      <td>{row['item_deleted_qty']}</td>
                      <td>{row['coupon_code']}</td>
                      <td>{finalOrderValue}</td>
                      <td>{row['revised_order_disc']}</td>
                      <td>{freeproductlist && <ShipModifyRenderFreeProducts freeproductlist={freeproductlist} />}</td>
                      <td>{row['error_msg']?row['error_msg']:(row['is_update']=="Y"?(<Button variant="warning" onClick={() => { updateDohShipModifyItem(row); }}>Update</Button>):null)}</td>
                  </tr>
              );
          });

      return (<><tbody>{rows}</tbody></>);
    }

    const ShipModifyRenderFreeProducts = (freeproductlist) => {
      let freeProds = freeproductlist['freeproductlist'];

      let rows = freeProds.map((row, index) => {
              return (
                  <ul key={index}>
                      <li>ID - {row['freeProduct']}</li>
                      <li>Order Qty - {row['existingSugarQuantity']}</li>
                      <li>Modified Qty - {row['numSugarBags']}</li>
                  </ul>
              );
          });

      return (<><span>{rows}</span></>);
    }

    const ShipModifyRouteOrdersTable = (routeData) => {
      return (
          <>
            <table className="table table-striped">
                  <ShipModifyRouteOrdersTableHeader />
                  <ShipModifyRouteOrdersTableBody routeData={routeData['routeData']} />
            </table>
          </>
      );
    }

    const ShipModifyOrdersTable = () => {
      let rows = Object.keys(shipModifyOrdersData).map((rkey, index) => {
        let routeData = shipModifyOrdersData[rkey] || {};

        if(Object.keys(routeData).length>0){
          return (
            <div key={rkey}>
              <center><h4>Route - {rkey}</h4></center>
              <ShipModifyRouteOrdersTable routeData={routeData} />
            </div>
          );
        }
      });

      return (<><div ref={dataToPrint} style={{ marginTop:70}}>{rows}</div></>);
    }

    const RenderWarehouseDropdown = () => {
      let rows = warehouseList.map((warehouse,index)=>{
        return (<Dropdown.Item key={warehouse} name={warehouse} onClick={(e)=>{e.preventDefault();setSelectedWarehouse(e.target.name);}}>{warehouse}</Dropdown.Item>)
      });

      return (<>
        <InputGroup size="sm" style={{ marginLeft: 30, marginRight: 10, display:"flex" }}>
          <InputGroup.Prepend >
            <InputGroup.Text> Warehouse </InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton id="dropdown-basic-button" title={(selectedWarehouse!="SELECT")?selectedWarehouse:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton>
        </InputGroup>
      </>);
    }

    const RenderRouteDropdown = () => {
      let rows = routeList.map((route,index)=>{
        return (<Dropdown.Item key={route} name={route} onClick={(e)=>{e.preventDefault();setSelectedRoute(e.target.name);}}>{route}</Dropdown.Item>)
      });

      return (<>
        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 10, display:"flex" }}>
          <InputGroup.Prepend >
            <InputGroup.Text> Route </InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton id="dropdown-basic-button" title={(selectedRoute!="SELECT")?selectedRoute:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton>
        </InputGroup>
      </>);
    }

    const RenderVarietyDropdown = () => {
      let rows = varietyList.map((variety,index)=>{
        return (<Dropdown.Item key={variety} name={variety} onClick={(e)=>{e.preventDefault();setSelectedVariety(e.target.name);}}>{variety}</Dropdown.Item>)
      });

      return (<>
        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 10, display:"flex" }}>
          <InputGroup.Prepend >
            <InputGroup.Text> Type </InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton id="dropdown-basic-button" title={(selectedVariety!="SELECT")?selectedVariety:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton>
        </InputGroup>
      </>);
    }

    const RenderStatusDropdown = () => {
      let rows = statusList.map((status,index)=>{
        return (<Dropdown.Item key={status} name={status} onClick={(e)=>{e.preventDefault();setSelectedStatus(e.target.name);}}>{status}</Dropdown.Item>)
      });

      return (<>
        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 10, display:"flex" }}>
          <InputGroup.Prepend >
            <InputGroup.Text> Status </InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton id="dropdown-basic-button" title={(selectedStatus!="SELECT")?selectedStatus:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton>
        </InputGroup>
      </>);
    }

    const RenderDiscrepancySwitch = () => {
      return (<>
        <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 10, display:"flex" }}>
          <InputGroup.Prepend >
            <InputGroup.Text> Discrepancy </InputGroup.Text>
          </InputGroup.Prepend>
          <Switch onChange={(val) => { setIsError(val); }} checked={isError} />
        </InputGroup>
      </>);
    }

    const resetFilters = () => {
      setSelectedRoute("SELECT");
      setSelectedVariety("SELECT");
      setSelectedWarehouse("SELECT");
      setSelectedStatus("SELECT");
      setIsError(false);
    }

    const fetchDohData = () => {
      setBackDrop(true);
      let deliverydate = new Date(deliveryDate);
      let year = deliverydate.getFullYear();
      let month = deliverydate.getMonth() + 1;
      let date = deliverydate.getDate();
      let finaldate = `${date}-${month}-${year}`;

      fire.database().ref(`Driver_Orders_History/${year}/${month}/${finaldate}`).once("value", (snapshot) => {
        let getOrderHistory = snapshot.val() || {};
        setDohData(getOrderHistory);

        setTimeout(function() {
          setBackDrop(false);
          setPickingFlag(true);
        }, 2000)
      })
    }

    const exportPickingData = async () => {
      const workbook = new excel.Workbook();

      let varietyPickingData = {};
      Object.keys(pickingData).map((rkey,rindex) => {
        let routeObj = pickingData[rkey] || {};

        let status = pickingRouteTypeStatusMap[rkey]?pickingRouteTypeStatusMap[rkey]:(pickingRouteTypeStatusMap[rkey]==""?"Assigned":"UnAssigned");

        if((!isError || (isError && pickingRouteTypeDiscrepancyMap[rkey]=="Y")) && (selectedWarehouse=="SELECT" || selectedWarehouse==pickingRouteTypeWarehouseMap[rkey]) && (selectedStatus=="SELECT" || selectedStatus==status) && (selectedRoute=="SELECT" || rkey.includes(selectedRoute)) && (selectedVariety=="SELECT" || rkey.includes(selectedVariety))) {
          Object.keys(routeObj).map((key,index) => {
            let itemObj = routeObj[key] || {};
            let type = itemObj.type || "";

            if(isError) {
              if(pickingRouteTypeDiscrepancyMap[rkey]=="Y" && (itemObj.hasOwnProperty("picked_qty") && itemObj.picked_qty!="" && Number(itemObj.picked_qty)!=Number(itemObj.order_qty))) {
                if(!varietyPickingData[type]) {
                  varietyPickingData[type] = [];
                }
                varietyPickingData[type].push(itemObj);
              }
            } else {
              if(!varietyPickingData[type]) {
                varietyPickingData[type] = [];
              }
              varietyPickingData[type].push(itemObj);
            }

          })
        }

      })

      Object.keys(varietyPickingData).map((rkey,rindex) => {
        let routeData = varietyPickingData[rkey] || [];

        if(rkey) {
          const variety_sheet = workbook.addWorksheet(rkey);
          if(rkey.includes("LOOSE")) {
            variety_sheet.columns = [
              { header: 'Route', key: 'vehicle_no', width: 20 },
              { header: 'Item Id', key: 'item_id', width: 20 },
              { header: 'Item Description', key: 'long_description', width: 50 },
              { header: 'Type', key: 'type', width: 20 },
              { header: 'Category', key: 'category', width: 20 },
              { header: 'Sub Category', key: 'sub_category', width: 20 },
              { header: 'Order Number', key: 'order_number', width: 20 },
              { header: 'Bag ID', key: 'bag_id', width: 20 },
              { header: 'Order Qty', key: 'order_qty', width: 20},
              { header: 'Modified Qty', key: 'modified_qty', width: 20},
              { header: 'Picked Qty', key: 'picked_qty', width: 20}
            ];
          } else {
            variety_sheet.columns = [
              { header: 'Route', key: 'vehicle_no', width: 20 },
              { header: 'Item Id', key: 'item_id', width: 20 },
              { header: 'Item Description', key: 'long_description', width: 50 },
              { header: 'Type', key: 'type', width: 20 },
              { header: 'Category', key: 'category', width: 20 },
              { header: 'Sub Category', key: 'sub_category', width: 20 },
              { header: 'Order Qty', key: 'order_qty', width: 20},
              { header: 'Modified Qty', key: 'modified_qty', width: 20},
              { header: 'Picked Qty', key: 'picked_qty', width: 20}
            ];
          }

          variety_sheet.addRows(routeData);

          for(let i=0;i<routeData.length;i++) {
            if((routeData[i].hasOwnProperty("picked_qty") && routeData[i].picked_qty!="" && Number(routeData[i].picked_qty)!=Number(routeData[i].order_qty)) || routeData[i].hasOwnProperty("modified_qty")) {
              let row = variety_sheet.getRow(i+2);
              row.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0000' },
                bgColor: { argb: 'ff0000' }
              };
              row.commit();
            }
          }
        }
      })

      let buf = await workbook.xlsx.writeBuffer();
      console.log(buf);

      let fileName = `PickingSummary_${pickingDate}.xlsx`;
      return downloadFile(new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
    }

    const exportLoadingData = async () => {
      const workbook = new excel.Workbook();

      let varietyLoadingData = {};
      Object.keys(loadingData).map((rkey,rindex) => {
        let routeObj = loadingData[rkey] || {};

        let status = loadingRouteTypeStatusMap[rkey]?loadingRouteTypeStatusMap[rkey]:(loadingRouteTypeStatusMap[rkey]==""?"Assigned":"UnAssigned");

        if((!isError || (isError && loadingRouteTypeDiscrepancyMap[rkey]=="Y"))
         && (selectedWarehouse=="SELECT" || selectedWarehouse==loadingRouteTypeWarehouseMap[rkey])
         && (selectedStatus=="SELECT" || selectedStatus==status)
         && (selectedRoute=="SELECT" || rkey.includes(selectedRoute)) && (selectedVariety=="SELECT" || rkey.includes(selectedVariety))) {
           Object.keys(routeObj).map((key,index) => {
             let itemObj = routeObj[key] || {};
             let type = itemObj.type || "";

             if(isError) {
               if(loadingRouteTypeDiscrepancyMap[rkey]=="Y" &&
               (itemObj.hasOwnProperty("loaded_qty") && itemObj.loaded_qty!="" && Number(itemObj.picked_qty)!=Number(itemObj.loaded_qty))) {
                 if(!varietyLoadingData[type]) {
                   varietyLoadingData[type] = [];
                 }
                 varietyLoadingData[type].push(itemObj);
               }
             } else {
               if(!varietyLoadingData[type]) {
                 varietyLoadingData[type] = [];
               }
               varietyLoadingData[type].push(itemObj);
             }
           })
        }
      })

      Object.keys(varietyLoadingData).map((rkey,rindex) => {
        let routeData = varietyLoadingData[rkey] || [];

        if(rkey) {
          const variety_sheet = workbook.addWorksheet(rkey);
          if(rkey.includes("LOOSE")) {
            variety_sheet.columns = [
              { header: 'Route', key: 'vehicle_no', width: 20 },
              { header: 'Item Id', key: 'item_id', width: 20 },
              { header: 'Item Description', key: 'long_description', width: 50 },
              { header: 'Type', key: 'type', width: 20 },
              { header: 'Category', key: 'category', width: 20 },
              { header: 'Sub Category', key: 'sub_category', width: 20 },
              { header: 'Order Number', key: 'order_number', width: 20 },
              { header: 'Bag ID', key: 'bag_id', width: 20 },
              { header: 'Order Qty', key: 'order_qty', width: 20},
              { header: 'Modified Qty', key: 'modified_qty', width: 20},
              { header: 'Picked Qty', key: 'picked_qty', width: 20},
              { header: 'Loaded Qty', key: 'loaded_qty', width: 20}
            ];
          } else {
            variety_sheet.columns = [
              { header: 'Route', key: 'vehicle_no', width: 20 },
              { header: 'Item Id', key: 'item_id', width: 20 },
              { header: 'Item Description', key: 'long_description', width: 50 },
              { header: 'Type', key: 'type', width: 20 },
              { header: 'Category', key: 'category', width: 20 },
              { header: 'Sub Category', key: 'sub_category', width: 20 },
              { header: 'Order Qty', key: 'order_qty', width: 20},
              { header: 'Modified Qty', key: 'modified_qty', width: 20},
              { header: 'Picked Qty', key: 'picked_qty', width: 20},
              { header: 'Loaded Qty', key: 'loaded_qty', width: 20}
            ];
          }

          variety_sheet.addRows(routeData);

          for(let i=0;i<routeData.length;i++) {
            if((routeData[i].hasOwnProperty("loaded_qty") && routeData[i].loaded_qty!="" && Number(routeData[i].picked_qty)!=Number(routeData[i].loaded_qty)) || routeData[i].hasOwnProperty("modified_qty")) {
              let row = variety_sheet.getRow(i+2);
              row.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'ff0000' },
                bgColor: { argb: 'ff0000' }
              };
              row.commit();
            }
          }
        }
      })

      let buf = await workbook.xlsx.writeBuffer();
      console.log(buf);

      let fileName = `LoadingSummary_${pickingDate}.xlsx`;
      return downloadFile(new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
    }

    const exportShippedModificationData = async () => {
      const workbook = new excel.Workbook();

      Object.keys(shipModifyOrdersData).map((rkey,rindex) => {
        let routeObj = shipModifyOrdersData[rkey] || {};

        const route_sheet = workbook.addWorksheet(rkey);
        route_sheet.columns = [
          { header: 'Order Number', key: 'order_number', width: 20 },
          { header: 'Item Id', key: 'item_id', width: 20 },
          { header: 'Total Order Amt', key: 'total_amt', width: 50 },
          { header: 'Order Qty', key: 'order_qty', width: 20},
          { header: 'Modified Qty', key: 'item_modified_qty', width: 20},
          { header: 'Coupon Code', key: 'coupon_code', width: 20 },
          { header: 'Revised Order Amt', key: 'revised_order_amt', width: 20 },
          { header: 'Revised Order Disc', key: 'final_order_value', width: 20}
        ];

        Object.keys(routeObj).map((key,index) => {
          let itemObj = routeObj[key] || {};

          route_sheet.addRow(itemObj);
        })
      })

      let buf = await workbook.xlsx.writeBuffer();
      console.log(buf);

      let fileName = `ShippedModificationSummary_${deliveryDate}.xlsx`;
      return downloadFile(new Blob([buf], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
    }

    function downloadFile(blob: Blob, fileName) {
      const a: HTMLAnchorElement = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);

      const url: string = window.URL.createObjectURL(blob);

      a.href = url;
      a.download = fileName;

      a.click();

      window.URL.revokeObjectURL(url);

      if (a && a.parentElement) {
          a.parentElement.removeChild(a);
      }
    }

    useEffect(() => {
      updateDate(formatDate(tomorrow), "Delivery");
    }, [])

    const updateDate = (date, dtype) => {
      if(dtype=="Delivery") {

        Promise.all([
          fetch(`${service_url}/delivery-picking-date?delivery_date=${formatDate(date)}`).then(response=>response.json())
        ]).then(([dateData]) => {
          let dateObj = dateData.data || {};

          if(dateData.success==1 && Object.keys(dateObj).length>0) {
            setDeliveryDate(formatDate(date));
            let pickdt = formatDate(dateObj.picking_date);
            setPickingDate(pickdt);
          } else {
            setDeliveryDate(formatDate(date));
            setPickingDate("");
            toast(`No picking date present corresponding to delivery date - ${formatDate(date)}`, { type: toast.TYPE.ERROR });
          }
        }).catch((err) => {
          setDeliveryDate(formatDate(date));
          setPickingDate("");
          toast(`Error in fetching picking date relation - ${err}`, { type: toast.TYPE.ERROR });
        })
      } else if(dtype=="Picking") {
        Promise.all([
          fetch(`${service_url}/delivery-picking-date?picking_date=${formatDate(date)}`).then(response=>response.json())
        ]).then(([dateData]) => {
          let dateObj = dateData.data || {};

          if(dateData.success==1 && Object.keys(dateObj).length>0) {
            setPickingDate(formatDate(date));
            let deliverydt = formatDate(dateObj.delivery_date);
            setDeliveryDate(deliverydt);
          } else {
            setPickingDate(formatDate(date));
            setDeliveryDate("");
            toast(`No delivery date present corresponding to picking date - ${formatDate(date)}`, { type: toast.TYPE.ERROR });
          }
        }).catch((err) => {
          setPickingDate(formatDate(date));
          setDeliveryDate("");
          toast(`Error in fetching picking date relation - ${err}`, { type: toast.TYPE.ERROR });
        })
      }
    }

    const UnpickingItemsRenderTable = () => {
      return (
        <div style={{marginTop:15}}>
          <center><h4>Unpicking</h4></center>
          <UnpickingItemsTable />
        </div>
      );
    }

    const UnpickingItemsTable = () => {
      return (
          <>
            <table className="table table-striped">
                  <UnpickingItemsTableHeader />
                  <UnpickingItemsTableBody />
            </table>
          </>
      );
    }

    const UnpickingItemsTableHeader = () => {
      return (
          <thead className="thead-light">
              <tr>
                  <th>Route</th>
                  <th>Order Number</th>
                  <th>Variety</th>
                  <th>Item ID</th>
                  <th>Image</th>
                  <th>Item Desc</th>
                  <th>Unpicked qty</th>
                  <th>Order Qty</th>
                  <th>Modified Qty</th>
                  <th>Order Amt</th>
              </tr>
          </thead>
      );
    }

    const UnpickingItemsTableBody = () => {
      let rows = unpickingList.map((row, index) => {
          let freeproductlist = row['freeproductlist'] || [];
          let bgColor = "";
          let revisedOrderAmount = Number(row['revised_order_amt']) || 0;
          let orderDisc = Number(row['order_disc']) || 0;
          let revisedOrderDisc = Number(row['revised_order_disc']) || 0;
          let finalOrderValue = revisedOrderAmount + orderDisc - revisedOrderDisc;
          if(finalOrderValue<1000) {
            bgColor = "coral";
          }

              return (
                  <tr key={index}>
                      <td>{row['route']}</td>
                      <td>{row['order_number']}</td>
                      <td>{row['variety']}</td>
                      <td>{row['item_id']}</td>
                      {row['image_url']?(<td><a href={row['image_url']} target="_blank"><img
                                                    src={row['image_url']}
                                                    alt="Product Image"
                                                    height={70}
                                                    width={70}
                                                  /></a></td>):(<td></td>)}
                      <td>{row['item_disc']}</td>
                      <td>{row['item_deleted_qty']}</td>
                      <td>{row['order_qty']}</td>
                      <td>{row['item_modified_qty']}</td>
                      <td>{finalOrderValue}</td>
                  </tr>
              );
          });

      return (<><tbody>{rows}</tbody></>);
    }

    return (
      <>
      <NavBar/>
        <Navbar className="bg-light m-auto justify-content-center" style={{ paddingTop: 100,textAlign: "center"}}>
          <h3 style={{ marginLeft: "auto",marginRight: "auto" }}>Picking</h3>
        </Navbar>

          <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
            <div className="col-md-3">
              <InputGroup size="sm" style={{ marginLeft:15 }}>
                <InputGroup.Prepend>
                  <InputGroup.Text>Date</InputGroup.Text>
                </InputGroup.Prepend>
                <DropdownButton id="dropdown-basic-button" title={dateType} size="sm" variant="secondary" >
                  <Dropdown.Item key="Picking" name="Picking" onClick={(e)=>{e.preventDefault();setDateType("Picking") }}>Picking</Dropdown.Item>
                  <Dropdown.Item key="Delivery" name="Delivery" onClick={(e)=>{e.preventDefault();setDateType("Delivery") }}>Delivery</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
            <div className="col-md-3">
              <InputGroup style={{ marginLeft:15, width:300 }}>
                <InputGroup.Prepend >
                  <InputGroup.Text>{dateType} Date</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type="date" value={dateType=="Picking"?pickingDate:deliveryDate} onChange={(e) => { updateDate(e.target.value, dateType); }} />
              </InputGroup>
            </div>
            <div className="col-md-3">
              <InputGroup size="sm" style={{ marginLeft:15 }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Type of Summary </InputGroup.Text>
                </InputGroup.Prepend>
                <DropdownButton id="dropdown-basic-button" title={(summaryType!="SELECT")?summaryType:"SELECT"} size="sm" variant="secondary" >
                  <Dropdown.Item key="Picking" name="Picking" onClick={(e)=>{e.preventDefault();setSummaryType(e.target.name); }}>Picking</Dropdown.Item>
                  <Dropdown.Item key="Loading" name="Loading" onClick={(e)=>{e.preventDefault();setSummaryType(e.target.name); }}>Loading</Dropdown.Item>
                  <Dropdown.Item key="Shipped Modification" name="Shipped Modification" onClick={(e)=>{e.preventDefault();setSummaryType(e.target.name); }}>Shipped Modification</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
            <div className="col-md-3">
              {(formatDate(new Date(deliveryDate))>formatDate(new Date())) && <InputGroup size="sm" style={{ marginLeft: 15}} >
                <Button onClick={fetchDohData}>Picking Tasks</Button>
              </InputGroup>}
            </div>
            <div className="col-md-3">
              {summaryType=="Picking" && <InputGroup size="sm" style={{ marginLeft: 15}} >
                <Button onClick={exportPickingData}>Export</Button>
              </InputGroup>}
              {summaryType=="Loading" && <InputGroup size="sm" style={{ marginLeft: 15}} >
                <Button onClick={exportLoadingData}>Export</Button>
              </InputGroup>}
              {summaryType=="Shipped Modification" && <InputGroup size="sm" style={{ marginLeft: 15}} >
                <Button onClick={exportShippedModificationData}>Export</Button>
              </InputGroup>}
            </div>
          </div>

          {(unpickingList.length>0 && summaryType=="Shipped Modification") ? <UnpickingItemsRenderTable /> : null}

          {(summaryType=="Picking" || summaryType=="Loading" || summaryType=="Shipped Modification")?
           (<div className="row" style={{ paddingTop:20, marginLeft: 25 }}>
              {(summaryType=="Picking") && <h4>{pickingStatus}</h4>}
              {(summaryType=="Loading") && <h4>{loadingStatus}</h4>}
              {(summaryType=="Shipped Modification") && <h4>{shipModifyStatus}</h4>}
            </div>):null}

            {allErrorStatus.length>0?<Button onClick={() => { setAllErrorsFlag(true); }}>All Errors</Button>:null}

          <div className="row" style={{ paddingTop:20 }}>
            <div className="col-md-2">
              {(((Object.keys(pickingData).length>0 && summaryType=="Picking") || (Object.keys(loadingData).length>0 && summaryType=="Loading")) && warehouseList.length>0) ? <RenderWarehouseDropdown /> : null}
            </div>
            <div className="col-md-2">
              {(((Object.keys(pickingData).length>0 && summaryType=="Picking") || (Object.keys(loadingData).length>0 && summaryType=="Loading")) && routeList.length>0) ? <RenderRouteDropdown /> : null}
            </div>
            <div className="col-md-2">
              {(Object.keys(pickingData).length>0 && summaryType=="Picking" && varietyList.length>0) ? <RenderVarietyDropdown /> : null}
            </div>
            <div className="col-md-2">
              {(((Object.keys(pickingData).length>0 && summaryType=="Picking") || (Object.keys(loadingData).length>0 && summaryType=="Loading")) && statusList.length>0) ? <RenderStatusDropdown /> : null}
            </div>
            <div className="col-md-2">
              {((Object.keys(pickingData).length>0 && summaryType=="Picking") || (Object.keys(loadingData).length>0 && summaryType=="Loading")) ? <Button style={{ marginLeft:10, display:"block" }}  variant="warning" onClick={()=>{ resetFilters(); }}>Reset Filters</Button> : null}
            </div>
            <div className="col-md-2">
              {((Object.keys(pickingData).length>0 && summaryType=="Picking") || (Object.keys(loadingData).length>0 && summaryType=="Loading")) ? <RenderDiscrepancySwitch /> : null}
            </div>
          </div>

          {((Object.keys(pickingData).length>0 && summaryType=="Picking") || (Object.keys(loadingData).length>0 && summaryType=="Loading")
           || (Object.keys(shipModifyOrdersData).length>0 && summaryType=="Shipped Modification"))?<ReactToPrint
              trigger={() => <a href="#" style={{ 'float': 'right' }} ><FontAwesomeIcon size="3x" icon={faPrint} /></a>}
              content={() => { return dataToPrint.current }}
              pageStyle={{margin:"50px",border:"2px"}}
          />:null}

          {(Object.keys(pickingData).length>0 && summaryType=="Picking") ? <PickingItemsTable /> : null}
          {(Object.keys(loadingData).length>0 && summaryType=="Loading") ? <LoadingItemsTable /> : null}
          {(Object.keys(shipModifyOrdersData).length>0 && summaryType=="Shipped Modification") ? <ShipModifyOrdersTable /> : null}

          {pickingFlag==true?<PickingModal
              show={pickingFlag}
              onHide={() => setPickingFlag(false)}
              dohData={dohData}
              deliveryDate={deliveryDate}
              pickingDate={pickingDate}
          />:null}

          {allErrorsFlag==true?<AllErrorsModal
              show={allErrorsFlag}
              onHide={() => setAllErrorsFlag(false)}
              allErrorStatus={allErrorStatus}
          />:null}

          {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
      </>
    )

}

export default Picking
