import React, { useState, useEffect, useRef } from 'react'
import { Table, Badge,Navbar,Form,Button,Dropdown,DropdownButton,InputGroup,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import szlogo from '../../assests/images/sz_logo.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import AddItemModal from '../OrdersListing/AddNewItemModal';
import { IconButton } from '@material-ui/core';
import NavBar from '../Navigation bar/Navbar'
import Select from 'react-select';
import { toast } from 'react-toastify';
import { validateIsAdminUser,validateIsOperationUser } from '../UserCredentials'
import ModalDuplicateReturnOrders from './duplicateordermodal'
import fire from '../../Configs/firebase';
import { support_portal_url, orderingApiUrl } from '../../Configs/mysqlconfig';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour=''+d.getHours(),
        minute=''+d.getMinutes(),
        second=''+d.getSeconds();

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }
    if (hour.length < 2){
      hour = '0' + hour;
    }
    if (minute.length < 2){
      minute = '0' + minute;
    }
    if (second.length < 2){
      second = '0' + second;
    }

    let currdate=[day, month, year].join('/');
    let time = hour + ":" + minute + ":" + second;
    let curdateTime = currdate+' '+time;

    return curdateTime;
}

function formatOrderDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }

    let currdate=[year,month,day].join('-');

    return currdate;
}

Date.prototype.toShortFormat = function() {

    let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];

    let day = this.getDate();

    let monthIndex = this.getMonth();
    let monthName = monthNames[monthIndex];

    let year = ""+this.getFullYear();
    let lasttwoYearDigit = year.substr(year.length - 2);

    return `${day} ${monthName} ${lasttwoYearDigit}`;
}


const AddReturnOrAdjustmentOrder = () => {

  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];
  // console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  // console.log(allowedRoutesSet);

    const [backDrop,setBackDrop] = useState(false);
    const [orderData,setOrderData] = useState([]);
    const [backupOrderData,setBackupOrderData] = useState([]);
    const [searchOrderData,setSearchOrderData] = useState([]);
    const [itemChecked,setItemChecked] = useState({});
    const [ordrStatus,setOrdrStatus]=useState("");

    const [initialOrderSelection,setInitialOrderSelection]=useState(true);

    //radio button state
    const [orderOption,setOrderOption]=useState("AddOrder");
    const [orderSelectedItemSet,setOrderSelectedItemSet]=useState({});
    const [addedOrderChangedStatus,setAddedOrderChangedStatus]=useState("");
    const [reasonDropdownDisable,setReasonDropdownDisable]=useState(false);

    //add order state
    const [orderNumber,setOrderNumber] = useState("");
    const [returnOrderType,setReturnOrderType] = useState("SELECT");
    const [returnReason,setReturnReason]=useState("SELECT");
    const [customerReturnedItems,setCustomerReturnedItems]=useState("SELECT");
    const [returnItem,setReturnItem]=useState("SELECT");
    const [customerDeductedMoney,setCustomerDeductedMoney]=useState("SELECT");
    const [returnedVehicleId,setReturnedVehicleId]=useState("SELECT");

    //schedule for delivery state
    let vehhroute=sessionStorage.getItem("routeIdToPrint")?sessionStorage.getItem("routeIdToPrint"):"SELECT";
    const [routeVehicleId,setRouteVehicleId] = useState(vehhroute);
    const [vehiclePriority,setVehiclePriority] = useState();
    let currdate=formatOrderDate(new Date());
    const [deliveryDate,setDeliveryDate] = useState(currdate);
    const [returnDate,setReturnDate]=useState(currdate);
    const [deliveryOrderNumber,setDeliveryOrderNumber] = useState("");
    const [deliveryOrderData,setDeliveryOrderData] = useState([]);
    const [backupDeliveryOrderData,setBackupDeliveryOrderData] = useState([]);

    //item input focus state
    const [itemFocusObj,setItemFocusObj] = useState({});

    //new states
    const [orderType,setOrderType] = useState("SELECT");
    const [actualOrderType,setActualOrderType] = useState("SELECT");
    const [validRouteList,setValidRouteList] = useState([]);
    const [customerAdjustedPayment,setCustomerAdjustedPayment]=useState("SELECT");
    const [isOrderAlreadyPickedUp,setIsOrderAlreadyPickedUp]=useState("SELECT");
    const [isOrderPickedDropdownDisable,setIsOrderPickedDropdownDisable]=useState(false);

    //duplicate order checks
    const [showDuplicateOrderNumberModal,setShowDuplicateOrderNumberModal]=useState(false);
    const [duplicateOrderNumber,setDuplicateOrderNumber]=useState("");

    const checkRef = useRef();

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(()=>{
      //valid routes list
      let currdate=new Date();
      let day=currdate.getDate();
      let month=currdate.getMonth()+1;
      let year=currdate.getFullYear();
      let date=day+"-"+month+'-'+year;

      let validRoute=[];

      fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/`).once('value',snapshot=>{
        if(snapshot.hasChildren()){
          let vehData = snapshot.val();
          Object.keys(vehData).map((key,value)=>{
            if(key!="9999"){
              validRoute.push(key);
            }
          });
          setValidRouteList(validRoute);
        }else{
          setValidRouteList([]);
        }
      });
    },[])

    useEffect(() => {
      if(routeVehicleId && deliveryDate){
        let currdate=new Date(deliveryDate);
        let day=currdate.getDate();
        let month=currdate.getMonth()+1;
        let year=currdate.getFullYear();
        let date=day+"-"+month+'-'+year;
        let maxVehiclePriority=-1;

        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/${routeVehicleId}/`).once('value',snapshot=>{
          if(snapshot.hasChildren()){
            let vehData = snapshot.val();
            Object.keys(vehData).map((key,value)=>{
              let priority=Number(vehData[key]['priority']);
              if(priority>maxVehiclePriority){
                maxVehiclePriority=priority;
              }
            });
            let driverOrdersPriority=maxVehiclePriority+1;
            setVehiclePriority(driverOrdersPriority);
          }else{
            maxVehiclePriority=1;
            setVehiclePriority(maxVehiclePriority);
          }
        });

        let validRoute=[];

        fire.database().ref(`Driver_Orders_History/${year}/${month}/${date}/`).once('value',snapshot=>{
          if(snapshot.hasChildren()){
            let vehData = snapshot.val();
            Object.keys(vehData).map((key,value)=>{
              if(key!="9999"){
                validRoute.push(key);
              }
            });
            setValidRouteList(validRoute);
          }else{
            setValidRouteList([]);
          }
        });
      }
    },[deliveryDate,routeVehicleId])

    useEffect(()=>{
      if(returnItem!="SELECT"){
        if(returnItem=="Yes"){
          setCustomerDeductedMoney("SELECT");
          if(ordrStatus=="Delivered"){
            setReturnOrderType("Yes");
            setReasonDropdownDisable(true);
          }
        }else if(returnItem=="No"){
          setReturnOrderType("SELECT");
        }
      }
    },[returnItem])

    const viewRetailerOrders = () => {
        console.log('viewRetailerOrders');
        console.log(orderNumber);
        setBackDrop(true);
        setOrderData([]);
        setBackupOrderData([]);
        setSearchOrderData([]);
        let retailerOrdersArray=[];

        if(orderNumber.includes('-')==true && !orderNumber.includes("-S")){
          setItemChecked({});
          setOrderData([]);
          setBackupOrderData([]);
          setSearchOrderData([]);
          setBackDrop(false);
          toast("Order can't be placed for this OrderNumber. Contact Admin.", { type: toast.TYPE.ERROR });
        }else if(orderNumber.includes('/')==true){
          fetch(`${support_portal_url}/api/ordernumbersearch?ordernumber=${orderNumber}&token=${sessionStorage.getItem("apiToken")}`).then(res => {
              return res.json()
          }).then(data => {
            let orderdatalist=data.data;
            console.log(orderdatalist);
            if(data['success']==1){
              let orderstatus=orderdatalist["status"];
              let orderitemslist=orderdatalist["items"];

              console.log(orderitemslist);
              if(orderstatus.includes("Delivered") || orderstatus.includes("Processed") || orderstatus.includes("Shipped")){
                for(let i=0;i<orderitemslist.length;i++){
                  let order_qty=orderitemslist[i]['order_qty'];
                  let item_delivered_qty=orderitemslist[i]['item_delivered_qty'];
                  order_qty=Number(order_qty);
                  item_delivered_qty=Number(item_delivered_qty);
                  let ele=orderitemslist[i];
                  ele['original_order_qty']=orderitemslist[i]['order_qty'];
                  ele['original_total_amt']=orderitemslist[i]['total_amt'];
                  ele['original_gst_charge']=orderitemslist[i]['gst_charge'];
                  ele['original_apmc_charge']=orderitemslist[i]['apmc_charge'];
                  ele['original_transport_charge']=orderitemslist[i]['transport_charge'];
                  ele['offer_price']=orderitemslist[i]['offer_price'];

                  ele['address1']=orderdatalist['address1'];
                  ele['address2']=orderdatalist['address2'];
                  ele['agent_id']=orderdatalist['agent_id'];
                  ele['area']=orderdatalist['area'];
                  ele['card_amt']=orderdatalist['card_amt'];
                  ele['cash_amt']=orderdatalist['cash_amt'];
                  ele['cheque_date']=orderdatalist['cheque_date'];
                  ele['distributor_id']=orderdatalist['distributor_id'];
                  ele['ean']=orderdatalist['ean'];
                  ele['exp_delivery_date']=orderdatalist['exp_delivery_date'];
                  ele['item_status']=orderdatalist['item_status'];
                  ele['market_price']=orderdatalist['market_price'];
                  ele['mrp']=orderdatalist['mrp'];
                  ele['mtd_exclude']=orderdatalist['mtd_exclude'];

                  ele['offer_disc']=orderdatalist['offer_disc'];
                  ele['paid_date']=orderdatalist['paid_date'];
                  // ele['price']=orderdatalist['price'];
                  ele['scheme_amt']=orderdatalist['scheme_amt'];
                  ele['shipping_charges']=orderdatalist['shipping_charges'];
                  ele['update_status']=orderdatalist['update_status'];
                  ele['cashback_redeemed']=orderdatalist['cashback_redeemed'];
                  ele['city']=orderdatalist['city'];
                  ele['credit_amt']=orderdatalist['credit_amt'];
                  ele['final_order_amt']=orderdatalist['final_order_amt'];
                  ele['fssai_document_number']=orderdatalist['fssai_document_number'];
                  ele['address1']=orderdatalist['address1'];
                  ele['gst_number']=orderdatalist['gst_number'];
                  ele['net_order_amt']=orderdatalist['net_order_amt'];
                  ele['order_amt']=orderdatalist['order_amt'];
                  ele['order_date']=formatOrderDate(orderdatalist['order_date']);
                  ele['order_disc']=orderdatalist['order_disc'];
                  ele['order_number']=orderdatalist['order_number'];
                  ele['order_payment_mode']=orderdatalist['order_payment_mode'];
                  ele['paytm_amt']=orderdatalist['paytm_amt'];
                  ele['phone']=orderdatalist['phone'];
                  ele['pincode']=orderdatalist['pincode'];
                  ele['retailer_id']=orderdatalist['retailer_id'];
                  ele['shop_name']=orderdatalist['shop_name'];
                  ele['status']=orderdatalist['status'];
                  ele['coupon_code_order_level']=orderdatalist['coupon_code_order_level'];
                  retailerOrdersArray.push(ele);
                }
                console.log(retailerOrdersArray);
                setItemChecked({});
                setBackupOrderData(retailerOrdersArray);
                setSearchOrderData(retailerOrdersArray);
                setOrderData(retailerOrdersArray);
                setOrdrStatus(orderstatus);
                setBackDrop(false);
                if(orderstatus=="Delivered"){
                  setReturnOrderType("Yes");
                  setReasonDropdownDisable(true);
                }else{
                  setReturnOrderType("SELECT");
                  setReasonDropdownDisable(false);
                }
              }else{
                setItemChecked({});
                setOrderData([]);
                setBackupOrderData([]);
                setSearchOrderData([]);
                setBackDrop(false);
                toast(`Return Order can be only for Delivered Orders. ${orderNumber} - Status: ${orderstatus}.`, { type: toast.TYPE.ERROR });
              }

            }else{
              setItemChecked({});
              setOrderData([]);
              setBackupOrderData([]);
              setSearchOrderData([]);
              setBackDrop(false);
              toast('Order number not found', { type: toast.TYPE.ERROR });

              let errMessage=data['message']?data['message']:"";
              alert(errMessage)
              if(data['status']==401 || data['status']==403){
                sessionStorage.clear();
                window.location.href="/"
              }
            }
          }).catch(err => {
            setItemChecked({});
            setOrderData([]);
            setBackupOrderData([]);
            setSearchOrderData([]);
            setBackDrop(false);
            toast(err, { type: toast.TYPE.ERROR });
          })
        }else{
          setItemChecked({});
          setOrderData([]);
          setBackupOrderData([]);
          setSearchOrderData([]);
          setBackDrop(false);
          toast('Please enter valid order number', { type: toast.TYPE.ERROR });
        }
    }

    const viewRetailerOrdersForDelivery = () => {
        console.log('viewRetailerOrdersForDelivery');
        console.log(deliveryOrderNumber);
        setBackDrop(true);
        let retailerOrdersArray=[];
        if(deliveryOrderNumber.includes('/')){
          let retailerid="";
          if(deliveryOrderNumber.length==13){
            retailerid=deliveryOrderNumber.split('/')[0];
          }else if(deliveryOrderNumber.length>=15){
            if(deliveryOrderNumber.includes("A") || deliveryOrderNumber.includes("B") || deliveryOrderNumber.includes("-C") || deliveryOrderNumber.includes("-S") || deliveryOrderNumber.includes("R")){
              retailerid=deliveryOrderNumber.split('-')[0];
            }else if(deliveryOrderNumber.includes("SC")){
              let retstr=deliveryOrderNumber.split('/')[0];
              retailerid = retstr.substr(retstr.length - 5);
            }
          }
          console.log(retailerid);

          let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
          console.log(apiUrl);
          fetch(apiUrl)
          .then((response) => {
              return response.json();
          }).then((result) => {
              let retailerData=result['data'];
              console.log(retailerData);

              let firebaseOrderNumberList=deliveryOrderNumber.split('/');

              let firebaseOrderNumber=firebaseOrderNumberList[0]+'-'+firebaseOrderNumberList[1];
              let ordersNewRef = fire.database().ref('Orders_New/').orderByKey().startAt(`${firebaseOrderNumber}-`).endAt(`${firebaseOrderNumber}-\uf8ff`);
              ordersNewRef.once('value',snapshot=>{
                  if(snapshot.hasChildren()){
                      let ordersdata = snapshot.val();
                      console.log(ordersdata);
                      let searchOrdNum=deliveryOrderNumber;

                      let searchOrdNumList=searchOrdNum.split("/");
                      let newOrdNum=searchOrdNumList[0]+"-"+searchOrdNumList[1];
                      console.log(newOrdNum);
                      let finalOrdersList=[];

                      Object.keys(ordersdata).map((key,index)=>{
                        if(key.includes(newOrdNum)){
                          let order_qty=Number(ordersdata[key]['order_qty']);
                          if(order_qty>0){
                            let ele=ordersdata[key];
                            if(deliveryOrderNumber.includes('-R')){
                              ele['net_order_amt']="0"
                              ele['cash_amt']="0"
                              ele['net_amt']="0"
                              ele['order_amt']="0"
                              ele['final_order_amt']="0"
                              ele['cashback_redeemed']="0";
                              ele['order_disc']="0";
                              ele['total_amt']="0";
                              ele['gst_charge']="0";
                              ele['transport_charge']="0";
                            }

                            if(retailerData){
                              ele['address1']=retailerData['address1']?retailerData['address1']:"";
                              ele['address2']=retailerData['address2']?retailerData['address2']:"";
                              ele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                              ele['city']=retailerData['city']?retailerData['city']:"";
                              ele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                              ele['phone']=retailerData['phone']?retailerData['phone']:"";
                              ele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                              ele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                              ele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                              ele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                              ele['asm']=retailerData['asm']?retailerData['asm']:"";
                              ele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                              ele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                              ele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                            }

                            finalOrdersList.push(ele);
                          }
                        }
                      });
                      console.log(finalOrdersList);
                      setDeliveryOrderData(finalOrdersList);
                      let currdate=formatOrderDate(new Date());
                      console.log(currdate);
                      setDeliveryDate(currdate);
                      setBackDrop(false);

                      if(finalOrdersList.length==0){
                        toast("Order number not present", { type: toast.TYPE.ERROR });
                      }
                  }else{
                    setDeliveryOrderData([]);
                    setBackDrop(false);
                  }
              });
          }).catch((error) => {
            toast(error, { type: toast.TYPE.ERROR });
            setDeliveryOrderData([]);
            setBackDrop(false);
          });
        }else{
          setDeliveryOrderData([]);
          setBackDrop(false);
          toast(`Invalid Order number ${deliveryOrderNumber}`, { type: toast.TYPE.ERROR });
        }
    }

    const DeliveryRetailerOrdersTableHeader = () => {
      return (
          <thead className="thead-light">
              <tr>
                  <th>Item Description</th>
                  <th>Item ID</th>
                  <th>Order Qty</th>
                  <th>Total Amount</th>
              </tr>
          </thead>
      );
    }

    const DeliveryRetailerOrdersTableBody = () => {
      let rows=[];

      if(deliveryOrderData.length>0){
        rows = deliveryOrderData.map((ord, index) => {
          return (
              <tr key={index}>
                  <td>{ord['item_disc']}</td>
                  <td>{ord['item_id']}</td>
                  <td>{ord['order_qty']}</td>
                  <td>{ord['total_amt']}</td>
              </tr>
          );
        });
      }else{
        rows=[];
      }

      return (<><tbody>{rows}</tbody></>);
    }

    const DeliveryRetailerOrdersSummaryTable = () => {
      let finalNetOrderAmount=0;
      let finalOrderAmount=0;
      let finalGstAmount=0;
      let finalTransportAmount=0;

      for(let k=0;k<deliveryOrderData.length;k++){
        let item_id=""+deliveryOrderData[k]['item_id'];
        let order_qty=Number(deliveryOrderData[k]['order_qty']);
        let total_amt=Number(deliveryOrderData[k]['total_amt']);
        let gst_charge=Number(deliveryOrderData[k]['gst_charge']);
        let transport_charge=Number(deliveryOrderData[k]['transport_charge']);
        finalNetOrderAmount=finalNetOrderAmount+total_amt;
        finalGstAmount=finalGstAmount+gst_charge;
        finalTransportAmount=finalTransportAmount+transport_charge;
      }
      finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

      return (
        <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
          <table className="table table-striped" style={{width:100,marginLeft:"auto",marginRight:"auto"}}>
            <tbody>
                <tr>
                    <td>OrderValue</td>
                    <td>{finalOrderAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>GST</td>
                    <td>{finalGstAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Transport</td>
                    <td>{finalTransportAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>NetOrderValue</td>
                    <td>{finalNetOrderAmount.toFixed(2)}</td>
                </tr>
            </tbody>
        </table>
      </div>
      );
    }


    const RetailerOrdersSummaryTable = () => {
      let finalNetOrderAmount=0;
      let finalOrderAmount=0;
      let finalGstAmount=0;
      let finalTransportAmount=0;

      if(customerAdjustedPayment!="Yes" && actualOrderType!="R"){
        let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');

        if(bulkItemChecks.length==0){
          for(let k=0;k<orderData.length;k++){
            let item_id=""+orderData[k]['item_id'];
            let order_qty=Number(orderData[k]['order_qty']);
            let total_amt=Number(orderData[k]['total_amt']);
            let gst_charge=Number(orderData[k]['gst_charge']);
            let transport_charge=Number(orderData[k]['transport_charge']);
            finalNetOrderAmount=finalNetOrderAmount+total_amt;
            finalGstAmount=finalGstAmount+gst_charge;
            finalTransportAmount=finalTransportAmount+transport_charge;
          }
          finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

        }else{
          let selectedItemSet=new Set();
          for (let i=0;i<bulkItemChecks.length;i++) {
            let itemId=bulkItemChecks[i].name;
            let isItemChecked=bulkItemChecks[i].checked;
            if(isItemChecked==true){
              selectedItemSet.add(itemId);
            }
          }
          for(let k=0;k<orderData.length;k++){
            let item_id=""+orderData[k]['item_id'];
            let order_qty=Number(orderData[k]['order_qty']);
            if(selectedItemSet.has(item_id) && order_qty>0){
              let total_amt=Number(orderData[k]['total_amt']);
              let gst_charge=Number(orderData[k]['gst_charge']);
              let transport_charge=Number(orderData[k]['transport_charge']);
              finalNetOrderAmount=finalNetOrderAmount+total_amt;
              finalGstAmount=finalGstAmount+gst_charge;
              finalTransportAmount=finalTransportAmount+transport_charge;
            }
          }
          finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);
        }
      }

      return (
        <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
          <table className="table table-striped" style={{width:100,marginLeft:"auto",marginRight:"auto"}}>
            <tbody>
                <tr>
                    <td>OrderValue</td>
                    <td>{finalOrderAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>GST</td>
                    <td>{finalGstAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Transport</td>
                    <td>{finalTransportAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>NetOrderValue</td>
                    <td>{finalNetOrderAmount.toFixed(2)}</td>
                </tr>
            </tbody>
        </table>
      </div>
      );
    }

    const RetailerOrdersTableHeader = () => {
      let isOrderChecked=initialOrderSelection==true?true:(checkRef.current?checkRef.current.checked:true);
      return (
          <thead className="thead-light">
              <tr>
                  <th><input type="checkbox" id="selectAllCheckbox" defaultChecked={isOrderChecked} ref={checkRef} onChange={() => {
                      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
                      let itemObj={};
                      for (let i = 0; i < bulkItemChecks.length; i++) {
                        let itemId=bulkItemChecks[i].name;
                        if(checkRef.current.checked){
                          itemObj[`${itemId}`]=true;
                          bulkItemChecks[i].checked = true;
                        }else{
                          itemObj[`${itemId}`]=false;
                          bulkItemChecks[i].checked = false;
                        }
                      }
                      console.log(itemObj);
                      setInitialOrderSelection(false);
                      setItemChecked(itemObj);
                  }} /></th>
                  <th>Item Description</th>
                  <th>Item ID</th>
                  <th>Qty</th>
                  <th>Total Amount</th>
              </tr>
          </thead>
      );
    }

    const updateItemBasedOnQuantity = (rowNum,updatedQuantity,originalQuantity,itemID) => {
      updatedQuantity=Number(updatedQuantity);
      console.log(originalQuantity);
      console.log(updatedQuantity);
      console.log(backupOrderData[rowNum]['total_amt']);
      if(originalQuantity>0){
        let updatedOrderDataList = [...orderData];
        updatedOrderDataList[rowNum]['order_qty'] = ""+updatedQuantity;
        updatedOrderDataList[rowNum]['total_amt'] = ""+Math.round((updatedOrderDataList[rowNum]['original_total_amt']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        updatedOrderDataList[rowNum]['apmc_charge'] = ""+Math.round((updatedOrderDataList[rowNum]['original_apmc_charge']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        updatedOrderDataList[rowNum]['gst_charge'] = ""+Math.round((updatedOrderDataList[rowNum]['original_gst_charge']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        updatedOrderDataList[rowNum]['transport_charge'] = ""+Math.round((updatedOrderDataList[rowNum]['original_transport_charge']*(updatedQuantity/originalQuantity) + Number.EPSILON) * 100) / 100;
        setOrderData(updatedOrderDataList);
        setTimeout(() => {
          let bulkitemqty=document.getElementsByClassName("itemQty");
          for(let i=0;i<bulkitemqty.length;i++){
            let item_id=Number(bulkitemqty[i].name);
            if(itemID==item_id){
              bulkitemqty[i].focus();
            }
          }
         }, 0)
      }
    }

    const filterOrderData = (flag) => {
      if(searchOrderData.length>0){
        let orderstatus=searchOrderData[0]['status'];
        if(orderstatus=="Partially-Delivered"){
          let finalOrdersData=[];
          for(let i=0;i<searchOrderData.length;i++){
            let order_qty=Number(searchOrderData[i]['order_qty']);
            let item_delivered_qty=Number(searchOrderData[i]['item_delivered_qty']);
            if(item_delivered_qty>0){
              let ele=searchOrderData[i];
              finalOrdersData.push(ele);
            }
          }
          setOrderData(finalOrdersData);
          setBackupOrderData(finalOrdersData);
        } else {
          setOrderData(searchOrderData);
          setBackupOrderData(searchOrderData);
        }
      } else {
        setOrderData(searchOrderData);
        setBackupOrderData(searchOrderData);
      }
    }

    const RetailerOrdersTableBody = () => {
      let rows=[];

      if(orderData.length>0){
        rows = orderData.map((ord, index) => {
          let itemID=ord.item_id;
          let order_qty=Number(ord.order_qty);
          let item_delivered_qty=Number(ord.item_delivered_qty);
          let item_qty=order_qty-item_delivered_qty;
          let isItemChecked=initialOrderSelection==true?true:(itemChecked[itemID]!==undefined?itemChecked[itemID]:true);
          let originalQuantity=Number(ord.original_order_qty);

          return (
              <tr key={index}>
                  <td><input type="checkbox" name={ord.item_id} className="bulkItemSelection" defaultChecked={isItemChecked} onClick={(e) => {
                    setInitialOrderSelection(false);
                    setItemChecked({...itemChecked,[ord.item_id]: e.target.checked});
                  }} /></td>
                  <td>{ord['item_disc']}</td>
                  <td>{ord['item_id']}</td>
                  <td>
                    <Form.Control className="itemQty" name={ord.item_id} type="number" min="1" max={originalQuantity} value={order_qty} onChange={(e) => { updateItemBasedOnQuantity(index,e.target.value,originalQuantity,ord.item_id);setItemFocusObj({[ord.item_id]: true}); }} />
                  </td>
                  <td>{((actualOrderType=="OR" && customerAdjustedPayment=='Yes') || actualOrderType=="R")?0:ord['total_amt']}</td>
              </tr>
          );
        });
      }else{
        rows=[];
      }

      return (<><tbody>{rows}</tbody></>);
    }

    const getDeliveryOrdernumber = (ordernumber) => {
      if(ordernumber && ordernumber.length>0){
        let ordernumberlist=ordernumber.split("/");

        if(actualOrderType=="R"){
          let ordnum=ordernumberlist[0]+"-R/"+ordernumberlist[1];
          setDeliveryOrderNumber(ordnum);
        }else if(actualOrderType=="S"){
          let ordnum=ordernumberlist[0]+"-S/"+ordernumberlist[1]
          setDeliveryOrderNumber(ordnum);
        }else{
          setDeliveryOrderNumber("");
        }
      }else{
        setDeliveryOrderNumber("");
      }
    }



    const placeDeliveryOrder = () => {
      console.log('placeDeliveryOrder');
      console.log(deliveryOrderNumber);
      console.log(deliveryOrderData);

      if(routeVehicleId=="" || typeof(routeVehicleId)=="undefined" || routeVehicleId==undefined || routeVehicleId=='undefined' || routeVehicleId=="SELECT"){
        toast("Please select route", { type: toast.TYPE.ERROR });
      }else if(vehiclePriority=="" || typeof(vehiclePriority)=="undefined"){
        toast("Please enter order priority", { type: toast.TYPE.ERROR });
      }else if(deliveryOrderNumber.length==0){
        toast("Order Number is empty", { type: toast.TYPE.ERROR });
      }else if(deliveryOrderData.length>0){
          let orderstatus=deliveryOrderData[0]['status'];
          let delivery_order_picked_up=deliveryOrderData[0]['order_picked_up']?deliveryOrderData[0]['order_picked_up']:"";
          if(delivery_order_picked_up=="Yes" && deliveryOrderNumber.includes("-OR")){
            toast("Order already picked up", { type: toast.TYPE.ERROR });
          }else if(orderstatus=="Partially-Delivered" || orderstatus=="Delivered"){
            toast("Order status should not be Partially-Delivered or Delivered", { type: toast.TYPE.ERROR });
          }else{
            setBackDrop(true);
            //amount calculations
            let finalNetOrderAmount=0;
            let finalOrderAmount=0;
            let finalGstAmount=0;
            let finalTransportAmount=0;
            for(let k=0;k<deliveryOrderData.length;k++){
              let item_id=""+deliveryOrderData[k]['item_id'];
              let total_amt=Number(deliveryOrderData[k]['total_amt']);
              let gst_charge=Number(deliveryOrderData[k]['gst_charge']);
              let transport_charge=Number(deliveryOrderData[k]['transport_charge']);
              finalNetOrderAmount=finalNetOrderAmount+total_amt;
              finalGstAmount=finalGstAmount+gst_charge;
              finalTransportAmount=finalTransportAmount+transport_charge;

            }
            finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);
            finalOrderAmount=finalOrderAmount.toFixed(2);
            finalNetOrderAmount=finalNetOrderAmount.toFixed(2);
            let currTimestamp=Date.parse(new Date());

            let driverOrdersList=JSON.parse(JSON.stringify(deliveryOrderData));
            let driverordershistoryele={};

            let year,month,date;

            if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
              let currdate=new Date(deliveryDate);
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;
            }else{
              let currdate=new Date();
              let day=currdate.getDate();
              month=currdate.getMonth()+1;
              year=currdate.getFullYear();
              date=day+"-"+month+'-'+year;
            }


            //get retailer data
            let retailerid="";
            if(deliveryOrderNumber.length==13){
              retailerid=deliveryOrderNumber.split('/')[0];
            }else if(deliveryOrderNumber.length>=15){
              if(deliveryOrderNumber.includes("A") || deliveryOrderNumber.includes("B") || deliveryOrderNumber.includes("-C") || deliveryOrderNumber.includes("-S") || deliveryOrderNumber.includes("R")){
                retailerid=deliveryOrderNumber.split('-')[0];
              }else if(deliveryOrderNumber.includes("SC")){
                let retstr=deliveryOrderNumber.split('/')[0];
                retailerid = retstr.substr(retstr.length - 5);
              }
            }
            let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
            console.log(apiUrl);
            fetch(apiUrl)
            .then((response) => {
                return (response['status']==200)?response.json():[];
            }).then((result) => {
                let retailerData=result['data'];

                for(let z=0;z<driverOrdersList.length;z++){
                  let item_id=""+driverOrdersList[z]['item_id'];
                  let mrptotalamt=driverOrdersList[z]['total_amt'];
                  let doele=driverOrdersList[z];
                  doele['priority']=vehiclePriority;

                  doele['order_number']=deliveryOrderNumber;

                  doele['ordered_from']='web';
                  doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  doele['status']=orderstatus;
                  doele['apmc_charge']=""+driverOrdersList[z]['apmc_charge'];
                  doele['order_qty']=""+driverOrdersList[z]['order_qty'];

                  let order_picked_up=driverOrdersList[z]['order_picked_up']?driverOrdersList[z]['order_picked_up']:"";
                  if(order_picked_up=="No" && deliveryOrderNumber.includes("-OR")){
                    doele['status']="Returned";
                  }


                  if(deliveryOrderNumber.includes("-R")){
                    doele['net_order_amt']="0"
                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;
                    doele['cash_amt']="0"
                    doele['net_amt']="0"
                    doele['order_amt']="0"
                    doele['final_order_amt']="0"
                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";
                    doele['total_amt']="0";
                    doele['gst_charge']="0";
                    doele['transport_charge']="0";
                    doele['mrp']="0";
                    doele['item_returned_qty']=""+driverOrdersList[z]['item_delivered_qty'];
                    doele['customer_returned_items']=driverOrdersList[z]['customer_returned_items']?driverOrdersList[z]['customer_returned_items']:"";
                  }else if(deliveryOrderNumber.includes("-OR")){
                    doele['net_order_amt']=finalNetOrderAmount>0?("-"+finalNetOrderAmount):(""+finalNetOrderAmount);
                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;
                    doele['cash_amt']=finalNetOrderAmount>0?("-"+finalNetOrderAmount):(""+finalNetOrderAmount);
                    doele['net_amt']=finalNetOrderAmount>0?("-"+finalNetOrderAmount):(""+finalNetOrderAmount);
                    doele['order_amt']=finalOrderAmount>0?("-"+finalOrderAmount):(""+finalOrderAmount);
                    doele['final_order_amt']=finalOrderAmount>0?("-"+finalOrderAmount):(""+finalOrderAmount);
                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";
                    doele['gst_charge']=finalGstAmount>0?("-"+finalGstAmount):(""+finalGstAmount);
                    doele['total_amt']=finalNetOrderAmount>0?("-"+finalNetOrderAmount):""+finalNetOrderAmount;
                    doele['mrp']=""+mrptotalamt;
                    doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];
                  }else{
                    doele['net_order_amt']=""+finalNetOrderAmount;
                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;
                    doele['cash_amt']=""+finalNetOrderAmount;
                    doele['net_amt']=""+finalNetOrderAmount;
                    doele['order_amt']=""+finalOrderAmount;
                    doele['final_order_amt']=""+finalOrderAmount;
                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";
                    doele['gst_charge']=""+finalGstAmount;
                    doele['total_amt']=""+finalNetOrderAmount;
                    doele['mrp']=""+mrptotalamt;
                    doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];
                  }

                  doele['address1']=retailerData['address1']?retailerData['address1']:"";
                  doele['address2']=retailerData['address2']?retailerData['address2']:"";
                  doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                  doele['city']=retailerData['city']?retailerData['city']:"";
                  doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                  doele['phone']=retailerData['phone']?retailerData['phone']:"";
                  doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                  doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                  doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                  doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                  doele['asm']=retailerData['asm']?retailerData['asm']:"";
                  doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                  doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                  doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                  doele['driver_id']=""+routeVehicleId
                  doele['vehicle']=Number(routeVehicleId)
                  doele['order_payment_mode']="Cash on Delivery"
                  doele['credit_amt']="0"
                  doele['card_amt']="0"
                  doele['cashback_redeemed']="0"
                  doele['cheque_amt']="0"



                  delete doele['delivery_date'];
                  delete doele['delivery_date_conv'];
                  delete doele['item_delivered_qty'];
                  delete doele['processed_date'];
                  delete doele['revised_order_amt'];
                  if(routeVehicleId != '9999') {
                    delete doele['item_returned_qty'];
                  }

                  let ordnum=deliveryOrderNumber;
                  //23451-R/3564656
                  let ordnumlist=ordnum.split("/");
                  let retid=ordnumlist[0];
                  let retlist=retid.split("-");
                  let dorefstr=retlist[0]+"_"+retlist[1]+"-"+ordnumlist[1]+"-"+item_id;
                  console.log(dorefstr);
                  let dohkey=`/${year}/${month}/${date}/${routeVehicleId}/${dorefstr}`;

                  driverordershistoryele[`${dohkey}`]=doele;
                }
                console.log('driverordershistoryele');
                console.log(driverordershistoryele);

                //add in firebase
                let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                driverOrdersRef.update(driverordershistoryele,function(error){
                  if (error) {
                    setBackDrop(false);
                    toast('Error in adding data to Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });

                  }else{
                    setDeliveryOrderNumber("");
                    setDeliveryOrderData([]);
                    let currdate=formatOrderDate(new Date());
                    setDeliveryDate(currdate);
                    setBackDrop(false);
                    toast(`Successfully Assigned Order: ${deliveryOrderNumber} to Route: ${routeVehicleId}`, { type: toast.TYPE.SUCCESS });

                  }
                });

            }).catch((reterr)=>{
              setBackDrop(false);
              toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
            })
          }
      }
    }

    const getOrderDiscount = (orderData, offerObj, productObj) => {
      let orderDiscount = 0;

      Object.keys(offerObj).map((key,index) => {
        let coupon_code = offerObj[key].coupon_code;
        let discount_percent = Number(offerObj[key].discount_percent) || 0;
        let excludedProducts = offerObj[key].excluded_products || {};
        let offerCategory = offerObj[key].category || "";

        let totalOrderAmount=0;
        for(let i=0;i<orderData.length;i++){
          let itemID=orderData[i]['item_id'];
          let category = productObj[itemID]?productObj[itemID].category:"";
          let sub_category = productObj[itemID]?productObj[itemID].sub_category:"";
          let brand = productObj[itemID]?productObj[itemID].brand:"";
          let itemAmt=0;

          if(excludedProducts[itemID]==undefined && Number(orderData[i].total_amt)!=0 && Number(orderData[i].dealer_price)!=0){
            if(offerCategory) {
              if(category.includes(offerCategory) || sub_category.includes(offerCategory) || brand.includes(offerCategory)) {
                if((orderData[i].item_delivered_qty && orderData[i].total_amt)||
                  (orderData[i].item_modified_qty && orderData[i].total_amt)) {
                    if(orderData[i].status.toLowerCase().includes(`partially-delivered`) && orderData[i].item_delivered_qty) {
                      itemAmt = (Number(orderData[i].total_amt) / Number(orderData[i].order_qty)) * (Number(orderData[i].item_delivered_qty))
                    } else if ((orderData[i].status.toLowerCase().includes(`shipped`) || orderData[i].status.toLowerCase().includes(`partially-delivered`)) && orderData[i].item_modified_qty) {
                      itemAmt = (Number(orderData[i].total_amt) / Number(orderData[i].order_qty)) * (Number(orderData[i].item_modified_qty))
                    } else{
                      itemAmt = Number(orderData[i].total_amt);
                    }
                }else{
                    itemAmt = Number(orderData[i].total_amt);
                }
              }
            } else {
              if((orderData[i].item_delivered_qty && orderData[i].total_amt)||
                (orderData[i].item_modified_qty && orderData[i].total_amt)) {
                  if(orderData[i].status.toLowerCase().includes(`partially-delivered`) && orderData[i].item_delivered_qty) {
                    itemAmt = (Number(orderData[i].total_amt) / Number(orderData[i].order_qty)) * (Number(orderData[i].item_delivered_qty))
                  } else if ((orderData[i].status.toLowerCase().includes(`shipped`) || orderData[i].status.toLowerCase().includes(`partially-delivered`)) && orderData[i].item_modified_qty) {
                    itemAmt = (Number(orderData[i].total_amt) / Number(orderData[i].order_qty)) * (Number(orderData[i].item_modified_qty))
                  } else{
                    itemAmt = Number(orderData[i].total_amt);
                  }
              }else{
                  itemAmt = Number(orderData[i].total_amt);
              }
            }
          }

          console.log(`${itemID} - ${itemAmt} - ${offerCategory}`);
          if(!isNaN(itemAmt) && itemAmt){
            totalOrderAmount += Number(itemAmt);
          }
        }

        orderDiscount += (discount_percent/100) * totalOrderAmount;
      })

      return orderDiscount.toFixed(2);
    }


    //latest code
    const placeShortOrder = () => {
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      let itemCount=0;
      for(let i=0;i<orderData.length;i++){
        let item_id=""+orderData[i]['item_id'];
        let order_qty=Number(orderData[i]['order_qty']);
        if(selectedItemSet.has(item_id) && order_qty>0 && Number(orderData[i]['dealer_price'])!=0 && Number(orderData[i]['total_amt'])!=0){
          itemCount=itemCount+1;
        }
      }

      let firebaseOrderNumberList=orderNumber.split('/');

      let firebaseOrderNumber=firebaseOrderNumberList[0]+'-S-'+firebaseOrderNumberList[1];
      console.log(firebaseOrderNumber);
      let ordersNewRef = fire.database().ref('Orders_New/').orderByKey().startAt(`${firebaseOrderNumber}-`).endAt(`${firebaseOrderNumber}-\uf8ff`);
      ordersNewRef.once('value',snapshot=>{
        if(snapshot.hasChildren()){
          toast("Already Short order was placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
        }else{
          let fbretailerid=firebaseOrderNumber.split('-')[0];
          console.log(fbretailerid);
          let ordersHistoryRef = fire.database().ref(`Orders_History/${fbretailerid}/`).orderByKey().startAt(`${firebaseOrderNumber}-`).endAt(`${firebaseOrderNumber}-\uf8ff`);
          ordersHistoryRef.once('value', (historysnapshot) => {
            if(historysnapshot.hasChildren()){
              toast("Already Short order was placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
            }else{
              let ordersdata = snapshot.val();
              let orderNumberList=orderNumber.split("/");
              let newOrderNumber=orderNumberList[0]+"-S/"+orderNumberList[1];

              if(selectedItemSet.size>0 && itemCount>0){
                setBackDrop(true);
                let orderstatus=orderData[0]['status'];
                if(orderstatus=="Cancelled" || orderstatus=="Store-Cancelled" || orderstatus=="Ordered"){
                  setBackDrop(false);
                  toast("The Order status should not be Cancelled or Store-Cancelled or Ordered", { type: toast.TYPE.ERROR });
                }else{
                  //amount calculations
                  let finalNetOrderAmount=0;
                  let finalOrderAmount=0;
                  let finalGstAmount=0;
                  let finalTransportAmount=0;
                  let orderPaymentMode = "";
                  let couponCodeStr = "";
                  let offerOrderData = [];
                  for(let k=0;k<orderData.length;k++){
                    let item_id=""+orderData[k]['item_id'];
                    orderPaymentMode=orderData[k]['order_payment_mode']?orderData[k]['order_payment_mode']:"";
                    if(selectedItemSet.has(item_id) && Number(orderData[k]['dealer_price'])!=0 && Number(orderData[k]['total_amt'])!=0){
                      let total_amt=Number(orderData[k]['total_amt']);
                      let gst_charge=Number(orderData[k]['gst_charge']);
                      let transport_charge=Number(orderData[k]['transport_charge']);
                      finalNetOrderAmount=finalNetOrderAmount+total_amt;
                      finalGstAmount=finalGstAmount+gst_charge;
                      finalTransportAmount=finalTransportAmount+transport_charge;
                      couponCodeStr = orderData[k]['coupon_code_order_level'] || "";
                      offerOrderData.push(orderData[k]);
                    }
                  }
                  finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

                  let apiList = [];
                  if(couponCodeStr) {
                    apiList.push(fetch(`${orderingApiUrl}/offers?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`).then((data) => { return data.json() } ))
                    apiList.push(fire.database().ref(`Offers_Custom_Level`).once('value'))
                    apiList.push(fire.database().ref(`Product_Master_New/A`).once('value'))
                  }

                  if(orderPaymentMode=="Credit" || orderPaymentMode=="Internal_Credit"){
                    apiList.push(fire.database().ref(`Superzop_Kredit_Internal/${fbretailerid}/approved_details`).once('value'))
                  }

                  console.log(apiList);

                  Promise.all(apiList).then((responseList) => {
                    console.log(responseList);
                    let customerOffersData = {};
                    let retailerKreditDetails = {};
                    let productObj = {};
                    let fbOffersObj = {};
                    let finalCouponCodeStr = "";
                    let offersObj = {};

                    if(couponCodeStr && responseList.length>0) {
                      customerOffersData = responseList[0];
                      console.log(customerOffersData);

                      productObj = responseList[2].val() || {};
                      fbOffersObj = responseList[1].val() || {};

                      if(customerOffersData.success==1) {
                        let customOffers = customerOffersData['data'] || [];
                        let couponSet = new Set();

                        let couponCodeList = couponCodeStr.split(",");
                        for(let couponCode of couponCodeList) {
                          if(!couponSet.has(couponCode)) {
                            couponSet.add(couponCode);
                          }
                        }
                        console.log("couponSet");
                        console.log(couponSet);

                        for(let customOffer of customOffers) {
                          let coupon_code = customOffer.coupon_code;
                          let discount_percent = Number(customOffer.discount_percent) || 0;

                          if(discount_percent>0 && couponSet.has(coupon_code)) {
                            offersObj[coupon_code] = customOffer;
                            finalCouponCodeStr += `${coupon_code},`;
                          }
                        }

                        console.log(fbOffersObj);
                        Object.keys(fbOffersObj).map((key,index) => {
                          let discount_percent = Number(fbOffersObj[key].discount_percent) || 0;
                          let coupon_code = fbOffersObj[key].coupon_code;

                          if(discount_percent>0 && couponSet.has(coupon_code)) {
                            offersObj[coupon_code] = fbOffersObj[key];
                            finalCouponCodeStr += `${coupon_code},`;
                          }
                        })

                        if(finalCouponCodeStr) {
                          finalCouponCodeStr = finalCouponCodeStr.slice(0,-1);
                        }
                      }
                    }

                    console.log(finalCouponCodeStr);
                    console.log(offersObj);

                    let orderDisc = 0;
                    if(finalCouponCodeStr) {
                      orderDisc = getOrderDiscount(offerOrderData, offersObj, productObj);
                    }

                    let updatedAvailableCredit = 0;
                    if(orderPaymentMode=="Credit" || orderPaymentMode=="Internal_Credit") {
                      if(responseList.length==1) {
                        retailerKreditDetails = responseList[0].val() || {};
                      } else if(responseList.length==4) {
                        retailerKreditDetails = responseList[3].val() || {};
                      }

                      let netOrderAmtWithDiscount = finalNetOrderAmount-orderDisc;

                      let retailerAvailableCredit = Number(retailerKreditDetails.available_credit) || 0;
                      updatedAvailableCredit = retailerAvailableCredit - netOrderAmtWithDiscount;

                      fire.database().ref(`Superzop_Kredit_Internal/${fbretailerid}/approved_details`).update({"available_credit":updatedAvailableCredit});
                    }


                    let currTimestamp=Date.parse(new Date());
                    let fbordersnewele={};
                    let finalOrderNumber="";
                    let finalOrderList=JSON.parse(JSON.stringify(orderData));
                    //get only items with item_delivered_qty < order_qty
                    for(let i=0;i<finalOrderList.length;i++){
                      let item_id=""+finalOrderList[i]['item_id'];
                      let order_qty=Number(finalOrderList[i]['order_qty']);
                      if(selectedItemSet.has(item_id) && order_qty>0 && Number(orderData[i]['dealer_price'])!=0 && Number(orderData[i]['total_amt'])!=0){
                        let ele=finalOrderList[i];
                        let order_number=finalOrderList[i]['order_number'];

                        let orderNumberList=order_number.split("/");
                        let newOrderNumber=orderNumberList[0]+"-S/"+orderNumberList[1];
                        finalOrderNumber=newOrderNumber;
                        ele['order_number']=newOrderNumber;
                        let fbkey=orderNumberList[0]+"-S-"+orderNumberList[1]+"-"+item_id;

                        ele['status']='Ordered';
                        let netOrderAmtWithDiscount = finalNetOrderAmount-orderDisc;
                        ele['net_order_amt']=""+netOrderAmtWithDiscount;
                        ele['order_date']=currTimestamp;
                        ele['exp_delivery_date']=currTimestamp;
                        ele['cash_amt']=""+netOrderAmtWithDiscount;
                        ele['net_amt']=""+netOrderAmtWithDiscount;
                        ele['order_amt']=""+finalOrderAmount;
                        ele['final_order_amt']=""+finalOrderAmount;
                        ele['cashback_redeemed']="0";
                        ele['order_disc']="0";
                        ele['reason']=returnReason;
                        let curdate = formatDate(new Date());
                        ele['ordered_at']=curdate;
                        ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];

                        if(orderPaymentMode=="Credit" || orderPaymentMode=="Internal_Credit") {
                          ele['order_payment_mode']="Internal_Credit";
                          ele['credit_amt']=""+netOrderAmtWithDiscount;
                        } else {
                          ele['order_payment_mode']="Cash on Delivery"
                          ele['credit_amt']="0"
                        }

                        ele['card_amt']="0"
                        ele['cashback_redeemed']="0"
                        ele['cheque_amt']="0"
                        ele['coupon_code_order_level']=finalCouponCodeStr || ""
                        ele['order_disc']=orderDisc

                        delete ele['delivery_date'];
                        delete ele['delivery_date_conv'];
                        delete ele['item_delivered_qty'];
                        delete ele['processed_date'];
                        delete ele['revised_order_amt'];


                        delete ele['weight'];
                        delete ele['shop_name'];
                        delete ele['phone'];
                        delete ele['address1'];
                        delete ele['address2'];
                        delete ele['city'];
                        delete ele['pincode'];
                        delete ele['gst_number'];
                        delete ele['agent_id'];
                        delete ele['bagdetails'];
                        delete ele['numbags'];
                        fbordersnewele[`${fbkey}`]=ele;
                      }
                    }
                    console.log('fbordersnewele');
                    console.log(fbordersnewele);

                    let orrdersNewRef=fire.database().ref('Orders_New');
                    orrdersNewRef.update(fbordersnewele,function(err){
                      if(err){
                        setBackDrop(false);
                        toast("Error in adding data to Orders_New : "+err, { type: toast.TYPE.ERROR });
                      }else{
                        getDeliveryOrdernumber(orderNumber);
                        setBackDrop(false);
                        toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });
                        if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                          setOrderOption("ScheduleForDelivery");
                          let status='Ordered';
                          let deliveryOrdersFinalItemsList=[];
                          for(let i=0;i<orderData.length;i++){
                            let itemid=""+orderData[i]['item_id'];
                            let order_qty=Number(orderData[i]['order_qty']);
                            if(selectedItemSet.has(itemid) && order_qty>0){
                              let itemele=orderData[i];
                              itemele['status']=status;
                              deliveryOrdersFinalItemsList.push(itemele);
                            }
                          }

                          setDeliveryOrderData(deliveryOrdersFinalItemsList);
                        }else{
                          setOrderData([]);
                          setBackupOrderData([]);
                          setSearchOrderData([]);
                          setItemChecked({});
                          setOrdrStatus("");
                          setInitialOrderSelection(true);
                          setOrderNumber("");
                          setReturnOrderType("SELECT");
                          setReturnReason("SELECT");
                          setReturnItem("SELECT");
                          setOrderType("SELECT");
                          setActualOrderType("SELECT");
                          setCustomerAdjustedPayment("SELECT");
                          setIsOrderAlreadyPickedUp("SELECT");
                          setReasonDropdownDisable(false);
                          setIsOrderPickedDropdownDisable(false);
                        }
                      }
                    });

                  }).catch((err) => {
                    toast(`Error in fetching data : ${err.message}`, { type: toast.TYPE.ERROR });
                  })

                }
              }else{
                toast("Please select items", { type: toast.TYPE.ERROR });
              }
            }
          });
        }
      });
    }

    const ReasonsDropdown = () => {
      console.log(actualOrderType);
      let reasonlist=[];
      let rows=[];

      if(actualOrderType!="SELECT"){
        if(actualOrderType=="S"){
          reasonlist=["Shortage","UnDelivered","Cross Loading","Damaged","Quality Issue","Weight Issue","Packaging Issue"];
        }else if(actualOrderType=="R"){
          reasonlist=["Shortage","UnDelivered","Cross Loading","Damaged","Quality Issue","Weight Issue","Packaging Issue","MRP Issue"];
        }else if(actualOrderType=="OR"){
          reasonlist=["Cross Loading","Damaged","Quality Issue","Weight Issue","Packaging Issue"];
        }
        rows=reasonlist.map((reason,index)=>{
          return (<Dropdown.Item key={reason} name={reason} onClick={(e)=>{e.preventDefault();setReturnReason(e.target.name);}}>{reason}</Dropdown.Item>)
        });
      }else{
        rows=[];
      }
      return (<><DropdownButton id="dropdown-basic-button" title={(returnReason!="SELECT")?returnReason:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton></>);
    }

    const VehicleDropdown = () => {
      let rows=[];

      if(validRouteList.length>0){
        rows=validRouteList.map((route,index)=>{
          if(route!="9999"){
            return (<Dropdown.Item key={route} name={route} onClick={(e)=>{e.preventDefault();setRouteVehicleId(e.target.name);}}>{route}</Dropdown.Item>)
          }
        });
      }else{
        rows=[];
      }

      return (<><DropdownButton id="dropdown-basic-button" title={(routeVehicleId!="SELECT")?routeVehicleId:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton></>);
    }

    const ReturnedVehicleDropdown = () => {
      let rows=[];

      if(validRouteList.length>0){
        rows=validRouteList.map((route,index)=>{
          if(route!="9999"){
            return (<Dropdown.Item key={route} name={route} onClick={(e)=>{e.preventDefault();setReturnedVehicleId(e.target.name);}}>{route}</Dropdown.Item>)
          }
        });
      }else{
        rows=[];
      }

      return (<><DropdownButton id="dropdown-basic-button" title={(returnedVehicleId!="SELECT")?returnedVehicleId:"SELECT"} size="sm" variant="secondary" >{rows}</DropdownButton></>);
    }

    const placeReturnOrderForDelivery = () => {
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      let itemCount=0;
      for(let i=0;i<orderData.length;i++){
        let item_id=""+orderData[i]['item_id'];
        let order_qty=Number(orderData[i]['order_qty']);
        if(selectedItemSet.has(item_id) && order_qty>0){
          itemCount=itemCount+1;
        }
      }

      fire.database().ref('Orders_New/').once('value',snapshot=>{
        if(snapshot.hasChildren()){
          let ordersdata = snapshot.val();
          let orderNumberList=orderNumber.split("/");
          let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];
          let thirdOrderNumber=orderNumberList[0]+"-R1/"+orderNumberList[1];
          let fourthOrderNumber=orderNumberList[0]+"-R2/"+orderNumberList[1];
          let fifthOrderNumber=orderNumberList[0]+"-R3/"+orderNumberList[1];
          let isOrderNumberPresent=false;
          let isThirdOrderNumberPresent=false;
          let isFourthOrderNumberPresent=false;
          let isFifthOrderNumberPresent=false;
          Object.keys(ordersdata).map((key,value)=>{
            if(ordersdata[key]['order_number']==newOrderNumber){
              isOrderNumberPresent=true;
            }
            if(ordersdata[key]['order_number']==thirdOrderNumber){
              isThirdOrderNumberPresent=true;
            }
            if(ordersdata[key]['order_number']==fourthOrderNumber){
              isFourthOrderNumberPresent=true;
            }
            if(ordersdata[key]['order_number']==fifthOrderNumber){
              isFifthOrderNumberPresent=true;
            }
          });

          if(isOrderNumberPresent==true || isThirdOrderNumberPresent == true || isFourthOrderNumberPresent == true || isFifthOrderNumberPresent == true){
            // if(isThirdOrderNumberPresent==true){
            //   toast("Already 2 Returns were placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
            // }
            if(isFifthOrderNumberPresent==true){
              toast("Already 4 Returns were placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
            }else if(isFourthOrderNumberPresent==true){
              setShowDuplicateOrderNumberModal(true);
              setDuplicateOrderNumber("R2");
            }else if(isThirdOrderNumberPresent==true){
              setShowDuplicateOrderNumberModal(true);
              setDuplicateOrderNumber("R1");
            }else{
              setShowDuplicateOrderNumberModal(true);
              setDuplicateOrderNumber("R");
            }
          }else{
            //check in orders history
            let historyRetailerID=orderNumberList[0];
            fire.database().ref(`Orders_History/${historyRetailerID}/`).once('value',historysnapshot=>{
              let isHistoryOrderNumberPresent=false;
              let isHistoryThirdOrderNumberPresent=false;
              let isHistoryFourthOrderNumberPresent=false;
              let isHistoryFifthOrderNumberPresent=false;
              if(historysnapshot.hasChildren()){
                let historyordersdata=historysnapshot.val();

                Object.keys(historyordersdata).map((key,value)=>{
                  if(historyordersdata[key]['order_number']==newOrderNumber){
                    isHistoryOrderNumberPresent=true;
                  }
                  if(historyordersdata[key]['order_number']==thirdOrderNumber){
                    isHistoryThirdOrderNumberPresent=true;
                  }
                  if(historyordersdata[key]['order_number']==fourthOrderNumber){
                    isHistoryFourthOrderNumberPresent=true;
                  }
                  if(historyordersdata[key]['order_number']==fifthOrderNumber){
                    isHistoryFifthOrderNumberPresent=true;
                  }
                });
              }

              //place -R
              if(isHistoryOrderNumberPresent==true){
                if(isHistoryFifthOrderNumberPresent==true){
                  toast("Already 4 Returns were placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
                }else if(isHistoryFourthOrderNumberPresent==true){
                  setShowDuplicateOrderNumberModal(true);
                  setDuplicateOrderNumber("R2");
                }else if(isHistoryThirdOrderNumberPresent==true){
                  setShowDuplicateOrderNumberModal(true);
                  setDuplicateOrderNumber("R1");
                }else{
                  setShowDuplicateOrderNumberModal(true);
                  setDuplicateOrderNumber("R");
                }
              }else{
                if(selectedItemSet.size && itemCount>0){
                  let orderstatus=orderData[0]['status'];
                  if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
                    toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
                  }else{
                    setBackDrop(true);
                    let fbordersnewele={};
                    let finalOrderList=JSON.parse(JSON.stringify(orderData));
                    let currTimestamp=Date.parse(new Date());
                    let finalOrderNumber="";

                    for(let i=0;i<finalOrderList.length;i++){
                      let item_id=""+finalOrderList[i]['item_id'];
                      let order_qty=Number(finalOrderList[i]['order_qty']);
                      if(selectedItemSet.has(item_id) && order_qty>0){
                        let ele=finalOrderList[i];
                        let order_number=finalOrderList[i]['order_number'];

                        let orderNumberList=order_number.split("/");
                        let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];
                        finalOrderNumber=newOrderNumber;
                        ele['order_number']=newOrderNumber;
                        let fbkey=orderNumberList[0]+"-R-"+orderNumberList[1]+"-"+item_id;
                        ele['price']=ele['dealer_price'];
                        ele['status']='Returned';
                        ele['net_order_amt']="0";
                        ele['order_date']=currTimestamp;
                        ele['exp_delivery_date']=currTimestamp;
                        ele['cash_amt']="0";
                        ele['total_amt']="0";
                        ele['net_amt']="0";
                        ele['order_amt']="0";
                        ele['final_order_amt']="0";
                        ele['gst_charge']="0";
                        ele['transport_charge']="0";

                        ele['cashback_redeemed']="0";
                        ele['order_disc']="0";
                        ele['reason']=returnReason;
                        let curdate = formatDate(new Date());
                        ele['ordered_at']=curdate;
                        ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                        if(customerReturnedItems=="No"){
                          ele['customer_returned_items']="No";
                        } else if (customerReturnedItems=="Yes"){
                          ele['customer_returned_items']="Yes";
                        }
                        ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                        ele['order_payment_mode']="Cash on Delivery"
                        ele['credit_amt']="0"
                        ele['card_amt']="0"
                        ele['cashback_redeemed']="0"
                        ele['cheque_amt']="0"

                        delete ele['delivery_date'];
                        delete ele['delivery_date_conv'];
                        delete ele['item_delivered_qty'];
                        delete ele['processed_date'];
                        delete ele['revised_order_amt'];

                        delete ele['weight'];
                        delete ele['shop_name'];
                        delete ele['phone'];
                        delete ele['address1'];
                        delete ele['address2'];
                        delete ele['city'];
                        delete ele['pincode'];
                        delete ele['gst_number'];
                        delete ele['agent_id'];
                        delete ele['bagdetails'];
                        delete ele['numbags'];
                        fbordersnewele[`${fbkey}`]=ele;
                      }
                    }
                    console.log('fbordersnewele');
                    console.log(fbordersnewele);

                    let driverOrdersList=JSON.parse(JSON.stringify(orderData));
                    let driverordershistoryele={};

                    let year,month,date;

                    let currdate=new Date();
                    let day=currdate.getDate();
                    month=currdate.getMonth()+1;
                    year=currdate.getFullYear();
                    date=day+"-"+month+'-'+year;

                    let retailerid=finalOrderNumber.split("-")[0];
                    let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
                    console.log(apiUrl);
                    fetch(apiUrl)
                    .then((response) => {
                        return (response['status']==200)?response.json():[];
                    }).then((result) => {
                        let retailerData=result['data'];
                        for(let z=0;z<driverOrdersList.length;z++){
                          let item_id=""+driverOrdersList[z]['item_id'];
                          let order_qty=Number(driverOrdersList[z]['order_qty']);
                          if(selectedItemSet.has(item_id) && order_qty>0){
                            let doele=driverOrdersList[z];
                            doele['priority']=1;

                            let order_number=driverOrdersList[z]['order_number'];
                            let orderNumberList=order_number.split("/");
                            let newOrderNumber=orderNumberList[0]+"-R/"+orderNumberList[1];

                            doele['order_number']=newOrderNumber;

                            doele['ordered_from']='web';
                            doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                            doele['status']='Delivered';
                            doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                            doele['order_date']=formatOrderDate(currTimestamp);
                            doele['exp_delivery_date']=currTimestamp;


                            doele['order_amt']="0"
                            doele['final_order_amt']="0"
                            doele['net_order_amt']="0";
                            doele['cash_amt']="0";
                            doele['net_amt']="0";
                            doele['total_amt']="0";
                            doele['gst_charge']="0";
                            doele['transport_charge']="0";
                            if(customerReturnedItems=="No"){
                              doele['customer_returned_items']="No";
                            } else if (customerReturnedItems=="Yes"){
                              doele['customer_returned_items']="Yes";
                            }
                            doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";

                            doele['cashback_redeemed']="0";
                            doele['order_disc']="0";

                            doele['priority']=1;

                            doele['address1']=retailerData['address1']?retailerData['address1']:"";
                            doele['address2']=retailerData['address2']?retailerData['address2']:"";
                            doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                            doele['city']=retailerData['city']?retailerData['city']:"";
                            doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                            doele['phone']=retailerData['phone']?retailerData['phone']:"";
                            doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                            doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";

                            doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                            doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                            doele['asm']=retailerData['asm']?retailerData['asm']:"";
                            doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                            doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                            doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                            doele['order_payment_mode']="Cash on Delivery"
                            doele['credit_amt']="0"
                            doele['card_amt']="0"
                            doele['cashback_redeemed']="0"
                            doele['cheque_amt']="0"

                            delete doele['delivery_date'];
                            delete doele['delivery_date_conv'];
                            delete doele['processed_date'];
                            delete doele['revised_order_amt'];
                            delete doele['item_delivered_qty'];

                            let ordnum=newOrderNumber;
                            //23451-R/3564656
                            let ordnumlist=ordnum.split("/");
                            let retid=ordnumlist[0];
                            let retlist=retid.split("-");
                            let dorefstr=retlist[0]+"_R-"+ordnumlist[1]+"-"+item_id;
                            // console.log(dorefstr);
                            let dohkey=`/${year}/${month}/${date}/9999/${dorefstr}`;
                            doele['driver_id']="9999"
                            doele['vehicle']=9999

                            driverordershistoryele[`${dohkey}`]=doele;
                          }
                        }
                        console.log('driverordershistoryele');
                        console.log(driverordershistoryele);


                        let orrdersNewRef=fire.database().ref('Orders_New');
                        orrdersNewRef.update(fbordersnewele,function(err){
                          if(err){
                            toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                          }else{
                            if(customerReturnedItems!="No"){
                              let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                              driverOrdersRef.update(driverordershistoryele,function(error){
                                if (error) {
                                  toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                                  setBackDrop(false);
                                }else{
                                    getDeliveryOrdernumber(orderNumber);
                                    setBackDrop(false);
                                    toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                                    if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                                      setOrderOption("ScheduleForDelivery");
                                      let status='Returned';
                                      let deliveryOrdersFinalItemsList=[];
                                      for(let i=0;i<orderData.length;i++){
                                        let itemid=""+orderData[i]['item_id'];
                                        let order_qty=Number(orderData[i]['order_qty']);
                                        if(selectedItemSet.has(itemid) && order_qty>0){
                                          let itemele=orderData[i];
                                          itemele['status']=status;
                                          itemele['net_order_amt']="0"
                                          itemele['cash_amt']="0"
                                          itemele['net_amt']="0"
                                          itemele['order_amt']="0"
                                          itemele['final_order_amt']="0"
                                          itemele['cashback_redeemed']="0";
                                          itemele['order_disc']="0";
                                          itemele['total_amt']="0";
                                          itemele['gst_charge']="0";
                                          itemele['transport_charge']="0";
                                          if(customerReturnedItems=="No"){
                                            itemele['customer_returned_items']="No";
                                          } else if (customerReturnedItems=="Yes"){
                                            itemele['customer_returned_items']="Yes";
                                          }

                                          deliveryOrdersFinalItemsList.push(itemele);
                                        }
                                      }

                                      setDeliveryOrderData(deliveryOrdersFinalItemsList);
                                    }else{
                                      setOrderData([]);
                                      setBackupOrderData([]);
                                      setSearchOrderData([]);
                                      setItemChecked({});
                                      setOrdrStatus("");
                                      setInitialOrderSelection(true);
                                      setOrderNumber("");
                                      setReturnOrderType("SELECT");
                                      setReturnReason("SELECT");
                                      setReturnItem("SELECT");
                                      setOrderType("SELECT");
                                      setActualOrderType("SELECT");
                                      setCustomerAdjustedPayment("SELECT");
                                      setIsOrderAlreadyPickedUp("SELECT");
                                      setReturnedVehicleId("SELECT");
                                      setReasonDropdownDisable(false);
                                      setIsOrderPickedDropdownDisable(false);
                                    }
                                }
                              });
                            }else{
                              getDeliveryOrdernumber(orderNumber);
                              setBackDrop(false);
                              toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                              if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                                setOrderOption("ScheduleForDelivery");
                                let status='Returned';
                                let deliveryOrdersFinalItemsList=[];
                                for(let i=0;i<orderData.length;i++){
                                  let itemid=""+orderData[i]['item_id'];
                                  let order_qty=Number(orderData[i]['order_qty']);
                                  if(selectedItemSet.has(itemid) && order_qty>0){
                                    let itemele=orderData[i];
                                    itemele['status']=status;
                                    itemele['net_order_amt']="0"
                                    itemele['cash_amt']="0"
                                    itemele['net_amt']="0"
                                    itemele['order_amt']="0"
                                    itemele['final_order_amt']="0"
                                    itemele['cashback_redeemed']="0";
                                    itemele['order_disc']="0";
                                    itemele['total_amt']="0";
                                    itemele['gst_charge']="0";
                                    itemele['transport_charge']="0";
                                    if(customerReturnedItems=="No"){
                                      itemele['customer_returned_items']="No";
                                    } else if (customerReturnedItems=="Yes"){
                                      itemele['customer_returned_items']="Yes";
                                    }

                                    deliveryOrdersFinalItemsList.push(itemele);
                                  }
                                }

                                setDeliveryOrderData(deliveryOrdersFinalItemsList);
                              }else{
                                setOrderData([]);
                                setBackupOrderData([]);
                                setSearchOrderData([]);
                                setItemChecked({});
                                setOrdrStatus("");
                                setInitialOrderSelection(true);
                                setOrderNumber("");
                                setReturnOrderType("SELECT");
                                setReturnReason("SELECT");
                                setReturnItem("SELECT");
                                setOrderType("SELECT");
                                setActualOrderType("SELECT");
                                setCustomerAdjustedPayment("SELECT");
                                setIsOrderAlreadyPickedUp("SELECT");
                                setReturnedVehicleId("SELECT");
                                setReasonDropdownDisable(false);
                                setIsOrderPickedDropdownDisable(false);
                              }

                            }
                          }
                        });
                    }).catch((reterr)=>{
                      setBackDrop(false);
                      toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
                    })
                  }
                }else{
                  toast("Please select items", { type: toast.TYPE.ERROR });
                }
              }
            });
          }
        }
      });
    }

    const placeReturnOrderForWarehouse = () => {
      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      let itemCount=0;
      for(let i=0;i<orderData.length;i++){
        let item_id=""+orderData[i]['item_id'];
        let order_qty=Number(orderData[i]['order_qty']);
        if(selectedItemSet.has(item_id) && order_qty>0){
          itemCount=itemCount+1;
        }
      }

      fire.database().ref('Orders_New/').once('value',snapshot=>{
        if(snapshot.hasChildren()){
          let ordersdata = snapshot.val();
          let orderNumberList=orderNumber.split("/");
          let newOrderNumber=orderNumberList[0]+"-OR/"+orderNumberList[1];
          let thirdOrderNumber=orderNumberList[0]+"-OR1/"+orderNumberList[1];
          let fourthOrderNumber=orderNumberList[0]+"-OR2/"+orderNumberList[1];

          let isOrderNumberPresent=false;
          let isThirdOrderNumberPresent=false;
          let isFourthOrderNumberPresent = false;
          Object.keys(ordersdata).map((key,value)=>{
            if(ordersdata[key]['order_number']==newOrderNumber){
              isOrderNumberPresent=true;
            }
            if(ordersdata[key]['order_number']==thirdOrderNumber){
              isThirdOrderNumberPresent=true;
            }

            if(ordersdata[key]['order_number']==fourthOrderNumber){
              isFourthOrderNumberPresent=true;
            }
          });

          if(isOrderNumberPresent==true){
            if(isFourthOrderNumberPresent==true) {
              toast("Already 3 Returns were placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
            } else if(isThirdOrderNumberPresent==true){
              setShowDuplicateOrderNumberModal(true);
              setDuplicateOrderNumber("OR1");
            }else{
              setShowDuplicateOrderNumberModal(true);
              setDuplicateOrderNumber("OR");
            }
          }else{
            //check in orders history
            let historyRetailerID=orderNumberList[0];
            fire.database().ref(`Orders_History/${historyRetailerID}/`).once('value',historysnapshot=>{
              let isHistoryOrderNumberPresent=false;
              let isHistoryThirdOrderNumberPresent=false;
              let isHistoryFourthOrderNumberPresent=false;
              if(historysnapshot.hasChildren()){
                let historyordersdata=historysnapshot.val();

                Object.keys(historyordersdata).map((key,value)=>{
                  if(historyordersdata[key]['order_number']==newOrderNumber){
                    isHistoryOrderNumberPresent=true;
                  }
                  if(historyordersdata[key]['order_number']==thirdOrderNumber){
                    isHistoryThirdOrderNumberPresent=true;
                  }

                  if(historyordersdata[key]['order_number']==fourthOrderNumber){
                    isHistoryFourthOrderNumberPresent=true;
                  }
                  
                });
              }

              //place -OR
              if(isHistoryOrderNumberPresent==true){
                if(isHistoryFourthOrderNumberPresent==true) {
                  toast("Already 3 Returns were placed for this Order. Please contact Admin.", { type: toast.TYPE.ERROR });
                } else if(isHistoryThirdOrderNumberPresent==true){
                  setShowDuplicateOrderNumberModal(true);
                  setDuplicateOrderNumber("OR1");
                }else{
                  setShowDuplicateOrderNumberModal(true);
                  setDuplicateOrderNumber("OR");
                }
              }else{
                if(selectedItemSet.size && itemCount>0){
                  let orderstatus=orderData[0]['status'];
                  if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
                    toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
                  // }else if(customerAdjustedPayment=="No" && !validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
                  //   toast("Please contact Admin to place the ReturnOrder.", { type: toast.TYPE.ERROR });
                  }else{
                    if(isOrderAlreadyPickedUp=="Yes"){
                      console.log(customerAdjustedPayment);
                      setBackDrop(true);
                      //amount calculations
                      let finalNetOrderAmount=0;
                      let finalOrderAmount=0;
                      let finalGstAmount=0;
                      let finalTransportAmount=0;
                      for(let k=0;k<orderData.length;k++){
                        let item_id=""+orderData[k]['item_id'];
                        if(selectedItemSet.has(item_id)){
                          let total_amt=Number(orderData[k]['total_amt']);
                          let gst_charge=Number(orderData[k]['gst_charge']);
                          let transport_charge=Number(orderData[k]['transport_charge']);
                          finalNetOrderAmount=finalNetOrderAmount+total_amt;
                          finalGstAmount=finalGstAmount+gst_charge;
                          finalTransportAmount=finalTransportAmount+transport_charge;
                        }
                      }
                      finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

                      let orderID,retailerID,walletAmt;
                      let today = new Date();
                      let transactionDate=today.toShortFormat();

                      let currTimestamp=Date.parse(new Date());
                      let fbordersnewele={};
                      let finalOrderList=JSON.parse(JSON.stringify(orderData));
                      //get only items with item_delivered_qty < order_qty
                      for(let i=0;i<finalOrderList.length;i++){
                        let item_id=""+finalOrderList[i]['item_id'];
                        let order_qty=Number(finalOrderList[i]['order_qty']);
                        if(selectedItemSet.has(item_id) && order_qty>0){
                          let ele=finalOrderList[i];
                          let order_number=finalOrderList[i]['order_number'];

                          let orderNumberList=order_number.split("/");
                          let newOrderNumber=orderNumberList[0]+"-OR/"+orderNumberList[1];
                          ele['order_number']=newOrderNumber;
                          let fbkey=orderNumberList[0]+"-OR-"+orderNumberList[1]+"-"+item_id;

                          ele['status']='Returned';

                          ele['order_date']=currTimestamp;
                          ele['exp_delivery_date']=currTimestamp;
                          let price = (Number(finalOrderList[i]['total_amt']) /Number(finalOrderList[i]['order_qty'])) * Number(finalOrderList[i]['order_qty']);
                          ele['price'] = price.toString();
                          if(customerAdjustedPayment=="No"){
                            ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                            ele['cash_amt']="0";
                          }else if(customerAdjustedPayment=="Yes"){
                            ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                            ele['cash_amt']="0";
                          }


                          orderID=newOrderNumber;
                          retailerID=finalOrderList[i]['retailer_id'];
                          walletAmt=""+finalOrderAmount;

                          ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";

                          ele['cashback_redeemed']="0";
                          ele['order_disc']="0";
                          ele['reason']=returnReason;
                          let curdate = formatDate(new Date());
                          ele['ordered_at']=curdate;
                          ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                          ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";
                          // ele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";
                          ele['order_payment_mode']="Cash on Delivery"
                          ele['credit_amt']="0"
                          ele['card_amt']="0"
                          ele['cashback_redeemed']="0"
                          ele['cheque_amt']="0"


                          delete ele['delivery_date'];
                          delete ele['delivery_date_conv'];
                          delete ele['item_delivered_qty'];
                          delete ele['processed_date'];
                          delete ele['revised_order_amt'];

                          delete ele['weight'];
                          delete ele['shop_name'];
                          delete ele['phone'];
                          delete ele['address1'];
                          delete ele['address2'];
                          delete ele['city'];
                          delete ele['pincode'];
                          delete ele['gst_number'];
                          delete ele['agent_id'];
                          delete ele['bagdetails'];
                          delete ele['numbags'];
                          fbordersnewele[`${fbkey}`]=ele;
                        }
                      }
                      console.log('fbordersnewele');
                      console.log(fbordersnewele);

                      let driverOrdersList=JSON.parse(JSON.stringify(orderData));
                      let driverordershistoryele={};

                      let year,month,date;

                      if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
                        let currdate=new Date(returnDate);
                        let day=currdate.getDate();
                        month=currdate.getMonth()+1;
                        year=currdate.getFullYear();
                        date=day+"-"+month+'-'+year;
                      }else{
                        let currdate=new Date();
                        let day=currdate.getDate();
                        month=currdate.getMonth()+1;
                        year=currdate.getFullYear();
                        date=day+"-"+month+'-'+year;
                      }

                      let retailerid=orderID.split("-")[0];
                      let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
                      console.log(apiUrl);
                      fetch(apiUrl)
                      .then((response) => {
                          return (response['status']==200)?response.json():[];
                      }).then((result) => {
                          let retailerData=result['data'];

                          for(let z=0;z<driverOrdersList.length;z++){
                            let item_id=""+driverOrdersList[z]['item_id'];
                            let order_qty=Number(driverOrdersList[z]['order_qty']);
                            if(selectedItemSet.has(item_id) && order_qty>0){
                              let doele=driverOrdersList[z];
                              doele['priority']=1;

                              let order_number=driverOrdersList[z]['order_number'];

                              let orderNumberList=order_number.split("/");
                              let newOrderNumber=orderNumberList[0]+"-OR/"+orderNumberList[1];
                              let fbkey=orderNumberList[0]+"_OR-"+orderNumberList[1]+"-"+item_id;

                              doele['order_number']=newOrderNumber;

                              doele['ordered_from']='web';
                              doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                              doele['status']='Delivered';
                              doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                              if(newOrderNumber.includes("-OR")){
                                // doele['status']="Returned";
                                doele['status']="Processed";
                              }

                              doele['order_date']=formatOrderDate(currTimestamp);
                              doele['exp_delivery_date']=currTimestamp;
                              doele['variety'] = driverOrdersList[z].variety;
                              doele['gst_per'] = driverOrdersList[z].gst_per;
                              doele['brand'] = driverOrdersList[z].brand;
                              doele['image_url'] = driverOrdersList[z].image_url;
                              
                              let price = (Number(driverOrdersList[z]['total_amt']) /Number(driverOrdersList[z]['order_qty'])) * Number(driverOrdersList[z]['order_qty']);
                              doele['price'] = price.toString();
                              if(customerAdjustedPayment=="No"){
                                doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                                doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                                doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                                doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                                doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                                doele['cash_amt']="0";
                              }else if(customerAdjustedPayment=="Yes"){
                                doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                                doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                                doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                                doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                                doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                                doele['cash_amt']="0";
                              }

                              doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                              doele['cashback_redeemed']="0";
                              doele['order_disc']="0";
                              // doele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";

                              doele['address1']=retailerData['address1']?retailerData['address1']:"";
                              doele['address2']=retailerData['address2']?retailerData['address2']:"";
                              doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                              doele['city']=driverOrdersList[z]['city']?driverOrdersList[z]['city']:"";
                              doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                              doele['phone']=retailerData['phone']?retailerData['phone']:"";
                              doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                              doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                              doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                              doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                              doele['asm']=retailerData['asm']?retailerData['asm']:"";
                              doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                              doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                              doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                              doele['order_payment_mode']="Cash on Delivery"
                              doele['credit_amt']="0"
                              doele['card_amt']="0"
                              doele['cashback_redeemed']="0"
                              doele['cheque_amt']="0";
                              doele['gst_charge']=""+driverOrdersList[z]['gst_charge'];
                              doele['apmc_charge']=""+driverOrdersList[z]['apmc_charge'];

                              delete doele['delivery_date'];
                              delete doele['delivery_date_conv'];
                              delete doele['processed_date'];
                              delete doele['revised_order_amt'];
                              delete doele['item_delivered_qty'];

                              let dohkey=`/${year}/${month}/${date}/${returnedVehicleId}/${fbkey}`;

                              driverordershistoryele[`${dohkey}`]=doele;
                            }
                          }
                          console.log('driverordershistoryele');
                          console.log(driverordershistoryele);

                          //add in firebase
                          let orrdersNewRef=fire.database().ref('Orders_New');
                          orrdersNewRef.update(fbordersnewele,function(err){
                            if(err){
                              setBackDrop(false);
                              toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                            }else{
                              let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                              driverOrdersRef.update(driverordershistoryele,function(error){
                                if (error) {
                                  setBackDrop(false);
                                  toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                                }else{
                                  if(customerAdjustedPayment=="No"){
                                    let walletele={};
                                    walletele['order_id']=orderID;
                                    walletele['retailer_id']=""+retailerID;
                                    walletele['transaction_date']=transactionDate;
                                    walletele['wallet_amt']=walletAmt;
                                    let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                                    let walletRef = walletMasterRef.push();
                                    walletRef.set(walletele,function(errr){
                                      if(errr){
                                        toast("Error in adding amount to Wallet_Master : "+errr, { type: toast.TYPE.ERROR });
                                      }else{
                                        setOrderData([]);
                                        setBackupOrderData([]);
                                        setSearchOrderData([]);
                                        setItemChecked({});
                                        setOrdrStatus("");
                                        setInitialOrderSelection(true);
                                        setOrderNumber("");
                                        setReturnOrderType("SELECT");
                                        setReturnReason("SELECT");
                                        setReturnItem("SELECT");
                                        setCustomerAdjustedPayment("SELECT");
                                        setIsOrderAlreadyPickedUp("SELECT");
                                        setOrderType("SELECT");
                                        setActualOrderType("SELECT");
                                        setReturnedVehicleId("SELECT");
                                        setReasonDropdownDisable(false);
                                        setIsOrderPickedDropdownDisable(false);
                                        let currdate=formatOrderDate(new Date());
                                        setReturnDate(currdate);

                                        setBackDrop(false);
                                        toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                                      }
                                    });

                                  }else{
                                    setOrderData([]);
                                    setBackupOrderData([]);
                                    setSearchOrderData([]);
                                    setItemChecked({});
                                    setOrdrStatus("");
                                    setInitialOrderSelection(true);
                                    setOrderNumber("");
                                    setReturnOrderType("SELECT");
                                    setReturnReason("SELECT");
                                    setReturnItem("SELECT");
                                    setOrderType("SELECT");
                                    setActualOrderType("SELECT");
                                    setCustomerAdjustedPayment("SELECT");
                                    setIsOrderAlreadyPickedUp("SELECT");
                                    setReturnedVehicleId("SELECT");
                                    setReasonDropdownDisable(false);
                                    setIsOrderPickedDropdownDisable(false);
                                    let currdate=formatOrderDate(new Date());
                                    setReturnDate(currdate);
                                    setBackDrop(false);
                                    toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                                  }
                                }
                              });
                            }
                          });
                      }).catch((reterr)=>{
                        setBackDrop(false);
                        toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
                      })
                    }else if(isOrderAlreadyPickedUp=="No"){
                      console.log(customerAdjustedPayment);
                      setBackDrop(true);
                      //amount calculations
                      let finalNetOrderAmount=0;
                      let finalOrderAmount=0;
                      let finalGstAmount=0;
                      let finalTransportAmount=0;
                      for(let k=0;k<orderData.length;k++){
                        let item_id=""+orderData[k]['item_id'];
                        if(selectedItemSet.has(item_id)){
                          let total_amt=Number(orderData[k]['total_amt']);
                          let gst_charge=Number(orderData[k]['gst_charge']);
                          let transport_charge=Number(orderData[k]['transport_charge']);
                          finalNetOrderAmount=finalNetOrderAmount+total_amt;
                          finalGstAmount=finalGstAmount+gst_charge;
                          finalTransportAmount=finalTransportAmount+transport_charge;
                        }
                      }
                      finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

                      let orderID,retailerID,walletAmt;
                      let today = new Date();
                      let transactionDate=today.toShortFormat();

                      let currTimestamp=Date.parse(new Date());
                      let fbordersnewele={};
                      let finalOrderList=JSON.parse(JSON.stringify(orderData));
                      //get only items with item_delivered_qty < order_qty
                      for(let i=0;i<finalOrderList.length;i++){
                        let item_id=""+finalOrderList[i]['item_id'];
                        let order_qty=Number(finalOrderList[i]['order_qty']);
                        if(selectedItemSet.has(item_id) && order_qty>0){
                          let ele=finalOrderList[i];
                          let order_number=finalOrderList[i]['order_number'];

                          let orderNumberList=order_number.split("/");
                          let newOrderNumber=orderNumberList[0]+"-OR/"+orderNumberList[1];
                          ele['order_number']=newOrderNumber;
                          let fbkey=orderNumberList[0]+"-OR-"+orderNumberList[1]+"-"+item_id;

                          ele['status']='Returned';

                          ele['order_date']=currTimestamp;
                          ele['exp_delivery_date']=currTimestamp;

                          if(customerAdjustedPayment=="No"){
                            ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                            ele['cash_amt']="0";
                          }else if(customerAdjustedPayment=="Yes"){
                            ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                            ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                            ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                            ele['cash_amt']="0";
                          }


                          orderID=newOrderNumber;
                          retailerID=finalOrderList[i]['retailer_id'];
                          walletAmt=""+finalOrderAmount;

                          ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                          ele['cashback_redeemed']="0";
                          ele['order_disc']="0";
                          ele['reason']=returnReason;
                          let curdate = formatDate(new Date());
                          ele['ordered_at']=curdate;
                          ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                          ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";
                          // ele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";
                          ele['order_payment_mode']="Cash on Delivery"
                          ele['credit_amt']="0"
                          ele['card_amt']="0"
                          ele['cashback_redeemed']="0"
                          ele['cheque_amt']="0"


                          delete ele['delivery_date'];
                          delete ele['delivery_date_conv'];
                          delete ele['item_delivered_qty'];
                          delete ele['processed_date'];
                          delete ele['revised_order_amt'];

                          delete ele['weight'];
                          delete ele['shop_name'];
                          delete ele['phone'];
                          delete ele['address1'];
                          delete ele['address2'];
                          delete ele['city'];
                          delete ele['pincode'];
                          delete ele['gst_number'];
                          delete ele['agent_id'];
                          delete ele['bagdetails'];
                          delete ele['numbags'];
                          fbordersnewele[`${fbkey}`]=ele;
                        }
                      }
                      console.log('fbordersnewele');
                      console.log(fbordersnewele);

                      //add in firebase
                      let orrdersNewRef=fire.database().ref('Orders_New');
                      orrdersNewRef.update(fbordersnewele,function(err){
                        if(err){
                          setBackDrop(false);
                          toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                        }else{
                          if(customerAdjustedPayment=="No"){
                            let walletele={};
                            walletele['order_id']=orderID;
                            walletele['retailer_id']=""+retailerID;
                            walletele['transaction_date']=transactionDate;
                            walletele['wallet_amt']=walletAmt;
                            let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                            let walletRef = walletMasterRef.push();
                            walletRef.set(walletele,function(errr){
                              if(errr){
                                toast("Error in adding amount to Wallet_Master : "+errr, { type: toast.TYPE.ERROR });
                              }else{
                                setOrderData([]);
                                setBackupOrderData([]);
                                setSearchOrderData([]);
                                setItemChecked({});
                                setOrdrStatus("");
                                setInitialOrderSelection(true);
                                setOrderNumber("");
                                setReturnOrderType("SELECT");
                                setReturnReason("SELECT");
                                setReturnItem("SELECT");
                                setCustomerAdjustedPayment("SELECT");
                                setIsOrderAlreadyPickedUp("SELECT");
                                setOrderType("SELECT");
                                setActualOrderType("SELECT");
                                setReturnedVehicleId("SELECT");
                                setReasonDropdownDisable(false);
                                setIsOrderPickedDropdownDisable(false);
                                let currdate=formatOrderDate(new Date());
                                setReturnDate(currdate);

                                setBackDrop(false);
                                toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                              }
                            });

                          }else{
                            setOrderData([]);
                            setBackupOrderData([]);
                            setSearchOrderData([]);
                            setItemChecked({});
                            setOrdrStatus("");
                            setInitialOrderSelection(true);
                            setOrderNumber("");
                            setReturnOrderType("SELECT");
                            setReturnReason("SELECT");
                            setReturnItem("SELECT");
                            setOrderType("SELECT");
                            setActualOrderType("SELECT");
                            setCustomerAdjustedPayment("SELECT");
                            setIsOrderAlreadyPickedUp("SELECT");
                            setReturnedVehicleId("SELECT");
                            setReasonDropdownDisable(false);
                            setIsOrderPickedDropdownDisable(false);
                            let currdate=formatOrderDate(new Date());
                            setReturnDate(currdate);
                            setBackDrop(false);
                            toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                          }
                        }
                      });
                    }
                  }
                }else{
                  toast("Please select items", { type: toast.TYPE.ERROR });
                }
              }

            });
          }
        }
      });
    }

    const placeDuplicateReturnOrder = () => {
      console.log('placeDuplicateReturnOrder');

      let bulkItemChecks = document.getElementsByClassName('bulkItemSelection');
      let selectedItemSet=new Set();
      for (let i=0;i<bulkItemChecks.length;i++) {
        let itemId=bulkItemChecks[i].name;
        let isItemChecked=bulkItemChecks[i].checked;
        if(isItemChecked==true){
          selectedItemSet.add(itemId);
        }
      }
      setOrderSelectedItemSet(selectedItemSet);
      console.log(selectedItemSet);
      console.log(selectedItemSet.size);

      let itemCount=0;
      for(let i=0;i<orderData.length;i++){
        let item_id=""+orderData[i]['item_id'];
        let order_qty=Number(orderData[i]['order_qty']);
        if(selectedItemSet.has(item_id) && order_qty>0){
          itemCount=itemCount+1;
        }
      }

      if(duplicateOrderNumber=="R"){
        if(selectedItemSet.size && itemCount>0){
          let orderstatus=orderData[0]['status'];
          if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
            toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
          }else{
            setBackDrop(true);
            let fbordersnewele={};
            let finalOrderList=JSON.parse(JSON.stringify(orderData));
            let currTimestamp=Date.parse(new Date());
            let finalOrderNumber="";

            for(let i=0;i<finalOrderList.length;i++){
              let item_id=""+finalOrderList[i]['item_id'];
              let order_qty=Number(finalOrderList[i]['order_qty']);
              if(selectedItemSet.has(item_id) && order_qty>0){
                let ele=finalOrderList[i];
                let order_number=finalOrderList[i]['order_number'];

                let orderNumberList=order_number.split("/");
                let newOrderNumber=orderNumberList[0]+"-R1/"+orderNumberList[1];
                finalOrderNumber=newOrderNumber;
                ele['order_number']=newOrderNumber;
                let fbkey=orderNumberList[0]+"-R1-"+orderNumberList[1]+"-"+item_id;
                ele['price']=ele['dealer_price'];
                ele['status']='Returned';
                ele['net_order_amt']="0";
                ele['order_date']=currTimestamp;
                ele['exp_delivery_date']=currTimestamp;
                ele['cash_amt']="0";
                ele['total_amt']="0";
                ele['net_amt']="0";
                ele['order_amt']="0";
                ele['final_order_amt']="0";
                ele['gst_charge']="0";
                ele['transport_charge']="0";

                ele['cashback_redeemed']="0";
                ele['order_disc']="0";
                ele['reason']=returnReason;
                let curdate = formatDate(new Date());
                ele['ordered_at']=curdate;
                ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                if(customerReturnedItems=="No"){
                  ele['customer_returned_items']="No";
                } else if (customerReturnedItems=="Yes"){
                  ele['customer_returned_items']="Yes";
                }
                ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                ele['order_payment_mode']="Cash on Delivery"
                ele['credit_amt']="0"
                ele['card_amt']="0"
                ele['cashback_redeemed']="0"
                ele['cheque_amt']="0"

                delete ele['delivery_date'];
                delete ele['delivery_date_conv'];
                delete ele['item_delivered_qty'];
                delete ele['processed_date'];
                delete ele['revised_order_amt'];

                delete ele['weight'];
                delete ele['shop_name'];
                delete ele['phone'];
                delete ele['address1'];
                delete ele['address2'];
                delete ele['city'];
                delete ele['pincode'];
                delete ele['gst_number'];
                delete ele['agent_id'];
                delete ele['bagdetails'];
                delete ele['numbags'];
                fbordersnewele[`${fbkey}`]=ele;
              }
            }
            console.log('fbordersnewele');
            console.log(fbordersnewele);

            let driverOrdersList=JSON.parse(JSON.stringify(orderData));
            let driverordershistoryele={};

            let year,month,date;

            let currdate=new Date();
            let day=currdate.getDate();
            month=currdate.getMonth()+1;
            year=currdate.getFullYear();
            date=day+"-"+month+'-'+year;

            let retailerid=finalOrderNumber.split("-")[0];
            let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
            console.log(apiUrl);
            fetch(apiUrl)
            .then((response) => {
                return (response['status']==200)?response.json():[];
            }).then((result) => {
                let retailerData=result['data'];
                for(let z=0;z<driverOrdersList.length;z++){
                  let item_id=""+driverOrdersList[z]['item_id'];
                  let order_qty=Number(driverOrdersList[z]['order_qty']);
                  if(selectedItemSet.has(item_id) && order_qty>0){
                    let doele=driverOrdersList[z];
                    doele['priority']=1;

                    let order_number=driverOrdersList[z]['order_number'];
                    let orderNumberList=order_number.split("/");
                    let newOrderNumber=orderNumberList[0]+"-R1/"+orderNumberList[1];

                    doele['order_number']=newOrderNumber;

                    doele['ordered_from']='web';
                    doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                    doele['status']='Delivered';
                    doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;


                    doele['order_amt']="0"
                    doele['final_order_amt']="0"
                    doele['net_order_amt']="0";
                    doele['cash_amt']="0";
                    doele['net_amt']="0";
                    doele['total_amt']="0";
                    doele['gst_charge']="0";
                    doele['transport_charge']="0";
                    if(customerReturnedItems=="No"){
                      doele['customer_returned_items']="No";
                    } else if (customerReturnedItems=="Yes"){
                      doele['customer_returned_items']="Yes";
                    }

                    doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";

                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";

                    doele['priority']=1;

                    doele['address1']=retailerData['address1']?retailerData['address1']:"";
                    doele['address2']=retailerData['address2']?retailerData['address2']:"";
                    doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                    doele['city']=retailerData['city']?retailerData['city']:"";
                    doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                    doele['phone']=retailerData['phone']?retailerData['phone']:"";
                    doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                    doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";

                    doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                    doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                    doele['asm']=retailerData['asm']?retailerData['asm']:"";
                    doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                    doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                    doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                    doele['order_payment_mode']="Cash on Delivery"
                    doele['credit_amt']="0"
                    doele['card_amt']="0"
                    doele['cashback_redeemed']="0"
                    doele['cheque_amt']="0"

                    delete doele['delivery_date'];
                    delete doele['delivery_date_conv'];
                    delete doele['processed_date'];
                    delete doele['revised_order_amt'];
                    delete doele['item_delivered_qty'];

                    let ordnum=newOrderNumber;
                    //23451-R/3564656
                    let ordnumlist=ordnum.split("/");
                    let retid=ordnumlist[0];
                    let retlist=retid.split("-");
                    let dorefstr=retlist[0]+"_R1-"+ordnumlist[1]+"-"+item_id;
                    // console.log(dorefstr);
                    let dohkey=`/${year}/${month}/${date}/9999/${dorefstr}`;
                    doele['driver_id']="9999"
                    doele['vehicle']=9999

                    driverordershistoryele[`${dohkey}`]=doele;
                  }
                }
                console.log('driverordershistoryele');
                console.log(driverordershistoryele);


                let orrdersNewRef=fire.database().ref('Orders_New');
                orrdersNewRef.update(fbordersnewele,function(err){
                  if(err){
                    toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                  }else{
                    if(customerReturnedItems!="No"){
                      let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                      driverOrdersRef.update(driverordershistoryele,function(error){
                        if (error) {
                          toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                          setBackDrop(false);
                        }else{
                            setDeliveryOrderNumber(finalOrderNumber);
                            setBackDrop(false);
                            toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                            if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                              setOrderOption("ScheduleForDelivery");
                              let status='Returned';
                              let deliveryOrdersFinalItemsList=[];
                              for(let i=0;i<orderData.length;i++){
                                let itemid=""+orderData[i]['item_id'];
                                let order_qty=Number(orderData[i]['order_qty']);
                                if(selectedItemSet.has(itemid) && order_qty>0){
                                  let itemele=orderData[i];
                                  itemele['status']=status;
                                  itemele['net_order_amt']="0"
                                  itemele['cash_amt']="0"
                                  itemele['net_amt']="0"
                                  itemele['order_amt']="0"
                                  itemele['final_order_amt']="0"
                                  itemele['cashback_redeemed']="0";
                                  itemele['order_disc']="0";
                                  itemele['total_amt']="0";
                                  itemele['gst_charge']="0";
                                  itemele['transport_charge']="0";
                                  if(customerReturnedItems=="No"){
                                    itemele['customer_returned_items']="No";
                                  } else if (customerReturnedItems=="Yes"){
                                    itemele['customer_returned_items']="Yes";
                                  }

                                  deliveryOrdersFinalItemsList.push(itemele);
                                }
                              }

                              setDeliveryOrderData(deliveryOrdersFinalItemsList);
                              setDuplicateOrderNumber("");
                            }else{
                              setOrderData([]);
                              setBackupOrderData([]);
                              setSearchOrderData([]);
                              setItemChecked({});
                              setOrdrStatus("");
                              setInitialOrderSelection(true);
                              setOrderNumber("");
                              setReturnOrderType("SELECT");
                              setReturnReason("SELECT");
                              setReturnItem("SELECT");
                              setOrderType("SELECT");
                              setActualOrderType("SELECT");
                              setCustomerAdjustedPayment("SELECT");
                              setIsOrderAlreadyPickedUp("SELECT");
                              setReturnedVehicleId("SELECT");
                              setReasonDropdownDisable(false);
                              setIsOrderPickedDropdownDisable(false);
                              setDuplicateOrderNumber("");
                            }
                        }
                      });
                    }else{
                      setDeliveryOrderNumber(finalOrderNumber);
                      setBackDrop(false);
                      toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                      if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                        setOrderOption("ScheduleForDelivery");
                        let status='Returned';
                        let deliveryOrdersFinalItemsList=[];
                        for(let i=0;i<orderData.length;i++){
                          let itemid=""+orderData[i]['item_id'];
                          let order_qty=Number(orderData[i]['order_qty']);
                          if(selectedItemSet.has(itemid) && order_qty>0){
                            let itemele=orderData[i];
                            itemele['status']=status;
                            itemele['net_order_amt']="0"
                            itemele['cash_amt']="0"
                            itemele['net_amt']="0"
                            itemele['order_amt']="0"
                            itemele['final_order_amt']="0"
                            itemele['cashback_redeemed']="0";
                            itemele['order_disc']="0";
                            itemele['total_amt']="0";
                            itemele['gst_charge']="0";
                            itemele['transport_charge']="0";
                            if(customerReturnedItems=="No"){
                              itemele['customer_returned_items']="No";
                            } else if (customerReturnedItems=="Yes"){
                              itemele['customer_returned_items']="Yes";
                            }

                            deliveryOrdersFinalItemsList.push(itemele);
                          }
                        }

                        setDeliveryOrderData(deliveryOrdersFinalItemsList);
                        setDuplicateOrderNumber("");
                      }else{
                        setOrderData([]);
                        setBackupOrderData([]);
                        setSearchOrderData([]);
                        setItemChecked({});
                        setOrdrStatus("");
                        setInitialOrderSelection(true);
                        setOrderNumber("");
                        setReturnOrderType("SELECT");
                        setReturnReason("SELECT");
                        setReturnItem("SELECT");
                        setOrderType("SELECT");
                        setActualOrderType("SELECT");
                        setCustomerAdjustedPayment("SELECT");
                        setIsOrderAlreadyPickedUp("SELECT");
                        setReturnedVehicleId("SELECT");
                        setReasonDropdownDisable(false);
                        setIsOrderPickedDropdownDisable(false);
                        setDuplicateOrderNumber("");
                      }

                    }
                  }
                });
            }).catch((reterr)=>{
              setBackDrop(false);
              toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
            })
          }
        }else{
          toast("Please select items", { type: toast.TYPE.ERROR });
        }
      }else if(duplicateOrderNumber=="R1"){
        if(selectedItemSet.size && itemCount>0){
          let orderstatus=orderData[0]['status'];
          if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
            toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
          }else{
            setBackDrop(true);
            let fbordersnewele={};
            let finalOrderList=JSON.parse(JSON.stringify(orderData));
            let currTimestamp=Date.parse(new Date());
            let finalOrderNumber="";

            for(let i=0;i<finalOrderList.length;i++){
              let item_id=""+finalOrderList[i]['item_id'];
              let order_qty=Number(finalOrderList[i]['order_qty']);
              if(selectedItemSet.has(item_id) && order_qty>0){
                let ele=finalOrderList[i];
                let order_number=finalOrderList[i]['order_number'];

                let orderNumberList=order_number.split("/");
                let newOrderNumber=orderNumberList[0]+"-R2/"+orderNumberList[1];
                finalOrderNumber=newOrderNumber;
                ele['order_number']=newOrderNumber;
                let fbkey=orderNumberList[0]+"-R2-"+orderNumberList[1]+"-"+item_id;
                ele['price']=ele['dealer_price'];
                ele['status']='Returned';
                ele['net_order_amt']="0";
                ele['order_date']=currTimestamp;
                ele['exp_delivery_date']=currTimestamp;
                ele['cash_amt']="0";
                ele['total_amt']="0";
                ele['net_amt']="0";
                ele['order_amt']="0";
                ele['final_order_amt']="0";
                ele['gst_charge']="0";
                ele['transport_charge']="0";

                ele['cashback_redeemed']="0";
                ele['order_disc']="0";
                ele['reason']=returnReason;
                let curdate = formatDate(new Date());
                ele['ordered_at']=curdate;
                ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                if(customerReturnedItems=="No"){
                  ele['customer_returned_items']="No";
                } else if (customerReturnedItems=="Yes"){
                  ele['customer_returned_items']="Yes";
                }
                ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                ele['order_payment_mode']="Cash on Delivery"
                ele['credit_amt']="0"
                ele['card_amt']="0"
                ele['cashback_redeemed']="0"
                ele['cheque_amt']="0"

                delete ele['delivery_date'];
                delete ele['delivery_date_conv'];
                delete ele['item_delivered_qty'];
                delete ele['processed_date'];
                delete ele['revised_order_amt'];

                delete ele['weight'];
                delete ele['shop_name'];
                delete ele['phone'];
                delete ele['address1'];
                delete ele['address2'];
                delete ele['city'];
                delete ele['pincode'];
                delete ele['gst_number'];
                delete ele['agent_id'];
                delete ele['bagdetails'];
                delete ele['numbags'];
                fbordersnewele[`${fbkey}`]=ele;
              }
            }
            console.log('fbordersnewele');
            console.log(fbordersnewele);

            let driverOrdersList=JSON.parse(JSON.stringify(orderData));
            let driverordershistoryele={};

            let year,month,date;

            let currdate=new Date();
            let day=currdate.getDate();
            month=currdate.getMonth()+1;
            year=currdate.getFullYear();
            date=day+"-"+month+'-'+year;

            let retailerid=finalOrderNumber.split("-")[0];
            let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
            console.log(apiUrl);
            fetch(apiUrl)
            .then((response) => {
                return (response['status']==200)?response.json():[];
            }).then((result) => {
                let retailerData=result['data'];
                for(let z=0;z<driverOrdersList.length;z++){
                  let item_id=""+driverOrdersList[z]['item_id'];
                  let order_qty=Number(driverOrdersList[z]['order_qty']);
                  if(selectedItemSet.has(item_id) && order_qty>0){
                    let doele=driverOrdersList[z];
                    doele['priority']=1;

                    let order_number=driverOrdersList[z]['order_number'];
                    let orderNumberList=order_number.split("/");
                    let newOrderNumber=orderNumberList[0]+"-R2/"+orderNumberList[1];

                    doele['order_number']=newOrderNumber;

                    doele['ordered_from']='web';
                    doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                    doele['status']='Delivered';
                    doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;


                    doele['order_amt']="0"
                    doele['final_order_amt']="0"
                    doele['net_order_amt']="0";
                    doele['cash_amt']="0";
                    doele['net_amt']="0";
                    doele['total_amt']="0";
                    doele['gst_charge']="0";
                    doele['transport_charge']="0";
                    if(customerReturnedItems=="No"){
                      doele['customer_returned_items']="No";
                    } else if (customerReturnedItems=="Yes"){
                      doele['customer_returned_items']="Yes";
                    }

                    doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";

                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";

                    doele['priority']=1;

                    doele['address1']=retailerData['address1']?retailerData['address1']:"";
                    doele['address2']=retailerData['address2']?retailerData['address2']:"";
                    doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                    doele['city']=retailerData['city']?retailerData['city']:"";
                    doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                    doele['phone']=retailerData['phone']?retailerData['phone']:"";
                    doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                    doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";

                    doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                    doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                    doele['asm']=retailerData['asm']?retailerData['asm']:"";
                    doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                    doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                    doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                    doele['order_payment_mode']="Cash on Delivery"
                    doele['credit_amt']="0"
                    doele['card_amt']="0"
                    doele['cashback_redeemed']="0"
                    doele['cheque_amt']="0"

                    delete doele['delivery_date'];
                    delete doele['delivery_date_conv'];
                    delete doele['processed_date'];
                    delete doele['revised_order_amt'];
                    delete doele['item_delivered_qty'];

                    let ordnum=newOrderNumber;
                    //23451-R/3564656
                    let ordnumlist=ordnum.split("/");
                    let retid=ordnumlist[0];
                    let retlist=retid.split("-");
                    let dorefstr=retlist[0]+"_R2-"+ordnumlist[1]+"-"+item_id;
                    // console.log(dorefstr);
                    let dohkey=`/${year}/${month}/${date}/9999/${dorefstr}`;
                    doele['driver_id']="9999"
                    doele['vehicle']=9999

                    driverordershistoryele[`${dohkey}`]=doele;
                  }
                }
                console.log('driverordershistoryele');
                console.log(driverordershistoryele);


                let orrdersNewRef=fire.database().ref('Orders_New');
                orrdersNewRef.update(fbordersnewele,function(err){
                  if(err){
                    toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                  }else{
                    if(customerReturnedItems!="No"){
                      let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                      driverOrdersRef.update(driverordershistoryele,function(error){
                        if (error) {
                          toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                          setBackDrop(false);
                        }else{
                            setDeliveryOrderNumber(finalOrderNumber);
                            setBackDrop(false);
                            toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                            if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                              setOrderOption("ScheduleForDelivery");
                              let status='Returned';
                              let deliveryOrdersFinalItemsList=[];
                              for(let i=0;i<orderData.length;i++){
                                let itemid=""+orderData[i]['item_id'];
                                let order_qty=Number(orderData[i]['order_qty']);
                                if(selectedItemSet.has(itemid) && order_qty>0){
                                  let itemele=orderData[i];
                                  itemele['status']=status;
                                  itemele['net_order_amt']="0"
                                  itemele['cash_amt']="0"
                                  itemele['net_amt']="0"
                                  itemele['order_amt']="0"
                                  itemele['final_order_amt']="0"
                                  itemele['cashback_redeemed']="0";
                                  itemele['order_disc']="0";
                                  itemele['total_amt']="0";
                                  itemele['gst_charge']="0";
                                  itemele['transport_charge']="0";
                                  if(customerReturnedItems=="No"){
                                    itemele['customer_returned_items']="No";
                                  } else if (customerReturnedItems=="Yes"){
                                    itemele['customer_returned_items']="Yes";
                                  }

                                  deliveryOrdersFinalItemsList.push(itemele);
                                }
                              }

                              setDeliveryOrderData(deliveryOrdersFinalItemsList);
                              setDuplicateOrderNumber("");
                            }else{
                              setOrderData([]);
                              setBackupOrderData([]);
                              setSearchOrderData([]);
                              setItemChecked({});
                              setOrdrStatus("");
                              setInitialOrderSelection(true);
                              setOrderNumber("");
                              setReturnOrderType("SELECT");
                              setReturnReason("SELECT");
                              setReturnItem("SELECT");
                              setOrderType("SELECT");
                              setActualOrderType("SELECT");
                              setCustomerAdjustedPayment("SELECT");
                              setIsOrderAlreadyPickedUp("SELECT");
                              setReturnedVehicleId("SELECT");
                              setReasonDropdownDisable(false);
                              setIsOrderPickedDropdownDisable(false);
                              setDuplicateOrderNumber("");
                            }
                        }
                      });
                    }else{
                      setDeliveryOrderNumber(finalOrderNumber);
                      setBackDrop(false);
                      toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                      if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                        setOrderOption("ScheduleForDelivery");
                        let status='Returned';
                        let deliveryOrdersFinalItemsList=[];
                        for(let i=0;i<orderData.length;i++){
                          let itemid=""+orderData[i]['item_id'];
                          let order_qty=Number(orderData[i]['order_qty']);
                          if(selectedItemSet.has(itemid) && order_qty>0){
                            let itemele=orderData[i];
                            itemele['status']=status;
                            itemele['net_order_amt']="0"
                            itemele['cash_amt']="0"
                            itemele['net_amt']="0"
                            itemele['order_amt']="0"
                            itemele['final_order_amt']="0"
                            itemele['cashback_redeemed']="0";
                            itemele['order_disc']="0";
                            itemele['total_amt']="0";
                            itemele['gst_charge']="0";
                            itemele['transport_charge']="0";
                            if(customerReturnedItems=="No"){
                              itemele['customer_returned_items']="No";
                            } else if (customerReturnedItems=="Yes"){
                              itemele['customer_returned_items']="Yes";
                            }

                            deliveryOrdersFinalItemsList.push(itemele);
                          }
                        }

                        setDeliveryOrderData(deliveryOrdersFinalItemsList);
                        setDuplicateOrderNumber("");
                      }else{
                        setOrderData([]);
                        setBackupOrderData([]);
                        setSearchOrderData([]);
                        setItemChecked({});
                        setOrdrStatus("");
                        setInitialOrderSelection(true);
                        setOrderNumber("");
                        setReturnOrderType("SELECT");
                        setReturnReason("SELECT");
                        setReturnItem("SELECT");
                        setOrderType("SELECT");
                        setActualOrderType("SELECT");
                        setCustomerAdjustedPayment("SELECT");
                        setIsOrderAlreadyPickedUp("SELECT");
                        setReturnedVehicleId("SELECT");
                        setReasonDropdownDisable(false);
                        setIsOrderPickedDropdownDisable(false);
                        setDuplicateOrderNumber("");
                      }

                    }
                  }
                });
            }).catch((reterr)=>{
              setBackDrop(false);
              toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
            })
          }
        }else{
          toast("Please select items", { type: toast.TYPE.ERROR });
        }
      }else if(duplicateOrderNumber=="R2"){
        if(selectedItemSet.size && itemCount>0){
          let orderstatus=orderData[0]['status'];
          if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
            toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
          }else{
            setBackDrop(true);
            let fbordersnewele={};
            let finalOrderList=JSON.parse(JSON.stringify(orderData));
            let currTimestamp=Date.parse(new Date());
            let finalOrderNumber="";

            for(let i=0;i<finalOrderList.length;i++){
              let item_id=""+finalOrderList[i]['item_id'];
              let order_qty=Number(finalOrderList[i]['order_qty']);
              if(selectedItemSet.has(item_id) && order_qty>0){
                let ele=finalOrderList[i];
                let order_number=finalOrderList[i]['order_number'];

                let orderNumberList=order_number.split("/");
                let newOrderNumber=orderNumberList[0]+"-R3/"+orderNumberList[1];
                finalOrderNumber=newOrderNumber;
                ele['order_number']=newOrderNumber;
                let fbkey=orderNumberList[0]+"-R3-"+orderNumberList[1]+"-"+item_id;
                ele['price']=ele['dealer_price'];
                ele['status']='Returned';
                ele['net_order_amt']="0";
                ele['order_date']=currTimestamp;
                ele['exp_delivery_date']=currTimestamp;
                ele['cash_amt']="0";
                ele['total_amt']="0";
                ele['net_amt']="0";
                ele['order_amt']="0";
                ele['final_order_amt']="0";
                ele['gst_charge']="0";
                ele['transport_charge']="0";

                ele['cashback_redeemed']="0";
                ele['order_disc']="0";
                ele['reason']=returnReason;
                let curdate = formatDate(new Date());
                ele['ordered_at']=curdate;
                ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                if(customerReturnedItems=="No"){
                  ele['customer_returned_items']="No";
                } else if (customerReturnedItems=="Yes"){
                  ele['customer_returned_items']="Yes";
                }
                ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                ele['order_payment_mode']="Cash on Delivery"
                ele['credit_amt']="0"
                ele['card_amt']="0"
                ele['cashback_redeemed']="0"
                ele['cheque_amt']="0"

                delete ele['delivery_date'];
                delete ele['delivery_date_conv'];
                delete ele['item_delivered_qty'];
                delete ele['processed_date'];
                delete ele['revised_order_amt'];

                delete ele['weight'];
                delete ele['shop_name'];
                delete ele['phone'];
                delete ele['address1'];
                delete ele['address2'];
                delete ele['city'];
                delete ele['pincode'];
                delete ele['gst_number'];
                delete ele['agent_id'];
                delete ele['bagdetails'];
                delete ele['numbags'];
                fbordersnewele[`${fbkey}`]=ele;
              }
            }
            console.log('fbordersnewele');
            console.log(fbordersnewele);

            let driverOrdersList=JSON.parse(JSON.stringify(orderData));
            let driverordershistoryele={};

            let year,month,date;

            let currdate=new Date();
            let day=currdate.getDate();
            month=currdate.getMonth()+1;
            year=currdate.getFullYear();
            date=day+"-"+month+'-'+year;

            let retailerid=finalOrderNumber.split("-")[0];
            let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
            console.log(apiUrl);
            fetch(apiUrl)
            .then((response) => {
                return (response['status']==200)?response.json():[];
            }).then((result) => {
                let retailerData=result['data'];
                for(let z=0;z<driverOrdersList.length;z++){
                  let item_id=""+driverOrdersList[z]['item_id'];
                  let order_qty=Number(driverOrdersList[z]['order_qty']);
                  if(selectedItemSet.has(item_id) && order_qty>0){
                    let doele=driverOrdersList[z];
                    doele['priority']=1;

                    let order_number=driverOrdersList[z]['order_number'];
                    let orderNumberList=order_number.split("/");
                    let newOrderNumber=orderNumberList[0]+"-R3/"+orderNumberList[1];

                    doele['order_number']=newOrderNumber;

                    doele['ordered_from']='web';
                    doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                    doele['status']='Delivered';
                    doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                    doele['order_date']=formatOrderDate(currTimestamp);
                    doele['exp_delivery_date']=currTimestamp;


                    doele['order_amt']="0"
                    doele['final_order_amt']="0"
                    doele['net_order_amt']="0";
                    doele['cash_amt']="0";
                    doele['net_amt']="0";
                    doele['total_amt']="0";
                    doele['gst_charge']="0";
                    doele['transport_charge']="0";
                    if(customerReturnedItems=="No"){
                      doele['customer_returned_items']="No";
                    } else if (customerReturnedItems=="Yes"){
                      doele['customer_returned_items']="Yes";
                    }

                    doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";

                    doele['cashback_redeemed']="0";
                    doele['order_disc']="0";

                    doele['priority']=1;

                    doele['address1']=retailerData['address1']?retailerData['address1']:"";
                    doele['address2']=retailerData['address2']?retailerData['address2']:"";
                    doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                    doele['city']=retailerData['city']?retailerData['city']:"";
                    doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                    doele['phone']=retailerData['phone']?retailerData['phone']:"";
                    doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                    doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";

                    doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                    doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                    doele['asm']=retailerData['asm']?retailerData['asm']:"";
                    doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                    doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                    doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                    doele['order_payment_mode']="Cash on Delivery"
                    doele['credit_amt']="0"
                    doele['card_amt']="0"
                    doele['cashback_redeemed']="0"
                    doele['cheque_amt']="0"

                    delete doele['delivery_date'];
                    delete doele['delivery_date_conv'];
                    delete doele['processed_date'];
                    delete doele['revised_order_amt'];
                    delete doele['item_delivered_qty'];

                    let ordnum=newOrderNumber;
                    //23451-R/3564656
                    let ordnumlist=ordnum.split("/");
                    let retid=ordnumlist[0];
                    let retlist=retid.split("-");
                    let dorefstr=retlist[0]+"_R3-"+ordnumlist[1]+"-"+item_id;
                    // console.log(dorefstr);
                    let dohkey=`/${year}/${month}/${date}/9999/${dorefstr}`;
                    doele['driver_id']="9999"
                    doele['vehicle']=9999

                    driverordershistoryele[`${dohkey}`]=doele;
                  }
                }
                console.log('driverordershistoryele');
                console.log(driverordershistoryele);


                let orrdersNewRef=fire.database().ref('Orders_New');
                orrdersNewRef.update(fbordersnewele,function(err){
                  if(err){
                    toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                  }else{
                    if(customerReturnedItems!="No"){
                      let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                      driverOrdersRef.update(driverordershistoryele,function(error){
                        if (error) {
                          toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                          setBackDrop(false);
                        }else{
                            setDeliveryOrderNumber(finalOrderNumber);
                            setBackDrop(false);
                            toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                            if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                              setOrderOption("ScheduleForDelivery");
                              let status='Returned';
                              let deliveryOrdersFinalItemsList=[];
                              for(let i=0;i<orderData.length;i++){
                                let itemid=""+orderData[i]['item_id'];
                                let order_qty=Number(orderData[i]['order_qty']);
                                if(selectedItemSet.has(itemid) && order_qty>0){
                                  let itemele=orderData[i];
                                  itemele['status']=status;
                                  itemele['net_order_amt']="0"
                                  itemele['cash_amt']="0"
                                  itemele['net_amt']="0"
                                  itemele['order_amt']="0"
                                  itemele['final_order_amt']="0"
                                  itemele['cashback_redeemed']="0";
                                  itemele['order_disc']="0";
                                  itemele['total_amt']="0";
                                  itemele['gst_charge']="0";
                                  itemele['transport_charge']="0";
                                  if(customerReturnedItems=="No"){
                                    itemele['customer_returned_items']="No";
                                  } else if (customerReturnedItems=="Yes"){
                                    itemele['customer_returned_items']="Yes";
                                  }

                                  deliveryOrdersFinalItemsList.push(itemele);
                                }
                              }

                              setDeliveryOrderData(deliveryOrdersFinalItemsList);
                              setDuplicateOrderNumber("");
                            }else{
                              setOrderData([]);
                              setBackupOrderData([]);
                              setSearchOrderData([]);
                              setItemChecked({});
                              setOrdrStatus("");
                              setInitialOrderSelection(true);
                              setOrderNumber("");
                              setReturnOrderType("SELECT");
                              setReturnReason("SELECT");
                              setReturnItem("SELECT");
                              setOrderType("SELECT");
                              setActualOrderType("SELECT");
                              setCustomerAdjustedPayment("SELECT");
                              setIsOrderAlreadyPickedUp("SELECT");
                              setReturnedVehicleId("SELECT");
                              setReasonDropdownDisable(false);
                              setIsOrderPickedDropdownDisable(false);
                              setDuplicateOrderNumber("");
                            }
                        }
                      });
                    }else{
                      setDeliveryOrderNumber(finalOrderNumber);
                      setBackDrop(false);
                      toast(`Successfully placed the order. OrderNumber: ${finalOrderNumber}`, { type: toast.TYPE.SUCCESS });

                      if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
                        setOrderOption("ScheduleForDelivery");
                        let status='Returned';
                        let deliveryOrdersFinalItemsList=[];
                        for(let i=0;i<orderData.length;i++){
                          let itemid=""+orderData[i]['item_id'];
                          let order_qty=Number(orderData[i]['order_qty']);
                          if(selectedItemSet.has(itemid) && order_qty>0){
                            let itemele=orderData[i];
                            itemele['status']=status;
                            itemele['net_order_amt']="0"
                            itemele['cash_amt']="0"
                            itemele['net_amt']="0"
                            itemele['order_amt']="0"
                            itemele['final_order_amt']="0"
                            itemele['cashback_redeemed']="0";
                            itemele['order_disc']="0";
                            itemele['total_amt']="0";
                            itemele['gst_charge']="0";
                            itemele['transport_charge']="0";
                            if(customerReturnedItems=="No"){
                              itemele['customer_returned_items']="No";
                            } else if (customerReturnedItems=="Yes"){
                              itemele['customer_returned_items']="Yes";
                            }

                            deliveryOrdersFinalItemsList.push(itemele);
                          }
                        }

                        setDeliveryOrderData(deliveryOrdersFinalItemsList);
                        setDuplicateOrderNumber("");
                      }else{
                        setOrderData([]);
                        setBackupOrderData([]);
                        setSearchOrderData([]);
                        setItemChecked({});
                        setOrdrStatus("");
                        setInitialOrderSelection(true);
                        setOrderNumber("");
                        setReturnOrderType("SELECT");
                        setReturnReason("SELECT");
                        setReturnItem("SELECT");
                        setOrderType("SELECT");
                        setActualOrderType("SELECT");
                        setCustomerAdjustedPayment("SELECT");
                        setIsOrderAlreadyPickedUp("SELECT");
                        setReturnedVehicleId("SELECT");
                        setReasonDropdownDisable(false);
                        setIsOrderPickedDropdownDisable(false);
                        setDuplicateOrderNumber("");
                      }

                    }
                  }
                });
            }).catch((reterr)=>{
              setBackDrop(false);
              toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
            })
          }
        }else{
          toast("Please select items", { type: toast.TYPE.ERROR });
        }
      }else if(duplicateOrderNumber=="OR"){
        if(selectedItemSet.size && itemCount>0){
          let orderstatus=orderData[0]['status'];
          if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
            toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
          // }else if(customerAdjustedPayment=="No" && !validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
          //   toast("Please contact Admin to place the ReturnOrder.", { type: toast.TYPE.ERROR });
          }else{
            if(isOrderAlreadyPickedUp=="Yes"){
              console.log(customerAdjustedPayment);
              setBackDrop(true);
              //amount calculations
              let finalNetOrderAmount=0;
              let finalOrderAmount=0;
              let finalGstAmount=0;
              let finalTransportAmount=0;
              for(let k=0;k<orderData.length;k++){
                let item_id=""+orderData[k]['item_id'];
                if(selectedItemSet.has(item_id)){
                  let total_amt=Number(orderData[k]['total_amt']);
                  let gst_charge=Number(orderData[k]['gst_charge']);
                  let transport_charge=Number(orderData[k]['transport_charge']);
                  finalNetOrderAmount=finalNetOrderAmount+total_amt;
                  finalGstAmount=finalGstAmount+gst_charge;
                  finalTransportAmount=finalTransportAmount+transport_charge;
                }
              }
              finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

              let orderID,retailerID,walletAmt;
              let today = new Date();
              let transactionDate=today.toShortFormat();

              let currTimestamp=Date.parse(new Date());
              let fbordersnewele={};
              let finalOrderList=JSON.parse(JSON.stringify(orderData));
              //get only items with item_delivered_qty < order_qty
              for(let i=0;i<finalOrderList.length;i++){
                let item_id=""+finalOrderList[i]['item_id'];
                let order_qty=Number(finalOrderList[i]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let ele=finalOrderList[i];
                  let order_number=finalOrderList[i]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-OR1/"+orderNumberList[1];
                  ele['order_number']=newOrderNumber;
                  let fbkey=orderNumberList[0]+"-OR1-"+orderNumberList[1]+"-"+item_id;

                  ele['status']='Returned';

                  ele['order_date']=currTimestamp;
                  ele['exp_delivery_date']=currTimestamp;
                  let price = (Number(finalOrderList[i]['total_amt']) /Number(finalOrderList[i]['order_qty'])) * Number(finalOrderList[i]['order_qty']);
                  ele['price'] = price.toString();

                  if(customerAdjustedPayment=="No"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }else if(customerAdjustedPayment=="Yes"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }


                  orderID=newOrderNumber;
                  retailerID=finalOrderList[i]['retailer_id'];
                  walletAmt=""+finalOrderAmount;

                  ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                  ele['cashback_redeemed']="0";
                  ele['order_disc']="0";
                  ele['reason']=returnReason;
                  let curdate = formatDate(new Date());
                  ele['ordered_at']=curdate;
                  ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";
                  // ele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";
                  ele['order_payment_mode']="Cash on Delivery"
                  ele['credit_amt']="0"
                  ele['card_amt']="0"
                  ele['cashback_redeemed']="0"
                  ele['cheque_amt']="0"


                  delete ele['delivery_date'];
                  delete ele['delivery_date_conv'];
                  delete ele['item_delivered_qty'];
                  delete ele['processed_date'];
                  delete ele['revised_order_amt'];

                  delete ele['weight'];
                  delete ele['shop_name'];
                  delete ele['phone'];
                  delete ele['address1'];
                  delete ele['address2'];
                  delete ele['city'];
                  delete ele['pincode'];
                  delete ele['gst_number'];
                  delete ele['agent_id'];
                  delete ele['bagdetails'];
                  delete ele['numbags'];
                  fbordersnewele[`${fbkey}`]=ele;
                }
              }
              console.log('fbordersnewele');
              console.log(fbordersnewele);

              let driverOrdersList=JSON.parse(JSON.stringify(orderData));
              let driverordershistoryele={};

              let year,month,date;

              if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
                let currdate=new Date(returnDate);
                let day=currdate.getDate();
                month=currdate.getMonth()+1;
                year=currdate.getFullYear();
                date=day+"-"+month+'-'+year;
              }else{
                let currdate=new Date();
                let day=currdate.getDate();
                month=currdate.getMonth()+1;
                year=currdate.getFullYear();
                date=day+"-"+month+'-'+year;
              }

              let retailerid=orderID.split("-")[0];
              let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
              console.log(apiUrl);
              fetch(apiUrl)
              .then((response) => {
                  return (response['status']==200)?response.json():[];
              }).then((result) => {
                  let retailerData=result['data'];

                  for(let z=0;z<driverOrdersList.length;z++){
                    let item_id=""+driverOrdersList[z]['item_id'];
                    let order_qty=Number(driverOrdersList[z]['order_qty']);
                    if(selectedItemSet.has(item_id) && order_qty>0){
                      let doele=driverOrdersList[z];
                      doele['priority']=1;

                      let order_number=driverOrdersList[z]['order_number'];

                      let orderNumberList=order_number.split("/");
                      let newOrderNumber=orderNumberList[0]+"-OR1/"+orderNumberList[1];
                      let fbkey=orderNumberList[0]+"_OR1-"+orderNumberList[1]+"-"+item_id;

                      doele['order_number']=newOrderNumber;

                      doele['ordered_from']='web';
                      doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                      doele['status']='Delivered';
                      doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                      if(newOrderNumber.includes("-OR")){
                        // doele['status']="Returned";
                        doele['status']="Processed";
                      }

                      doele['order_date']=formatOrderDate(currTimestamp);
                      doele['exp_delivery_date']=currTimestamp;

                      doele['variety'] = driverOrdersList[z].variety;
                      doele['gst_per'] = driverOrdersList[z].gst_per;
                      doele['brand'] = driverOrdersList[z].brand;
                      doele['image_url'] = driverOrdersList[z].image_url;
                      let price = (Number(driverOrdersList[z]['total_amt']) /Number(driverOrdersList[z]['order_qty'])) * Number(driverOrdersList[z]['order_qty']);
                      doele['price'] = price.toString();

                      if(customerAdjustedPayment=="No"){
                        doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                        doele['cash_amt']="0";
                      }else if(customerAdjustedPayment=="Yes"){
                        doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                        doele['cash_amt']="0";
                      }

                      doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                      doele['cashback_redeemed']="0";
                      doele['order_disc']="0";
                      // doele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";

                      doele['address1']=retailerData['address1']?retailerData['address1']:"";
                      doele['address2']=retailerData['address2']?retailerData['address2']:"";
                      doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                      doele['city']=driverOrdersList[z]['city']?driverOrdersList[z]['city']:"";
                      doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                      doele['phone']=retailerData['phone']?retailerData['phone']:"";
                      doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                      doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                      doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                      doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                      doele['asm']=retailerData['asm']?retailerData['asm']:"";
                      doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                      doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                      doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                      doele['order_payment_mode']="Cash on Delivery"
                      doele['credit_amt']="0"
                      doele['card_amt']="0"
                      doele['cashback_redeemed']="0"
                      doele['cheque_amt']="0";
                      doele['gst_charge']=""+driverOrdersList[z]['gst_charge'];
                      doele['apmc_charge']=""+driverOrdersList[z]['apmc_charge'];

                      delete doele['delivery_date'];
                      delete doele['delivery_date_conv'];
                      delete doele['processed_date'];
                      delete doele['revised_order_amt'];
                      delete doele['item_delivered_qty'];

                      let dohkey=`/${year}/${month}/${date}/${returnedVehicleId}/${fbkey}`;

                      driverordershistoryele[`${dohkey}`]=doele;
                    }
                  }
                  console.log('driverordershistoryele');
                  console.log(driverordershistoryele);

                  //add in firebase
                  let orrdersNewRef=fire.database().ref('Orders_New');
                  orrdersNewRef.update(fbordersnewele,function(err){
                    if(err){
                      setBackDrop(false);
                      toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                    }else{
                      let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                      driverOrdersRef.update(driverordershistoryele,function(error){
                        if (error) {
                          setBackDrop(false);
                          toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                        }else{
                          if(customerAdjustedPayment=="No"){
                            let walletele={};
                            walletele['order_id']=orderID;
                            walletele['retailer_id']=""+retailerID;
                            walletele['transaction_date']=transactionDate;
                            walletele['wallet_amt']=walletAmt;
                            let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                            let walletRef = walletMasterRef.push();
                            walletRef.set(walletele,function(errr){
                              if(errr){
                                toast("Error in adding amount to Wallet_Master : "+errr, { type: toast.TYPE.ERROR });
                              }else{
                                setOrderData([]);
                                setBackupOrderData([]);
                                setSearchOrderData([]);
                                setItemChecked({});
                                setOrdrStatus("");
                                setInitialOrderSelection(true);
                                setOrderNumber("");
                                setReturnOrderType("SELECT");
                                setReturnReason("SELECT");
                                setReturnItem("SELECT");
                                setCustomerAdjustedPayment("SELECT");
                                setIsOrderAlreadyPickedUp("SELECT");
                                setOrderType("SELECT");
                                setActualOrderType("SELECT");
                                setReturnedVehicleId("SELECT");
                                setReasonDropdownDisable(false);
                                setIsOrderPickedDropdownDisable(false);
                                let currdate=formatOrderDate(new Date());
                                setReturnDate(currdate);
                                setDuplicateOrderNumber("");

                                setBackDrop(false);
                                toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                              }
                            });

                          }else{
                            setOrderData([]);
                            setBackupOrderData([]);
                            setSearchOrderData([]);
                            setItemChecked({});
                            setOrdrStatus("");
                            setInitialOrderSelection(true);
                            setOrderNumber("");
                            setReturnOrderType("SELECT");
                            setReturnReason("SELECT");
                            setReturnItem("SELECT");
                            setOrderType("SELECT");
                            setActualOrderType("SELECT");
                            setCustomerAdjustedPayment("SELECT");
                            setIsOrderAlreadyPickedUp("SELECT");
                            setReturnedVehicleId("SELECT");
                            setReasonDropdownDisable(false);
                            setIsOrderPickedDropdownDisable(false);
                            let currdate=formatOrderDate(new Date());
                            setReturnDate(currdate);
                            setDuplicateOrderNumber("");
                            setBackDrop(false);
                            toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                          }
                        }
                      });
                    }
                  });
              }).catch((reterr)=>{
                setBackDrop(false);
                toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
              })
            }else if(isOrderAlreadyPickedUp=="No"){
              console.log(customerAdjustedPayment);
              setBackDrop(true);
              //amount calculations
              let finalNetOrderAmount=0;
              let finalOrderAmount=0;
              let finalGstAmount=0;
              let finalTransportAmount=0;
              for(let k=0;k<orderData.length;k++){
                let item_id=""+orderData[k]['item_id'];
                if(selectedItemSet.has(item_id)){
                  let total_amt=Number(orderData[k]['total_amt']);
                  let gst_charge=Number(orderData[k]['gst_charge']);
                  let transport_charge=Number(orderData[k]['transport_charge']);
                  finalNetOrderAmount=finalNetOrderAmount+total_amt;
                  finalGstAmount=finalGstAmount+gst_charge;
                  finalTransportAmount=finalTransportAmount+transport_charge;
                }
              }
              finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

              let orderID,retailerID,walletAmt;
              let today = new Date();
              let transactionDate=today.toShortFormat();

              let currTimestamp=Date.parse(new Date());
              let fbordersnewele={};
              let finalOrderList=JSON.parse(JSON.stringify(orderData));
              //get only items with item_delivered_qty < order_qty
              for(let i=0;i<finalOrderList.length;i++){
                let item_id=""+finalOrderList[i]['item_id'];
                let order_qty=Number(finalOrderList[i]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let ele=finalOrderList[i];
                  let order_number=finalOrderList[i]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-OR1/"+orderNumberList[1];
                  ele['order_number']=newOrderNumber;
                  let fbkey=orderNumberList[0]+"-OR1-"+orderNumberList[1]+"-"+item_id;

                  ele['status']='Returned';

                  ele['order_date']=currTimestamp;
                  ele['exp_delivery_date']=currTimestamp;

                  if(customerAdjustedPayment=="No"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }else if(customerAdjustedPayment=="Yes"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }


                  orderID=newOrderNumber;
                  retailerID=finalOrderList[i]['retailer_id'];
                  walletAmt=""+finalOrderAmount;

                  ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                  ele['cashback_redeemed']="0";
                  ele['order_disc']="0";
                  ele['reason']=returnReason;
                  let curdate = formatDate(new Date());
                  ele['ordered_at']=curdate;
                  ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";
                  // ele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";
                  ele['order_payment_mode']="Cash on Delivery"
                  ele['credit_amt']="0"
                  ele['card_amt']="0"
                  ele['cashback_redeemed']="0"
                  ele['cheque_amt']="0"


                  delete ele['delivery_date'];
                  delete ele['delivery_date_conv'];
                  delete ele['item_delivered_qty'];
                  delete ele['processed_date'];
                  delete ele['revised_order_amt'];

                  delete ele['weight'];
                  delete ele['shop_name'];
                  delete ele['phone'];
                  delete ele['address1'];
                  delete ele['address2'];
                  delete ele['city'];
                  delete ele['pincode'];
                  delete ele['gst_number'];
                  delete ele['agent_id'];
                  delete ele['bagdetails'];
                  delete ele['numbags'];
                  fbordersnewele[`${fbkey}`]=ele;
                }
              }
              console.log('fbordersnewele');
              console.log(fbordersnewele);

              //add in firebase
              let orrdersNewRef=fire.database().ref('Orders_New');
              orrdersNewRef.update(fbordersnewele,function(err){
                if(err){
                  setBackDrop(false);
                  toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                }else{
                  if(customerAdjustedPayment=="No"){
                    let walletele={};
                    walletele['order_id']=orderID;
                    walletele['retailer_id']=""+retailerID;
                    walletele['transaction_date']=transactionDate;
                    walletele['wallet_amt']=walletAmt;
                    let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                    let walletRef = walletMasterRef.push();
                    walletRef.set(walletele,function(errr){
                      if(errr){
                        toast("Error in adding amount to Wallet_Master : "+errr, { type: toast.TYPE.ERROR });
                      }else{
                        setOrderData([]);
                        setBackupOrderData([]);
                        setSearchOrderData([]);
                        setItemChecked({});
                        setOrdrStatus("");
                        setInitialOrderSelection(true);
                        setOrderNumber("");
                        setReturnOrderType("SELECT");
                        setReturnReason("SELECT");
                        setReturnItem("SELECT");
                        setCustomerAdjustedPayment("SELECT");
                        setIsOrderAlreadyPickedUp("SELECT");
                        setOrderType("SELECT");
                        setActualOrderType("SELECT");
                        setReturnedVehicleId("SELECT");
                        setReasonDropdownDisable(false);
                        setIsOrderPickedDropdownDisable(false);
                        let currdate=formatOrderDate(new Date());
                        setReturnDate(currdate);
                        setDuplicateOrderNumber("");

                        setBackDrop(false);
                        toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                      }
                    });

                  }else{
                    setOrderData([]);
                    setBackupOrderData([]);
                    setSearchOrderData([]);
                    setItemChecked({});
                    setOrdrStatus("");
                    setInitialOrderSelection(true);
                    setOrderNumber("");
                    setReturnOrderType("SELECT");
                    setReturnReason("SELECT");
                    setReturnItem("SELECT");
                    setOrderType("SELECT");
                    setActualOrderType("SELECT");
                    setCustomerAdjustedPayment("SELECT");
                    setIsOrderAlreadyPickedUp("SELECT");
                    setReturnedVehicleId("SELECT");
                    setReasonDropdownDisable(false);
                    setIsOrderPickedDropdownDisable(false);
                    let currdate=formatOrderDate(new Date());
                    setReturnDate(currdate);
                    setDuplicateOrderNumber("");
                    setBackDrop(false);
                    toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                  }
                }
              });
            }
          }
        }else{
          toast("Please select items", { type: toast.TYPE.ERROR });
        }
      }else if(duplicateOrderNumber=="OR1"){
        if(selectedItemSet.size && itemCount>0){
          let orderstatus=orderData[0]['status'];
          if(orderstatus!="Partially-Delivered" && orderstatus!="Delivered"){
            toast("Non-Delivered Orders are not applicable for Returns", { type: toast.TYPE.ERROR });
          // }else if(customerAdjustedPayment=="No" && !validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
          //   toast("Please contact Admin to place the ReturnOrder.", { type: toast.TYPE.ERROR });
          }else{
            if(isOrderAlreadyPickedUp=="Yes"){
              console.log(customerAdjustedPayment);
              setBackDrop(true);
              //amount calculations
              let finalNetOrderAmount=0;
              let finalOrderAmount=0;
              let finalGstAmount=0;
              let finalTransportAmount=0;
              for(let k=0;k<orderData.length;k++){
                let item_id=""+orderData[k]['item_id'];
                if(selectedItemSet.has(item_id)){
                  let total_amt=Number(orderData[k]['total_amt']);
                  let gst_charge=Number(orderData[k]['gst_charge']);
                  let transport_charge=Number(orderData[k]['transport_charge']);
                  finalNetOrderAmount=finalNetOrderAmount+total_amt;
                  finalGstAmount=finalGstAmount+gst_charge;
                  finalTransportAmount=finalTransportAmount+transport_charge;
                }
              }
              finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

              let orderID,retailerID,walletAmt;
              let today = new Date();
              let transactionDate=today.toShortFormat();

              let currTimestamp=Date.parse(new Date());
              let fbordersnewele={};
              let finalOrderList=JSON.parse(JSON.stringify(orderData));
              //get only items with item_delivered_qty < order_qty
              for(let i=0;i<finalOrderList.length;i++){
                let item_id=""+finalOrderList[i]['item_id'];
                let order_qty=Number(finalOrderList[i]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let ele=finalOrderList[i];
                  let order_number=finalOrderList[i]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-OR2/"+orderNumberList[1];
                  ele['order_number']=newOrderNumber;
                  let fbkey=orderNumberList[0]+"-OR2-"+orderNumberList[1]+"-"+item_id;

                  ele['status']='Returned';

                  ele['order_date']=currTimestamp;
                  ele['exp_delivery_date']=currTimestamp;

                  if(customerAdjustedPayment=="No"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }else if(customerAdjustedPayment=="Yes"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }


                  orderID=newOrderNumber;
                  retailerID=finalOrderList[i]['retailer_id'];
                  walletAmt=""+finalOrderAmount;

                  ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                  ele['cashback_redeemed']="0";
                  ele['order_disc']="0";
                  ele['reason']=returnReason;
                  let curdate = formatDate(new Date());
                  ele['ordered_at']=curdate;
                  ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";
                  // ele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";
                  ele['order_payment_mode']="Cash on Delivery"
                  ele['credit_amt']="0"
                  ele['card_amt']="0"
                  ele['cashback_redeemed']="0"
                  ele['cheque_amt']="0"


                  delete ele['delivery_date'];
                  delete ele['delivery_date_conv'];
                  delete ele['item_delivered_qty'];
                  delete ele['processed_date'];
                  delete ele['revised_order_amt'];

                  delete ele['weight'];
                  delete ele['shop_name'];
                  delete ele['phone'];
                  delete ele['address1'];
                  delete ele['address2'];
                  delete ele['city'];
                  delete ele['pincode'];
                  delete ele['gst_number'];
                  delete ele['agent_id'];
                  delete ele['bagdetails'];
                  delete ele['numbags'];
                  fbordersnewele[`${fbkey}`]=ele;
                }
              }
              console.log('fbordersnewele');
              console.log(fbordersnewele);

              let driverOrdersList=JSON.parse(JSON.stringify(orderData));
              let driverordershistoryele={};

              let year,month,date;

              if(validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)){
                let currdate=new Date(returnDate);
                let day=currdate.getDate();
                month=currdate.getMonth()+1;
                year=currdate.getFullYear();
                date=day+"-"+month+'-'+year;
              }else{
                let currdate=new Date();
                let day=currdate.getDate();
                month=currdate.getMonth()+1;
                year=currdate.getFullYear();
                date=day+"-"+month+'-'+year;
              }

              let retailerid=orderID.split("-")[0];
              let apiUrl=`${support_portal_url}/api/fetchretailerdata?retailerid=${retailerid}&token=${sessionStorage.getItem("apiToken")}`
              console.log(apiUrl);
              fetch(apiUrl)
              .then((response) => {
                  return (response['status']==200)?response.json():[];
              }).then((result) => {
                  let retailerData=result['data'];

                  for(let z=0;z<driverOrdersList.length;z++){
                    let item_id=""+driverOrdersList[z]['item_id'];
                    let order_qty=Number(driverOrdersList[z]['order_qty']);
                    if(selectedItemSet.has(item_id) && order_qty>0){
                      let doele=driverOrdersList[z];
                      doele['priority']=1;

                      let order_number=driverOrdersList[z]['order_number'];

                      let orderNumberList=order_number.split("/");
                      let newOrderNumber=orderNumberList[0]+"-OR2/"+orderNumberList[1];
                      let fbkey=orderNumberList[0]+"_OR2-"+orderNumberList[1]+"-"+item_id;

                      doele['order_number']=newOrderNumber;

                      doele['ordered_from']='web';
                      doele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                      doele['status']='Delivered';
                      doele['item_returned_qty']=""+driverOrdersList[z]['order_qty'];

                      doele['order_date']=formatOrderDate(currTimestamp);
                      doele['exp_delivery_date']=currTimestamp;

                      if(customerAdjustedPayment=="No"){
                        doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                        doele['cash_amt']="0";
                      }else if(customerAdjustedPayment=="Yes"){
                        doele['order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                        doele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                        doele['total_amt']="-"+driverOrdersList[z]['total_amt'];
                        doele['cash_amt']="0";
                      }

                      doele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                      doele['cashback_redeemed']="0";
                      doele['order_disc']="0";
                      // doele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";

                      doele['address1']=retailerData['address1']?retailerData['address1']:"";
                      doele['address2']=retailerData['address2']?retailerData['address2']:"";
                      doele['agent_id']=retailerData['agent_id']?retailerData['agent_id']:"";
                      doele['city']=driverOrdersList[z]['city']?driverOrdersList[z]['city']:"";
                      doele['gst_number']=retailerData['gst_number']?retailerData['gst_number']:"";
                      doele['phone']=retailerData['phone']?retailerData['phone']:"";
                      doele['pincode']=retailerData['pincode']?retailerData['pincode']:"";
                      doele['shop_name']=retailerData['shop_name']?retailerData['shop_name']:"";
                      doele['latitude']=retailerData['latitude']?retailerData['latitude']:"";
                      doele['longitude']=retailerData['longitude']?retailerData['longitude']:"";
                      doele['asm']=retailerData['asm']?retailerData['asm']:"";
                      doele['asmphone']=retailerData['asmphone']?retailerData['asmphone']:"";
                      doele['salesman_name']=retailerData['salesman_name']?retailerData['salesman_name']:"";
                      doele['se_phone_number']=retailerData['se_phone_number']?retailerData['se_phone_number']:"";
                      doele['order_payment_mode']="Cash on Delivery"
                      doele['credit_amt']="0"
                      doele['card_amt']="0"
                      doele['cashback_redeemed']="0"
                      doele['cheque_amt']="0";
                      doele['gst_charge']=""+driverOrdersList[z]['gst_charge'];
                      doele['apmc_charge']=""+driverOrdersList[z]['apmc_charge'];

                      delete doele['delivery_date'];
                      delete doele['delivery_date_conv'];
                      delete doele['processed_date'];
                      delete doele['revised_order_amt'];
                      delete doele['item_delivered_qty'];

                      let dohkey=`/${year}/${month}/${date}/9999/${fbkey}`;

                      driverordershistoryele[`${dohkey}`]=doele;
                    }
                  }
                  console.log('driverordershistoryele');
                  console.log(driverordershistoryele);

                  //add in firebase
                  let orrdersNewRef=fire.database().ref('Orders_New');
                  orrdersNewRef.update(fbordersnewele,function(err){
                    if(err){
                      setBackDrop(false);
                      toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                    }else{
                      let driverOrdersRef = fire.database().ref('Driver_Orders_History');
                      driverOrdersRef.update(driverordershistoryele,function(error){
                        if (error) {
                          setBackDrop(false);
                          toast('Error in placing order in Driver_Orders_History : '+error, { type: toast.TYPE.ERROR });
                        }else{
                          if(customerAdjustedPayment=="No"){
                            let walletele={};
                            walletele['order_id']=orderID;
                            walletele['retailer_id']=""+retailerID;
                            walletele['transaction_date']=transactionDate;
                            walletele['wallet_amt']=walletAmt;
                            let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                            let walletRef = walletMasterRef.push();
                            walletRef.set(walletele,function(errr){
                              if(errr){
                                toast("Error in adding amount to Wallet_Master : "+errr, { type: toast.TYPE.ERROR });
                              }else{
                                setOrderData([]);
                                setBackupOrderData([]);
                                setSearchOrderData([]);
                                setItemChecked({});
                                setOrdrStatus("");
                                setInitialOrderSelection(true);
                                setOrderNumber("");
                                setReturnOrderType("SELECT");
                                setReturnReason("SELECT");
                                setReturnItem("SELECT");
                                setCustomerAdjustedPayment("SELECT");
                                setIsOrderAlreadyPickedUp("SELECT");
                                setOrderType("SELECT");
                                setActualOrderType("SELECT");
                                setReturnedVehicleId("SELECT");
                                setReasonDropdownDisable(false);
                                setIsOrderPickedDropdownDisable(false);
                                let currdate=formatOrderDate(new Date());
                                setReturnDate(currdate);
                                setDuplicateOrderNumber("");

                                setBackDrop(false);
                                toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                              }
                            });

                          }else{
                            setOrderData([]);
                            setBackupOrderData([]);
                            setSearchOrderData([]);
                            setItemChecked({});
                            setOrdrStatus("");
                            setInitialOrderSelection(true);
                            setOrderNumber("");
                            setReturnOrderType("SELECT");
                            setReturnReason("SELECT");
                            setReturnItem("SELECT");
                            setOrderType("SELECT");
                            setActualOrderType("SELECT");
                            setCustomerAdjustedPayment("SELECT");
                            setIsOrderAlreadyPickedUp("SELECT");
                            setReturnedVehicleId("SELECT");
                            setReasonDropdownDisable(false);
                            setIsOrderPickedDropdownDisable(false);
                            let currdate=formatOrderDate(new Date());
                            setReturnDate(currdate);
                            setDuplicateOrderNumber("");
                            setBackDrop(false);
                            toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                          }
                        }
                      });
                    }
                  });
              }).catch((reterr)=>{
                setBackDrop(false);
                toast("Error in fetching retailer data : "+reterr, { type: toast.TYPE.ERROR });
              })
            }else if(isOrderAlreadyPickedUp=="No"){
              console.log(customerAdjustedPayment);
              setBackDrop(true);
              //amount calculations
              let finalNetOrderAmount=0;
              let finalOrderAmount=0;
              let finalGstAmount=0;
              let finalTransportAmount=0;
              for(let k=0;k<orderData.length;k++){
                let item_id=""+orderData[k]['item_id'];
                if(selectedItemSet.has(item_id)){
                  let total_amt=Number(orderData[k]['total_amt']);
                  let gst_charge=Number(orderData[k]['gst_charge']);
                  let transport_charge=Number(orderData[k]['transport_charge']);
                  finalNetOrderAmount=finalNetOrderAmount+total_amt;
                  finalGstAmount=finalGstAmount+gst_charge;
                  finalTransportAmount=finalTransportAmount+transport_charge;
                }
              }
              finalOrderAmount=finalNetOrderAmount-(finalGstAmount+finalTransportAmount);

              let orderID,retailerID,walletAmt;
              let today = new Date();
              let transactionDate=today.toShortFormat();

              let currTimestamp=Date.parse(new Date());
              let fbordersnewele={};
              let finalOrderList=JSON.parse(JSON.stringify(orderData));
              //get only items with item_delivered_qty < order_qty
              for(let i=0;i<finalOrderList.length;i++){
                let item_id=""+finalOrderList[i]['item_id'];
                let order_qty=Number(finalOrderList[i]['order_qty']);
                if(selectedItemSet.has(item_id) && order_qty>0){
                  let ele=finalOrderList[i];
                  let order_number=finalOrderList[i]['order_number'];

                  let orderNumberList=order_number.split("/");
                  let newOrderNumber=orderNumberList[0]+"-OR2/"+orderNumberList[1];
                  ele['order_number']=newOrderNumber;
                  let fbkey=orderNumberList[0]+"-OR2-"+orderNumberList[1]+"-"+item_id;

                  ele['status']='Returned';

                  ele['order_date']=currTimestamp;
                  ele['exp_delivery_date']=currTimestamp;

                  if(customerAdjustedPayment=="No"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }else if(customerAdjustedPayment=="Yes"){
                    ele['order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['final_order_amt']="-"+finalOrderAmount.toFixed(2);
                    ele['net_order_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['net_amt']="-"+finalNetOrderAmount.toFixed(2);
                    ele['total_amt']="-"+finalOrderList[i]['total_amt'];
                    ele['cash_amt']="0";
                  }


                  orderID=newOrderNumber;
                  retailerID=finalOrderList[i]['retailer_id'];
                  walletAmt=""+finalOrderAmount;

                  ele['returned_from']=returnedVehicleId!="SELECT"?returnedVehicleId:"";
                  ele['cashback_redeemed']="0";
                  ele['order_disc']="0";
                  ele['reason']=returnReason;
                  let curdate = formatDate(new Date());
                  ele['ordered_at']=curdate;
                  ele['ordered_by']=JSON.parse(sessionStorage.getItem('user'))[0]['email'];
                  ele['customer_adjusted_payment']=customerAdjustedPayment!="SELECT"?customerAdjustedPayment:"";
                  // ele['order_picked_up']=isOrderAlreadyPickedUp!="SELECT"?isOrderAlreadyPickedUp:"";
                  ele['order_payment_mode']="Cash on Delivery"
                  ele['credit_amt']="0"
                  ele['card_amt']="0"
                  ele['cashback_redeemed']="0"
                  ele['cheque_amt']="0"


                  delete ele['delivery_date'];
                  delete ele['delivery_date_conv'];
                  delete ele['item_delivered_qty'];
                  delete ele['processed_date'];
                  delete ele['revised_order_amt'];

                  delete ele['weight'];
                  delete ele['shop_name'];
                  delete ele['phone'];
                  delete ele['address1'];
                  delete ele['address2'];
                  delete ele['city'];
                  delete ele['pincode'];
                  delete ele['gst_number'];
                  delete ele['agent_id'];
                  delete ele['bagdetails'];
                  delete ele['numbags'];
                  fbordersnewele[`${fbkey}`]=ele;
                }
              }
              console.log('fbordersnewele');
              console.log(fbordersnewele);

              //add in firebase
              let orrdersNewRef=fire.database().ref('Orders_New');
              orrdersNewRef.update(fbordersnewele,function(err){
                if(err){
                  setBackDrop(false);
                  toast("Error in placing order in Orders_New : "+err, { type: toast.TYPE.ERROR });
                }else{
                  if(customerAdjustedPayment=="No"){
                    let walletele={};
                    walletele['order_id']=orderID;
                    walletele['retailer_id']=""+retailerID;
                    walletele['transaction_date']=transactionDate;
                    walletele['wallet_amt']=walletAmt;
                    let walletMasterRef=fire.database().ref(`Wallet_Master/${retailerID}/`);
                    let walletRef = walletMasterRef.push();
                    walletRef.set(walletele,function(errr){
                      if(errr){
                        toast("Error in adding amount to Wallet_Master : "+errr, { type: toast.TYPE.ERROR });
                      }else{
                        setOrderData([]);
                        setBackupOrderData([]);
                        setSearchOrderData([]);
                        setItemChecked({});
                        setOrdrStatus("");
                        setInitialOrderSelection(true);
                        setOrderNumber("");
                        setReturnOrderType("SELECT");
                        setReturnReason("SELECT");
                        setReturnItem("SELECT");
                        setCustomerAdjustedPayment("SELECT");
                        setIsOrderAlreadyPickedUp("SELECT");
                        setOrderType("SELECT");
                        setActualOrderType("SELECT");
                        setReturnedVehicleId("SELECT");
                        setReasonDropdownDisable(false);
                        setIsOrderPickedDropdownDisable(false);
                        let currdate=formatOrderDate(new Date());
                        setReturnDate(currdate);
                        setDuplicateOrderNumber("");

                        setBackDrop(false);
                        toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                      }
                    });

                  }else{
                    setOrderData([]);
                    setBackupOrderData([]);
                    setSearchOrderData([]);
                    setItemChecked({});
                    setOrdrStatus("");
                    setInitialOrderSelection(true);
                    setOrderNumber("");
                    setReturnOrderType("SELECT");
                    setReturnReason("SELECT");
                    setReturnItem("SELECT");
                    setOrderType("SELECT");
                    setActualOrderType("SELECT");
                    setCustomerAdjustedPayment("SELECT");
                    setIsOrderAlreadyPickedUp("SELECT");
                    setReturnedVehicleId("SELECT");
                    setReasonDropdownDisable(false);
                    setIsOrderPickedDropdownDisable(false);
                    let currdate=formatOrderDate(new Date());
                    setReturnDate(currdate);
                    setDuplicateOrderNumber("");
                    setBackDrop(false);
                    toast(`Successfully placed the order. OrderNumber: ${orderID}`, { type: toast.TYPE.SUCCESS });
                  }
                }
              });
            }
          }
        }else{
          toast("Please select items", { type: toast.TYPE.ERROR });
        }
      }
    }



    return (
      <>
      <NavBar/>
        <Navbar className="bg-light m-auto justify-content-center" style={{ paddingTop: 100,textAlign: "center"}}>
          <h3 style={{ marginLeft: "auto",marginRight: "auto" }}>Gate Pass / Customer Returns Orders</h3>
          {(allowedRoutesSet.has(`/viewreturnorders`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Button size="sm"  variant="warning" onClick={()=>{window.open("/viewreturnorders", "_blank")}}>Added Return Orders</Button>}
          </Navbar>
          <Row style={{ display:"flex"  }}>
            <Col style={{ margin: "30px" }}>
            <InputGroup style={{ width:"300px",marginLeft: "auto",marginRight: "auto" }}>
              <InputGroup.Prepend>
              <InputGroup.Radio name="orderoption" checked={orderOption==="AddOrder"} onChange={(e) => { setOrderOption("AddOrder"); }}  />
              </InputGroup.Prepend>
              <InputGroup.Text > Add Order</InputGroup.Text>
            </InputGroup>
            </Col>
            {/* {(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)))?(
              <Col style={{ margin: "30px"}}>
              <InputGroup style={{width:"300px", marginLeft: "auto",marginRight: "auto" }}>
                <InputGroup.Prepend>
                  <InputGroup.Radio name="orderoption" checked={orderOption==="ScheduleForDelivery"} onChange={(e) => { setOrderOption("ScheduleForDelivery"); getDeliveryOrdernumber(orderNumber); }} />
                </InputGroup.Prepend>
                <InputGroup.Text >Schedule For Delivery</InputGroup.Text>
              </InputGroup>
              </Col>
            ):null} */}
          </Row>
          {orderOption==="AddOrder"?(
            <div className="row" style={{paddingTop:20,width:"500px",marginLeft: "auto",marginRight: "auto"}}>
                <Form.Control style={{width:"400px", margin:"20"}} id="searchOrderNumber" className="searchBox" placeholder="Enter Parent Order Number" value={orderNumber} onChange={(e) => { setOrderNumber(e.target.value); }} />
                <Button size="sm" variant="warning" onClick={()=>{ viewRetailerOrders(); }}>Submit</Button>
            </div>
          ):null}

          {(orderOption==="AddOrder" && orderData.length>0)?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'25%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Type of Order </InputGroup.Text>
                </InputGroup.Prepend>
                <DropdownButton id="dropdown-basic-button" title={(orderType!="SELECT")?orderType:"SELECT"} size="sm" variant="secondary" >
                  <Dropdown.Item key="Short Order (S)" name="Short Order (S)" onClick={(e)=>{e.preventDefault();setOrderType(e.target.name);setActualOrderType("S");setReturnReason("SELECT");setCustomerReturnedItems("SELECT");setCustomerAdjustedPayment("SELECT");setIsOrderAlreadyPickedUp("SELECT");setReturnedVehicleId("SELECT"); }}>Short Order (S)</Dropdown.Item>
                  <Dropdown.Item key="Replacement (R)" name="Replacement (R)" onClick={(e)=>{e.preventDefault();setOrderType(e.target.name);setActualOrderType("R");setReturnReason("SELECT");setCustomerReturnedItems("SELECT");setCustomerAdjustedPayment("SELECT");setIsOrderAlreadyPickedUp("SELECT");setReturnedVehicleId("SELECT"); }}>Replacement (R)</Dropdown.Item>
                  <Dropdown.Item key="Old Return Adjustment (OR)" name="Old Return Adjustment (OR)" onClick={(e)=>{e.preventDefault();setOrderType(e.target.name);setActualOrderType("OR");setReturnReason("SELECT");setCustomerReturnedItems("SELECT");setCustomerAdjustedPayment("SELECT");setIsOrderAlreadyPickedUp("SELECT");setReturnedVehicleId("SELECT"); }}>Old Return Adjustment (OR)</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
          ):null}

          {(orderOption==="AddOrder" && orderData.length>0 && actualOrderType=="R")?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'45%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Customer returned the items or undelivered? </InputGroup.Text>
                </InputGroup.Prepend>
                <DropdownButton id="dropdown-basic-button" title={(customerReturnedItems!="SELECT")?customerReturnedItems:"SELECT"} size="sm" variant="secondary" >
                  <Dropdown.Item key="No" name="No" onClick={(e)=>{e.preventDefault();setCustomerReturnedItems(e.target.name); }}>No</Dropdown.Item>
                </DropdownButton>
                <InputGroup.Prepend >
                  <InputGroup.Text> Reason </InputGroup.Text>
                </InputGroup.Prepend>
                <ReasonsDropdown />
              </InputGroup>
            </div>
          ):null}

          {(orderOption==="AddOrder" && orderData.length>0 && actualOrderType=="S")?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'35%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Customer made payment?</InputGroup.Text>
                </InputGroup.Prepend>

                <DropdownButton id="dropdown-basic-button" title="No" disabled={true} size="sm" variant="secondary" >
                  <Dropdown.Item key="Yes" name="Yes">Yes</Dropdown.Item>
                  <Dropdown.Item key="No" name="No">No</Dropdown.Item>
                </DropdownButton>
                <InputGroup.Prepend >
                  <InputGroup.Text> Reason </InputGroup.Text>
                </InputGroup.Prepend>
                <ReasonsDropdown />
              </InputGroup>
            </div>
          ):null}

          {(orderOption==="AddOrder" && actualOrderType=="OR" && orderData.length>0)?(
            <div className="row" style={{paddingTop:20,marginLeft: "auto",marginRight: "auto"}}>
              <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'35%' }}>
                <InputGroup.Prepend>
                  <InputGroup.Text> Customer Adjusted the Payment?</InputGroup.Text>
                </InputGroup.Prepend>

                <DropdownButton id="dropdown-basic-button" title={(customerAdjustedPayment!="SELECT")?customerAdjustedPayment:"SELECT"} size="sm" variant="secondary" >
                  <Dropdown.Item key="Yes" name="Yes" onClick={(e)=>{e.preventDefault();setCustomerAdjustedPayment(e.target.name);filterOrderData(e.target.name);setIsOrderAlreadyPickedUp("Yes");setIsOrderPickedDropdownDisable(true); }}>Yes</Dropdown.Item>
                  <Dropdown.Item key="No" name="No" onClick={(e)=>{e.preventDefault();setCustomerAdjustedPayment(e.target.name);filterOrderData(e.target.name);setIsOrderAlreadyPickedUp("Yes");setIsOrderPickedDropdownDisable(true); }}>No</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>):null}

            {(orderOption==="AddOrder" && actualOrderType=="OR" && orderData.length>0 && customerAdjustedPayment!="SELECT")?(
              <div className="row" style={{paddingTop:5,marginLeft: "auto",marginRight: "auto"}}>
                <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'35%' }}>
                  {/* <InputGroup.Prepend>
                    <InputGroup.Text> Is the Order already picked up?</InputGroup.Text>
                  </InputGroup.Prepend>

                  <DropdownButton id="dropdown-basic-button" title={(isOrderAlreadyPickedUp!="SELECT")?isOrderAlreadyPickedUp:"SELECT"} size="sm" variant="secondary" disabled={isOrderPickedDropdownDisable} >
                    <Dropdown.Item key="Yes" name="Yes" onClick={(e)=>{e.preventDefault();setIsOrderAlreadyPickedUp(e.target.name); }}>Yes</Dropdown.Item>
                    <Dropdown.Item key="No" name="No" onClick={(e)=>{e.preventDefault();setIsOrderAlreadyPickedUp(e.target.name); }}>No</Dropdown.Item>
                  </DropdownButton> */}

                  <InputGroup.Prepend >
                    <InputGroup.Text> Reason </InputGroup.Text>
                  </InputGroup.Prepend>
                  <ReasonsDropdown />
                </InputGroup>
              </div>):null}

              {(orderOption==="AddOrder" && (actualOrderType=="OR" || (actualOrderType=="R" && customerReturnedItems=="Yes")))?(
                <div className="row" style={{paddingTop:5,marginLeft: "auto",marginRight: "auto"}}>
                  <InputGroup size="sm" style={{marginLeft: "auto",marginRight: "auto",display:"flex" ,width:'25%' }}>
                    <InputGroup.Prepend >
                      <InputGroup.Text> Select Vehicle </InputGroup.Text>
                    </InputGroup.Prepend>
                    <ReturnedVehicleDropdown />
                  </InputGroup>
                </div>
              ):null}

          {(orderOption==="AddOrder" && orderData.length>0 && returnReason!="SELECT" && actualOrderType=="S")?(<>
            <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
              <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                <RetailerOrdersTableHeader />
                <RetailerOrdersTableBody />
              </table>
            </div>

            <RetailerOrdersSummaryTable />

            <Button style={{margin:"auto",display:"block"}} variant="warning" onClick={()=>{ placeShortOrder(); }}>Place Order</Button>
          </>):null}

          {(orderOption==="AddOrder" && orderData.length>0 && returnReason!="SELECT" && actualOrderType=="R" && customerReturnedItems!="SELECT")?(<>
            <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
              <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                <RetailerOrdersTableHeader />
                <RetailerOrdersTableBody />
              </table>
            </div>

            <RetailerOrdersSummaryTable />

            <Button style={{margin:"auto",display:"block"}} variant="warning" onClick={()=>{ placeReturnOrderForDelivery(); }}>Place Order</Button>
          </>):null}

          {(orderOption==="AddOrder" && orderData.length>0 && returnReason!="SELECT" && actualOrderType=="OR" && customerAdjustedPayment!="SELECT" && isOrderAlreadyPickedUp!="SELECT" && returnedVehicleId!="SELECT")?(<>
            <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
              <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                <RetailerOrdersTableHeader />
                <RetailerOrdersTableBody />
              </table>
            </div>

            <RetailerOrdersSummaryTable />

            {(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && isOrderAlreadyPickedUp=="Yes")?(<InputGroup style={{paddingTop:20,margin:"auto",display:"flex",width:"300px",textAlign: "center"}}>
              <InputGroup.Prepend >
                <InputGroup.Text> Return Date</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control className="deliverydate" type="date" placeholder="Return Date" value={returnDate} onChange={(e) => { setReturnDate(formatOrderDate(e.target.value)); }} />
            </InputGroup>):null}
            <Button style={{margin:"auto",display:"block"}} variant="warning" onClick={()=>{ placeReturnOrderForWarehouse(); }}>Place Order</Button>
          </>):null}



            {/* <div className="row" style={{paddingTop:20,width:"500px",marginLeft: "auto",marginRight: "auto"}}>
          {orderOption==="ScheduleForDelivery"?(<>
              <Form.Control style={{width:"400px", margin:"20"}}  id="searchOrderNumber" className="searchBox" placeholder="Enter Return Order Number" value={deliveryOrderNumber} onChange={(e) => { setDeliveryOrderNumber(e.target.value); }} />
              <Button size="sm" variant="warning" onClick={()=>{ viewRetailerOrdersForDelivery(); }}>Submit</Button>
            </>):null}
          </div>

          {(orderOption==="ScheduleForDelivery" && deliveryOrderData.length>0 && deliveryOrderNumber.length>0)?(<>
            <div className="table-wrapper" style={{marginTop:15,paddingBottom:40}}>
              <table className="table table-striped" style={{paddingLeft:50,paddingRight:50,textAlign:"center"}}>
                <DeliveryRetailerOrdersTableHeader />
                <DeliveryRetailerOrdersTableBody />
              </table>
            </div>

            <DeliveryRetailerOrdersSummaryTable />

              <InputGroup size="sm"  style={{paddingTop:20,margin:"auto",display:"flex",width:"200px",textAlign: "center"}}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Route </InputGroup.Text>
                </InputGroup.Prepend>
                <VehicleDropdown />
              </InputGroup>
              <InputGroup  style={{paddingTop:20,margin:"auto",display:"flex",width:"200px",textAlign: "center"}}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Priority </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control className="priority" type="number" placeholder="Priority" value={vehiclePriority} onChange={(e) => { setVehiclePriority(e.target.value); }} />
              </InputGroup>
              {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<InputGroup  style={{paddingTop:20,margin:"auto",display:"flex",width:"300px",textAlign: "center"}}>
                <InputGroup.Prepend >
                  <InputGroup.Text> Delivery Date</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control className="deliverydate" type="date" placeholder="Delivery Date" value={deliveryDate} onChange={(e) => { setDeliveryDate(formatOrderDate(e.target.value)); }} />
              </InputGroup>):null}
              <Button style={{margin:"auto",display:"block",padding:"20",marginTop:"20"}}  variant="warning" onClick={()=>{ placeDeliveryOrder(); }}>Assign to Route</Button>
          </>):null} */}

          {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}

          {showDuplicateOrderNumberModal?(<ModalDuplicateReturnOrders
            show={showDuplicateOrderNumberModal}
            onHide={() => setShowDuplicateOrderNumberModal(false)}
            duplicateOrderNumber={duplicateOrderNumber}
            callback={placeDuplicateReturnOrder}
          />):null}
      </>
    )

}

export default AddReturnOrAdjustmentOrder
